import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DynamicList from '../../widgets/DynamicList';
import FeaturedList from './FeaturedList';
import { apiRequestAsyncThunk } from '../../thunk/CommonThunk';
import { CRUD_SLIDER, GET_SLIDERS, CRUD_FEATURED, GET_FEATURED } from '../../api/Api';
import { $serializeForm } from '../../main';
import { Loading } from '../Loaders';
import { LoadingSvg, SaveSvg } from '../../icons/Icon';

const FeaturedManager = (p) => {

    const sliderList = useRef(null);
    const dispatch = useDispatch();
    const  [featured, setFeatured] = useState(null);

    const loadFeatured = (e) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_FEATURED,
            call: showFeatured,
            messages: {
                loading: 'Loading featured',
                done: 'Loaded featured',
                error: 'Error loading featured'
            }
        }));
    }
    const showFeatured = (state, data) => {
        //console.log('Menu.showMainMenu', state, data?.data);
        switch (state) {
            case 'loading':
                setFeatured(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                let featured = data.data;
                setFeatured(<FeaturedList ref={sliderList} data={featured} />);
                break;
            case 'error':
                setFeatured(<FeaturedList ref={sliderList} data={[]} />);
                break;
        }
    }
    useEffect(() => {
        loadFeatured();
    }, []);

    const onGet = () => {
        dispatch(apiRequestAsyncThunk(CRUD_SLIDER.GET, {}));
    };
    const onSave = () => {
        let data = sliderList.current.getData();
        let list = sliderList.current.getList();
        const formData = new FormData();
        console.log('sliderList', list);

        list.forEach((slide, index) => {
            let id = slide.id;
            if(data[id]){
                let form = data[id].form;
                let images = data[id].image.getImages();
                let image = (Array.isArray(images) && images.length > 0) ? images[0] : null;
                let [encoded, decoded] = $serializeForm(form.getForm());
                formData.append(`${id}[form]`, JSON.stringify(decoded));    
                //formData.append(`${id}[image]`, (Array.isArray(images) && images.length > 0) ? images[0].file : '');
                console.log(image);
                formData.append(`${id}[image]`, image ? JSON.stringify({name: image.name, newName: image.newName, order: image.order}) : '');
                formData.append(`${id}[order]`, slide.order);
                formData.append(`images[${id}]`, image?.file || '');
            }
        });
        //formData.forEach((value, key) => {console.log(`${key}: ${value}`);});

        dispatch(apiRequestAsyncThunk({
            request_details: CRUD_FEATURED(formData),
            call: (state, data) => {
                console.log('CRUD_FEATURED', state);
                if(state == 'done' || state == 'error'){
                    loadFeatured();
                }
            },
            messages: {
                loading: 'Saving',
                done: 'done',
                error: 'error'
            }
        }));
    }

    return (
        <div className="page">
            <div className="flex-24">
                <div className="col-24 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span>Featured products</span><button className="page-box-header-yellow" onClick={onSave}><SaveSvg />save</button></h4>
                        </div>
                        <div className='page-box-body'>
                            {featured}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default FeaturedManager;