import { all } from 'redux-saga/effects';
import { watchSetService, watchGetAllService, watchPutService } from './ServicesSaga';
import { watchSetUnitSchema, watchGetAllUnitSchema, watchDeleteUnitSchema, watchUpdateUnitSchema } from './UnitSchemaSaga';
import { watchSetUnitSchemaDefinition, watchGetUnitSchemaDefinition, watchDeleteUnitSchemaDefinition, watchUpdateUnitSchemaDefinition } from './UnitSchemaDefinitionSaga';
import { watchSetUnitVariant, watchGetUnitVariant, watchGetAllUnitVariants, watchDeleteUnitVariant, watchUpdateUnitVariant } from './UnitVariantSaga';
import { watchSetUnitVariantDefinition, watchGetUnitVariantDefinition, watchDeleteUnitVariantDefinition, watchUpdateUnitVariantDefinition } from './UnitVariantDefinitionSaga';
import { watchGetUnitCategory, watchSetUnitCategory, watchGetAllUnitCategory, watchDeleteUnitCategory, watchUpdateUnitCategory } from './UnitCategorySaga';
import { watchGetUnitGroup, watchSetUnitGroup, watchGetAllUnitGroup, watchDeleteUnitGroup, watchUpdateUnitGroup } from './UnitGroupSaga';
import { watchGetUnitCategoryOption, watchSetUnitCategoryOption, watchGetAllUnitCategoryOption, watchDeleteUnitCategoryOption, watchUpdateUnitCategoryOption } from './UnitCategoryOptionSaga';

export default function* rootSaga() {
    yield all([
        watchSetService(),
        watchGetAllService(),
        watchPutService(),
        watchSetUnitSchema(),
        watchGetAllUnitSchema(),
        watchDeleteUnitSchema(),
        watchUpdateUnitSchema(),
        watchGetUnitSchemaDefinition(),
        watchSetUnitSchemaDefinition(),
        watchDeleteUnitSchemaDefinition(),
        watchUpdateUnitSchemaDefinition(),
        watchSetUnitVariant(),
        watchGetUnitVariant(),
        watchGetAllUnitVariants(),
        watchDeleteUnitVariant(),
        watchUpdateUnitVariant(),
        watchSetUnitVariantDefinition(),
        watchGetUnitVariantDefinition(),
        watchDeleteUnitVariantDefinition(),
        watchUpdateUnitVariantDefinition(),
        watchGetUnitCategory(),
        watchSetUnitCategory(),
        watchGetAllUnitCategory(),
        watchDeleteUnitCategory(),
        watchUpdateUnitCategory(),
        watchSetUnitCategoryOption(),
        watchGetUnitCategoryOption(),
        watchDeleteUnitCategoryOption(),
        watchUpdateUnitCategoryOption(),
        watchGetUnitGroup(),
        watchSetUnitGroup(),
        watchGetAllUnitGroup(),
        watchDeleteUnitGroup(),
        watchUpdateUnitGroup(),
    ]);
}