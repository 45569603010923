import { takeEvery, call, put, all } from 'redux-saga/effects';
import { UnitVariantDefinitionActions } from '../redux/slices/UnitVariantDefinitionSlice';
import { apiSubmit } from '../main';
import { toast } from 'react-toastify';

function* handleSetUnitVariantDefinition(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitVariantDefinitionActions.setUnitVariantDefinitionSuccess(response));
  } catch (e) {
    yield put(UnitVariantDefinitionActions.setUnitVariantDefinitionFailure(e.message));
  }finally{
  }
}

export function* watchSetUnitVariantDefinition() {
  yield takeEvery(UnitVariantDefinitionActions.setUnitVariantDefinition, handleSetUnitVariantDefinition);
}

function* handleGetUnitVariantDefinition(action) {
  try {
    var loading = toast.loading('Loading Variant definitions...',{
      closeOnClick: true,
      closeButton: true,
    });
    const response = yield call(apiSubmit, action.payload);
    if (response) {
      toast.update(loading, {
        render: "Variants definition loaded!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    }
    yield put(UnitVariantDefinitionActions.getUnitVariantDefinitionSuccess(response));
  } catch (e) {
    toast.update(loading, {
      render: "Failed to load variant definition. ",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
    yield put(UnitVariantDefinitionActions.getUnitVariantDefinitionFailure(e.message));
  }finally{

  }
}
export function* watchGetUnitVariantDefinition() {
  yield takeEvery(UnitVariantDefinitionActions.getUnitVariantDefinition, handleGetUnitVariantDefinition);
}

function* handleDeleteUnitVariantDefinition(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitVariantDefinitionActions.deleteUnitVariantDefinitionSuccess(response));
  } catch (e) {
    yield put(UnitVariantDefinitionActions.deleteUnitVariantDefinitionFailure(e.message));
  }finally{

  }
}
export function* watchDeleteUnitVariantDefinition() {
  yield takeEvery(UnitVariantDefinitionActions.deleteUnitVariantDefinition, handleDeleteUnitVariantDefinition);
}

function* handleUpdateUnitVariantDefinition(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitVariantDefinitionActions.putUnitVariantDefinitionSuccess(response));
  } catch (e) {
    yield put(UnitVariantDefinitionActions.putUnitVariantDefinitionFailure(e.message));
  }finally{

  }
}
export function* watchUpdateUnitVariantDefinition() {
  yield takeEvery(UnitVariantDefinitionActions.putUnitVariantDefinition, handleUpdateUnitVariantDefinition);
}
