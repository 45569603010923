import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { faker } from '@faker-js/faker';
import { UnitSchemaActions } from '../../redux/slices/UnitSchemaSlice';
import { UnitSchemaDefinitionActions } from '../../redux/slices/UnitSchemaDefinitionSlice';
import { UnitVariantDefinitionActions } from "../../redux/slices/UnitVariantDefinitionSlice";
import { UnitVariantActions } from "../../redux/slices/UnitVariantSlice";
import { GET_ALL_UNIT_SCHEMA, GET_UNIT_SCHEMA_DEFINITION, GET_UNIT_VARIANT_DEFINITION, GET_ALL_UNIT_VARIANTS } from "../../api/Api";
import { $serializeForm, $GroupLeafNodesUnderCommonPath, $GroupArrayItemsToPairs } from "../../main";
import Meta from "../../widgets/Meta";
import { SelectOptionsSection } from "../../widgets/MultiSelectOptions";
import { Loading } from "./../Loaders";
import { toast } from 'react-toastify';
import { SaveSvg } from "../../icons/Icon";
import MyQuillEditor from "../../widgets/MyQuillEditor";
import Variants from "./Variants";
import { CREATE_PRODUCT } from "../../api/Api";
import { generateRandomPhoneName, generateRandomParagraphAndList, generateRandomSentenceByWords } from "../../widgets/random";
import { apiRequestAsyncThunk } from "../../thunk/CommonThunk";

//const Delta = Quill.import('delta');
const randomNum = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
const NewProduct = () => {

    const [randomPhoneName, setRandomPhoneName] = useState(generateRandomPhoneName());
    const basic = useRef();
    const unit = useRef();
    const meta = useRef();
    const scripts = useRef();
    const productVariants = useRef(null);
    const dispatch = useDispatch();
    const [schema, setSchema] = useState(null);
    const [variants, setVariants] = useState(null);
    const allUnitVariants = useSelector(state => state.unitVariant.data);
    const allUnitSchemas = useSelector(state => state.unitSchema.data);
    const unitSchemaDefinition = useSelector(state => state.unitSchemaDefinition.data);
    const productVariant = useSelector(state => state.unitVariantDefinition.data);
    const [grouped, setGrouped] = useState(null);

    useEffect(() => {
        //console.log('findBottomMostNodes', $GroupLeafNodesUnderCommonPath(unitSchemaDefinition));
        setGrouped($GroupLeafNodesUnderCommonPath(unitSchemaDefinition));
    }, [unitSchemaDefinition]);
    useEffect(() => {
        //console.log('productVariant', productVariant);
    }, [productVariant]);
    useEffect(() => {
        dispatch(UnitSchemaActions.getAllUnitSchema(GET_ALL_UNIT_SCHEMA));
        dispatch(UnitVariantActions.getAllUnitVariants(GET_ALL_UNIT_VARIANTS));
        //toast.info("Button was clicked!");
        //console.log("Button was clicked!");
    }, []);
    useEffect(() => {
        var schemas = allUnitSchemas ? allUnitSchemas.map(unitSchema => ({ value: unitSchema.id, label: unitSchema.name })) : [];
        setSchema(schemas);
    }, [allUnitSchemas]);
    useEffect(() => {
        var variants = allUnitVariants ? allUnitVariants.map(unitVariant => ({ value: unitVariant.id, label: unitVariant.value })) : [];
        setVariants(variants);
    }, [allUnitVariants]);
    function formChanged(e) {
        e.preventDefault();
    }
    function parseKey(key) {
        var regex = /([\w]+)\[(\d+)\](?:\[(\w+)\])?/;
        var match = key.match(regex);

        if (match) {
            var name = match[1];
            var index = parseInt(match[2], 10);
            var subKey = match[3] || null;

            return { name, index, key: subKey };
        }

        return null;
    }
    function onSubmit(e) {
        e.preventDefault();
        var form = {};
        var [encoded, json] = $serializeForm(unit.current);
        var [basicEncoded, basicJson] = $serializeForm(basic.current);
        var [metaEncoded, metaJson] = $serializeForm(meta.current);
        var [scriptsEncoded, scriptsJson] = $serializeForm(scripts.current);
        form.basic = basicJson;
        form.details = json;
        //form.variants = productVariants.current.getVariants();
        var variants = productVariants.current.getVariants();
        form.meta = metaJson;
        form.scripts = scriptsJson;
        form.schemaDetails = grouped;
        //console.log('set-form',form);
        var formData = new FormData();
        formData.append('forms', JSON.stringify(form));
        //console.log('variants',variants);
        variants.forEach((variant, index) => {
            formData.append(`variants[${index}][form]`, JSON.stringify(variant.form));

            variant.formData.forEach((value, key) => {
                //console.log('variant.formData.forEach', key);
                var parsed = parseKey(key);
                if (parsed) {
                    //console.log('key', key);
                    console.log(index, parsed.name, parsed.index, parsed.key, value);
                    formData.append(`variants[${index}][${parsed.name}][${parsed.index}]${parsed.key ? '[' + parsed.key + ']' : ''}`, value);
                } else {
                    //console.log(index, key);
                    formData.append(`variants[${index}][${key}]`, value);
                }

            });
        });

        dispatch(apiRequestAsyncThunk({
            request_details: CREATE_PRODUCT(formData),
            call: () => {},
            messages: {
                loading: 'Saving product',
                done: 'Product saved',
                error: 'Error saving product',
            }
        }));
        //console.log('full_form', form);
        //for (let pair of formData.entries()) { console.log(pair[0] + ": " + pair[1]); }
    }
    function onSchemaChange(change) {
        //console.log('onSchemaChange', change);
        if (change && !Array.isArray(change)) {
            dispatch(UnitSchemaDefinitionActions.getUnitSchemaDefinition(GET_UNIT_SCHEMA_DEFINITION(change.value)));
        }
    }
    function onVariantChange(change) {
        if (change && !Array.isArray(change)) {
            dispatch(UnitVariantDefinitionActions.getUnitVariantDefinition(GET_UNIT_VARIANT_DEFINITION(change.value)));
        }
    }
    const productForm = (p) => {

        return (grouped && grouped.length > 0 && grouped.map((group, index) => {
            let path = parseInt(group.path);
            let heading = '';
            let paired = null;
            if (isNaN(path)) {
                heading = (<div key={index} className="flex-row">
                    <div className="flex-24">
                        <div className="col-24">
                            <section className="fieldset">
                                <h3><span>{group.path}</span></h3>
                            </section>
                        </div>
                    </div>
                </div>);
            }
            if (group.nodes && Array.isArray(group.nodes) && group.nodes.length > 0) {
                paired = $GroupArrayItemsToPairs(group.nodes);
            }
            return (<React.Fragment key={`row-${index}`}>
                {heading}
                {paired && paired.map(pair => (
                    (pair.length == 2) ?
                        (<div key={pair[0].id} className="flex-row">
                            <div className="flex-24">
                                <div className="col-12 flex-column gap-1rem-right">
                                    <label title=""><span>{pair[0].value}</span><input type="text" name={pair[0].value} placeholder="" defaultValue={generateRandomSentenceByWords(5)} /></label>
                                </div>
                                <div className="col-12 flex-column">
                                    <label title=""><span>{pair[1].value}</span><input type="text" name={pair[1].value} placeholder="" defaultValue={generateRandomSentenceByWords(5)} /></label>
                                </div>
                            </div>
                        </div>) :
                        (<div key={pair[0].id} className="flex-row">
                            <div className="flex-24">
                                <div className="col-24 flex-column">
                                    <label title=""><span>{pair[0].value}</span><input type="text" name={pair[0].value} placeholder="" defaultValue={generateRandomSentenceByWords(5)} /></label>
                                </div>
                            </div>
                        </div>)
                ))}
            </React.Fragment>);
        }));
    };

    //console.log('randomPhoneName', randomPhoneName);
    return (
        <div className="page">
            <div className="flex-24">
                <div className="col-19 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4>Basic</h4>
                        </div>
                        <div className='page-box-body'>
                            <form action="" ref={basic} onChange={formChanged} className="simple-form" autoComplete="on" target="_blank" noValidate>
                                <div className="flex-row">
                                    <div className="flex-24">
                                        <div className="col-12 flex-column gap-1rem-right">
                                            <label title="product name"><span>Name</span><input type="text" name="name" placeholder="" defaultValue={randomPhoneName} /></label>
                                        </div>
                                        <div className="col-12 flex-column">
                                            <label title="stock keeping unit"><span>SKU</span><input type="text" name="sku" placeholder="" defaultValue={'sku-' + faker.number.int({ min: 1, max: 99 })} /></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-24">
                                        <div className="col-12 flex-column gap-1rem-right">
                                            <label title="product brand name"><span>Brand</span><input type="text" name="brand" placeholder="" defaultValue={randomPhoneName.split(/\s+/)[0]} /></label>
                                        </div>
                                        <div className="col-12 flex-column">
                                            <label title="product url"><span>URL</span><input type="text" name="url" placeholder="" defaultValue={randomPhoneName.replaceAll(/\s+/g, '-')} /></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <label title="brief description of the service">
                                        <span>Description</span>
                                        <MyQuillEditor
                                            value={generateRandomParagraphAndList()}
                                            name="description"
                                            onChange={(e) => console.log(e)}
                                            style={{ height: '400px', boxSizing: 'border-box' }}
                                        />
                                    </label>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-24">
                                        <div className="col-12 flex-column gap-1rem-right">
                                            <label title="type unique unit code"><span> Variant Type</span>
                                                {schema ? <SelectOptionsSection name='variantId' multiple={false} options={variants} selected={null} onChange={onVariantChange} /> : 'loading'}
                                            </label>
                                        </div>
                                        <div className="col-12 flex-column">
                                            <label title="type unique unit code"><span> Product Type</span>
                                                {schema ? <SelectOptionsSection name='schemaId' multiple={false} options={schema} selected={null} onChange={onSchemaChange} /> : 'loading'}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Variants ref={productVariants} variants={productVariant} name={randomPhoneName} />
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4>Product data</h4>
                        </div>
                        <div className='page-box-body'>
                            <form action="" ref={unit} onChange={formChanged} className="simple-form" autoComplete="on" target="_blank" noValidate>
                                <div className="flex-column">
                                    {grouped && productForm()}
                                </div >
                            </form>
                        </div>
                    </div>
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4>Scripts</h4>
                        </div>
                        <div className='page-box-body'>
                            <form action="" ref={scripts} onChange={formChanged} className="simple-form" autoComplete="on" target="_blank" noValidate>
                                <div className="flex-column">
                                    <label title="header scripts"><span>Header Script</span><textarea name="header" rows="6" placeholder="" defaultValue={''} ></textarea></label>
                                    <label title="type unit name"><span>Footer Script</span><textarea name="footer" rows="6" placeholder="" defaultValue={''}  ></textarea></label>
                                </div>
                            </form>
                        </div>
                    </div>
                    <form action="" ref={meta} onChange={formChanged} className="simple-form" autoComplete="on" target="_blank" noValidate>
                        <Meta />
                    </form>
                </div>
                <div className="col-5 flex-column">
                    <div className="page-box page-box-sticky">
                        <div className='page-box-header'>
                            <h4>Create Product</h4>
                        </div>
                        <div className='page-box-body'>
                            <div className="flex-row">
                                <button className="blue-button page-box-header-yellow" onClick={onSubmit}><SaveSvg /> Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: '100px' }}></div>
        </div >
    );
};

export default NewProduct;