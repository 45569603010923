import { takeEvery, call, put, all } from 'redux-saga/effects';
import { UnitSchemaDefinitionActions } from '../redux/slices/UnitSchemaDefinitionSlice';
import { apiSubmit } from '../main';
import { toast } from 'react-toastify';

function* handleSetUnitSchemaDefinition(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitSchemaDefinitionActions.setUnitSchemaDefinitionSuccess(response));
  } catch (e) {
    yield put(UnitSchemaDefinitionActions.setUnitSchemaDefinitionFailure(e.message));
  }finally{
  }
}

export function* watchSetUnitSchemaDefinition() {
  yield takeEvery(UnitSchemaDefinitionActions.setUnitSchemaDefinition, handleSetUnitSchemaDefinition);
}

function* handleGetUnitSchemaDefinition(action) {
  try {
    var loading = toast.loading('Loading product type details...',{
      closeOnClick: true,
      closeButton: true,
    });
    const response = yield call(apiSubmit, action.payload);
    if (response) {
      toast.update(loading, {
        render: "Product type details loaded!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    }
    yield put(UnitSchemaDefinitionActions.getUnitSchemaDefinitionSuccess(response));
  } catch (e) {
    toast.update(loading, {
      render: "Failed to load product type details.",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
    yield put(UnitSchemaDefinitionActions.getUnitSchemaDefinitionFailure(e.message));
  }finally{

  }
}
export function* watchGetUnitSchemaDefinition() {
  yield takeEvery(UnitSchemaDefinitionActions.getUnitSchemaDefinition, handleGetUnitSchemaDefinition);
}

function* handleDeleteUnitSchemaDefinition(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitSchemaDefinitionActions.deleteUnitSchemaDefinitionSuccess(response));
  } catch (e) {
    yield put(UnitSchemaDefinitionActions.deleteUnitSchemaDefinitionFailure(e.message));
  }finally{

  }
}
export function* watchDeleteUnitSchemaDefinition() {
  yield takeEvery(UnitSchemaDefinitionActions.deleteUnitSchemaDefinition, handleDeleteUnitSchemaDefinition);
}

function* handleUpdateUnitSchemaDefinition(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitSchemaDefinitionActions.putUnitSchemaDefinitionSuccess(response));
  } catch (e) {
    yield put(UnitSchemaDefinitionActions.putUnitSchemaDefinitionFailure(e.message));
  }finally{

  }
}
export function* watchUpdateUnitSchemaDefinition() {
  yield takeEvery(UnitSchemaDefinitionActions.putUnitSchemaDefinition, handleUpdateUnitSchemaDefinition);
}
