import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { timestampPlusIndex } from "../../widgets/Functions";
import { DeleteSvg, AddLayerSvg, ImageSvg } from "../../icons/Icon";
import Variant from "./Variant";

const Variants = forwardRef((p, r) => {

    const allVariants = useRef(new Map());
    const [variants, setVariants] = useState([]);
    const [details, setDetails] = useState([]);
    let variant_details = [{ value: 'name' }, { value: 'skuName' },
    { value: 'skuCode' }, { value: 'costPrice' },
    { value: 'salePrice' }, { value: 'retailPrice' },
    { value: 'stockUnits' }, { value: 'stockAlert' }, { value: 'URL' }];

    const setItemRef = (id, element) => {
        if (element) {
            allVariants.current.set(id, element);
        } else {
            allVariants.current.delete(id);
        }
    };
    useImperativeHandle(r, () => ({
        getVariants: () => {
            let result = [];
            allVariants.current.forEach((variant) => {
                result.push(variant.getVariant());
            });
            return result;
        }
    }));
    useEffect(() => {
        setVariants(array => [...array, { _keyid_: timestampPlusIndex(array.length), key: '', value: '' }]);
        window.getAllVariants = () => {
            return allVariants.current;
        };
    }, []);

    useEffect(() => {
        let itemVariants = p.variants ? [...p.variants] : [];
        itemVariants.unshift(...variant_details);
        setDetails(itemVariants);
    }, [p.variants]);

    const add = (e) => {
        e.preventDefault();
        setVariants(array => [...array, { _keyid_: timestampPlusIndex(array.length), key: '', value: '' }]);
    };
    const remove = (e, key) => {
        e.preventDefault();
        setVariants(array => array.filter((item) => item._keyid_ !== key));
    };
    const showImageUploader = (e, keyid) => {
        e.preventDefault();
        allVariants.current.get(keyid).togglePopup();
    }
    return (
        <div className="page-box">
            <div className='page-box-header'>
                <h4><span>Variants</span> <button onClick={add} className="meta-add page-box-header-green"><AddLayerSvg /></button></h4>
            </div>
            <div className='page-box-body'>
                <div className="flex-column">
                    {variants.map((variant, index) => {
                        return (
                            <React.Fragment key={variant._keyid_} >
                                <section className="fieldset page-box-header">
                                    <h4>
                                        <span>{`Variant`}</span>
                                        <button onClick={(e) => showImageUploader(e, variant._keyid_)} className="meta-add page-box-header-grey"><ImageSvg /></button>
                                        <button onClick={(e) => remove(e, variant._keyid_)} className="meta-add page-box-header-grey"><DeleteSvg /></button>
                                    </h4>
                                </section>
                                <div className="flex-column">
                                    <Variant ref={(e) => setItemRef(variant._keyid_, e)} name={p.name} variants={details} remove={remove} keyid={variant._keyid_} index={index} />
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});

export default Variants;