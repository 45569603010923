import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { DeleteSvg, AddLayerSvg } from "../icons/Icon";
import { timestampPlusIndex } from "./Functions";
/*
{
  "keywords": "your, tags",
  "description": "150 words",
  "subject": "your website's subject",
  "copyright": "company name",
  "language": "ES"
}
*/
const MetaItem = (p) => {

    return (
        <div>
            <hr className="page-hr" />
            <div className="flex-24">
                <div className="col-6 flex-column gap-1rem-right">
                    <label title="type unit name">
                        <span>meta name</span>
                        <input type="text" name="metaName[]" placeholder="" defaultValue={p?.name} />
                    </label>
                </div>
                <div className="col-16 flex-column">
                    <label title="type unit name">
                        <span>content</span>
                        <textarea name="metaContent[]" rows={3} defaultValue={p?.content} ></textarea>
                    </label>
                </div>
                <div className="col-2 flex-column">
                    <button onClick={(e) => p.remove(e, p?.keyid)} className="meta-add"><DeleteSvg /></button>
                </div>
            </div>
        </div>
    );
};
const Meta = (p) => {

    const [metas, setMetas] = useState([]);

    useEffect(() => {
        if (p.value) {
            var entries = Object.entries(p.value);
        }
        if (entries) {
            const metas = entries.map(([key, value], index) => ({ _keyid_: timestampPlusIndex(index), key, value }));
            setMetas([...metas]);
        } else {
            setMetas([]);
        }

    }, [p.value]);
    const add = (e) => {
        e.preventDefault();
        setMetas(array => [...array, { _keyid_: timestampPlusIndex(array.length), key: '', value: '' }]);
    };
    const remove = (e, key) => {
        e.preventDefault();
        setMetas(array => array.filter((item) => item._keyid_ !== key));
    };

    return (
        <div className="page-box">
            <div className='page-box-header'>
                <h4><span>Meta{metas && (metas?.length > 0 ? `[${metas.length}]` : '')}</span>
                    <button onClick={add} className="meta-add page-box-header-green"><AddLayerSvg /></button>
                </h4>
            </div>
            <div className='page-box-body'>
                {metas && metas.map((meta, index) => {
                    let key = meta._keyid_ || timestampPlusIndex(index);
                    return (<MetaItem key={key} keyid={key} name={meta.key} content={meta.value} remove={remove} />)
                })}
            </div>
        </div>
    );
};

export default Meta;