import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    variant: null,
    get: null,
    create: null,
    getAll: null,
    delete: null,
    update: null
};

const UnitVariantSlice = createSlice({
    name: 'unitVariant',
    initialState,
    reducers: {
        resetState:(state, action) => {
            state.data = [];
        },
        resetCreate: (state, action) => {
            state.create = null;
        },
        setUnitVariant: (state, action) => {
            state.create = 'loading';
        },
        createUnitVariant: (state, action) => {
            state.create = 'working';
        },
        setUnitVariantSuccess: (state, action) => {
            state.create = 'done';
        },
        setUnitVariantFailure: (state, action) => {
            state.create = 'error';
        },
        resetUpdate: (state, action) => {
            state.update = null;
        },
        putUnitVariant: (state, action) => {
            state.update = 'loading';
        },
        updateUnitVariant: (state, action) => {
            state.update = 'working';
        },
        putUnitVariantSuccess: (state, action) => {
            state.update = 'done';
        },
        putUnitVariantFailure: (state, action) => {
            state.update = 'error';
        },
        resetDelete: (state, action) => {
            state.delete = null;
        },
        deleteUnitVariant: (state, action) => {
            state.delete = 'loading';
        },
        removeUnitVariant: (state, action) => {
            state.delete = 'working';
        },
        deleteUnitVariantSuccess: (state, action) => {
            state.delete = 'done';
        },
        deleteUnitVariantFailure: (state, action) => {
            state.delete = 'error';
        },
        resetGet: (state, action) => {
            state.get = null;
        },
        getUnitVariant: (state, action) => {
            state.get = 'loading';
        },
        fetchUnitVariant: (state, action) => {
            state.get = 'working';
        },
        getUnitVariantSuccess: (state, action) => {
            state.get = 'done';
            state.variant = action.payload.content.data;
        },
        getUnitVariantFailure: (state, action) => {
            state.get = 'error';
            state.variant = null;
        },
        resetGetAll: (state, action) => {
            state.getAll = null;
        },
        getAllUnitVariants: (state, action) => {
            state.getAll = 'loading';
        },
        fetchAllUnitVariants: (state, action) => {
            state.getAll = 'working';
        },
        getAllUnitVariantsSuccess: (state, action) => {
            state.getAll = 'done';
            state.data = action.payload.content.data;
        },
        getAllUnitVariantsFailure: (state, action) => {
            state.getAll = 'error';
            state.data = null;
        }
    },
});

export const UnitVariantActions = UnitVariantSlice.actions;
export default UnitVariantSlice;