import { Link, useLocation } from "react-router-dom";
import { ProductSvg, HomeSvg } from "../../icons/Icon";

function AppNav() {

    const location = useLocation();

    return (
        <nav className="nav">
            <ul>
                <li>
                    <input type="checkbox" id="products" />
                    <label htmlFor="products">
                        <ProductSvg />
                        <span>Products</span>
                        <b>&#9654;</b><i>&#9660;</i>
                    </label>
                    <ul>
                        <li><Link to="product/dashboard"><span>dashboard</span></Link></li>
                        <li><Link to="product/all"><span>List products</span></Link></li>
                        <li><Link to="product/new"><span>new</span></Link></li>
                        <li><Link to="product/slider"><span>Slider</span></Link></li>
                        <li><Link to="product/featured"><span>Featured</span></Link></li>
                        <li><Link to="product/type"><span>Type</span></Link></li>
                        <li><Link to="product/variant"><span>Variant</span></Link></li>
                    </ul>
                </li>
                <li>
                    <input type="checkbox" id="home" />
                    <label htmlFor="home">
                        <HomeSvg />
                        <span>Home page</span>
                        <b>&#9654;</b><i>&#9660;</i>
                    </label>
                    <ul>
                        <li><Link to="product/category"><span>Category</span></Link></li>
                        <li><Link to="home/group"><span>Group</span></Link></li>
                    </ul>
                </li>
                <li>
                    <input type="checkbox" id="services" />
                    <label htmlFor="services">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="m3.553 18.895 4 2a1.001 1.001 0 0 0 .894 0L12 19.118l3.553 1.776a.99.99 0 0 0 .894.001l4-2c.339-.17.553-.516.553-.895v-5c0-.379-.214-.725-.553-.895L17 10.382V6c0-.379-.214-.725-.553-.895l-4-2a1 1 0 0 0-.895 0l-4 2C7.214 5.275 7 5.621 7 6v4.382l-3.447 1.724A.998.998 0 0 0 3 13v5c0 .379.214.725.553.895zM8 12.118l2.264 1.132-2.913 1.457-2.264-1.132L8 12.118zm4-2.5 3-1.5v2.264l-3 1.5V9.618zm6.264 3.632-2.882 1.441-2.264-1.132L16 12.118l2.264 1.132zM8 18.882l-.062-.031V16.65L11 15.118v2.264l-3 1.5zm8 0v-2.264l3-1.5v2.264l-3 1.5zM12 5.118l2.264 1.132-2.882 1.441-2.264-1.132L12 5.118z"></path></svg>
                        <span>Site menu</span>
                        <b>&#9654;</b><i>&#9660;</i>
                    </label>
                    <ul>
                        <li><Link to="menu/edit"><span>edit</span></Link></li>
                    </ul>
                </li>
                <li>
                    <input type="checkbox" id="services" />
                    <label htmlFor="services">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="m3.553 18.895 4 2a1.001 1.001 0 0 0 .894 0L12 19.118l3.553 1.776a.99.99 0 0 0 .894.001l4-2c.339-.17.553-.516.553-.895v-5c0-.379-.214-.725-.553-.895L17 10.382V6c0-.379-.214-.725-.553-.895l-4-2a1 1 0 0 0-.895 0l-4 2C7.214 5.275 7 5.621 7 6v4.382l-3.447 1.724A.998.998 0 0 0 3 13v5c0 .379.214.725.553.895zM8 12.118l2.264 1.132-2.913 1.457-2.264-1.132L8 12.118zm4-2.5 3-1.5v2.264l-3 1.5V9.618zm6.264 3.632-2.882 1.441-2.264-1.132L16 12.118l2.264 1.132zM8 18.882l-.062-.031V16.65L11 15.118v2.264l-3 1.5zm8 0v-2.264l3-1.5v2.264l-3 1.5zM12 5.118l2.264 1.132-2.882 1.441-2.264-1.132L12 5.118z"></path></svg>
                        <span>services</span>
                        <b>&#9654;</b><i>&#9660;</i>
                    </label>
                    <ul>
                        <li><Link to="service/new-service"><span>new</span></Link></li>
                        <li><Link to="service/search-service"><span>search services</span></Link></li>
                    </ul>
                </li>
                <li>
                    <input type="checkbox" id="blog" />
                    <label htmlFor="blog">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M20 6h-2V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v15c0 1.654 1.346 3 3 3h10c1.654 0 3-1.346 3-3v-1h2c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2zm-4 13c0 .551-.448 1-1 1H5c-.552 0-1-.449-1-1V5h12v14zm4-3h-2V8h2v8z"></path><path d="M6 7h2v10H6zm6 0h2v10h-2z"></path></svg>
                        <span>blog</span>
                        <b>&#9654;</b><i>&#9660;</i>
                    </label>
                    <ul>
                        <li><Link to="blog/new"><span>new</span></Link></li>
                        <li><Link to="blog/search"><span>search-edit-delete</span></Link></li>
                    </ul>
                </li>
                <li>
                    <input type="checkbox" id="global" />
                    <label htmlFor="global">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M11 18.791V20H9v2h6v-2h-2v-1.845a9.934 9.934 0 0 0 3.071-2.084c3.898-3.898 3.898-10.243 0-14.143l-1.414 1.414c3.119 3.12 3.119 8.195 0 11.314-3.119 3.118-8.195 3.12-11.314 0L1.929 16.07A9.971 9.971 0 0 0 9 18.994a9.98 9.98 0 0 0 2-.203z"></path><path d="M3 9c0 3.309 2.691 6 6 6s6-2.691 6-6-2.691-6-6-6-6 2.691-6 6zm10 0c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4z"></path></svg>
                        <span>global</span>
                        <b>&#9654;</b><i>&#9660;</i>
                    </label>
                    <ul>
                        <li><Link to="global/meta"><span>meta</span></Link></li>
                        <li><Link to="blog/search"><span>search-edit-delete</span></Link></li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
}

export default AppNav;