const TreeSummary = (p) => {

    function renderList(list) {
        return (
            (list && Array.isArray(list)) ? (
                <ul className="changes-summary-item-ul">
                    {list.map(node => (
                        <li className="changes-summary-item-ul-li" key={node.id} >
                            <span>{node.value}</span>
                            {renderList(node?.nodes)}
                        </li>
                    ))}
                </ul>
            ) : null
        );
    }
    function renderDeleted(list) {
        return (
            (list && Array.isArray(list)) ? (
                <ul className="changes-summary-item-ul">
                    {list.map(node => (
                        <li key={node.id} className="changes-summary-item-ul-li"  {...(p.deleted.find(deleted => deleted.id === node.id)) ? { 'data-pickable': "yes", 'data-id': node.id } : ''} >
                            <span>{node.value}</span>
                            {renderList(node?.nodes)}
                        </li>
                    ))}
                </ul>
            ) : null
        );
    }
    return (
        <div className="changes-summary">
            <ul>
                <li>
                    <label htmlFor="changes-summary-new" className="changes-summary-green"><span>New</span><em>{(p.created) ? p.created.length : 0}</em></label>
                    <input type="checkbox" id="changes-summary-new" className="changes-summary-input" />
                    {p.created && p.created.length ? <section className="changes-summary-item">{renderList(p.created)}</section> : ''}
                </li>
                <li>
                    <label htmlFor="changes-summary-edited" className="changes-summary-orange"><span>Edited</span><em>{(p.edited) ? p.edited.length : 0}</em></label>
                    <input type="checkbox" id="changes-summary-edited" className="changes-summary-input" />
                    {p.edited && p.edited.length ? <section className="changes-summary-item">{renderList(p.edited)}</section> : ''}
                </li>
                <li>

                    <label htmlFor="changes-summary-deleted" className="changes-summary-red"><span>Deleted</span><em>{(p.deleted) ? p.deleted.length : 0}</em></label>
                    <input type="checkbox" id="changes-summary-deleted" className="changes-summary-input" defaultChecked="checked" />
                    {p.deleted && p.deleted.length ? <section className="changes-summary-item">{renderDeleted(p.deleted)}</section> : ''}
                </li>
            </ul>
        </div>
    );
};

export default TreeSummary;