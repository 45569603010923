import { forwardRef, useImperativeHandle, useRef, useEffect, useState } from "react";
import { columnNameToPretty } from "../main";
import { SaveSvg } from "../icons/Icon";
import { $serializeForm, printFormData } from "../main";
import FileUpload from "./FileUpload";

export const JsonToTable = (p) => {

    const [images, setImages] = useState(null);
    const upload = useRef(null);
    
    useEffect(() => {
        if ('images' in p.json) {
            setImages(p.json.images);
            delete p.json.images;
            console.log('images', images);
        } else {
            setImages(null);
        }
    }, [p.json]);

    return (
        <div className="simpleTableView">
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <tbody>
                    {Object.entries(p.json).map(([key, value]) => (
                        <tr key={key}>
                            <td style={{ border: "1px solid #ddd", padding: "" }}>{columnNameToPretty(key)}</td>
                            <td style={{ border: "1px solid #ddd", padding: "" }}>
                                {typeof value === "object" ? JSON.stringify(value) : value}
                            </td>
                        </tr>
                    ))}
                    <>{images && <tr key={'images'}>
                        <td>{columnNameToPretty('images')}</td>
                        <td><div style={{ padding: '1em 0', width: "300px", display: 'flex', height: '300px' }}><FileUpload ref={upload} images={images} /></div></td>
                    </tr>}</>
                </tbody>
            </table>
        </div>
    );
}
export const JsonToEditableTable = forwardRef((p, r) => {

    const form = useRef(null);
    const upload = useRef(null);
    const [images, setImages] = useState(null);

    useEffect(() => {
        if ('images' in p.json) {
            setImages(p.json.images);
            delete p.json.images;
            console.log('images', images);
        } else {
            setImages(null);
        }
    }, [p.json]);

    useImperativeHandle(r, () => ({
        getFormData: () => {
            var [encoded, json] = $serializeForm(form.current);
            let files = upload.current.getUploadedFiles();
            const formData = new FormData();
            formData.append(`variant`, JSON.stringify(json));

            files.forEach((file, index) => {
                var index = file.order;
                formData.append(`images[${index}]`, file.file);
                formData.append(`data[${index}][id]`, file.id || '');
                formData.append(`data[${index}][name]`, file.name);
                formData.append(`data[${index}][newName]`, file.newName);
                formData.append(`data[${index}][size]`, file.size);
                formData.append(`data[${index}][order]`, file.order);
            });

            return { variant: json, formData: formData };
        }
    }));

    return (
        <div className="page-box">
            <div className='page-box-header'>
                <h4><span>Changes Summary</span> <button className='page-box-header-yellow' onClick={p.onSave}><SaveSvg /> save</button></h4>
            </div>
            <div className='page-box-body'>
                <div className="">
                    <form ref={form} action="" onChange={() => { }} className="simple-form" autoComplete="on" target="_blank" noValidate>
                        <table >
                            <tbody>
                                {Object.entries(p.json).map(([key, value]) => (
                                    <tr key={key}>
                                        <td>{columnNameToPretty(key)}</td>
                                        {/*{(key == 'images') ?
                                            <td><div style={{ padding: '1em 0', width: "300px", display: 'flex', height: '300px' }}><FileUpload ref={upload} images={value} /></div></td> :
                                            <td><input type="text" name={key} defaultValue={value} /></td>}*/}
                                        <td><input type="text" name={key} defaultValue={value} /></td>
                                    </tr>
                                ))}
                                <>{images && <tr key={'images'}>
                                    <td>{columnNameToPretty('images')}</td>
                                    <td><div style={{ padding: '1em 0', width: "300px", display: 'flex', height: '300px' }}><FileUpload ref={upload} images={images} /></div></td>
                                </tr>}</>
                            </tbody>
                        </table>
                    </form>
                </div >
            </div>
        </div>
    );
});