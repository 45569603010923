import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Root from "../Root";
import NewProduct from "../components/product/NewProduct";
import NewService from "../components/service/NewService";
import SearchService from "../components/service/SearchService";
import TreePage from "../components/TreePage";
import Schema from "../components/Schema/Schema";
import ManageVariants from "../components/variant/ManageVariants";
import ManageCategories from "../components/category/ManageCategories";
import DualListBox from "../widgets/DualListBox";
import ListProducts from "../components/product/ListProducts";
import Menu from "../components/Menu/Menu";
import ManageGroups from "../components/group/ManageGroups";
import SliderManager from "../components/slider/SliderManager";
import FeaturedManager from "../components/fetaured/FeaturedManager";
import { Container } from "../components/Container";

const availableCategories = [
  { id: '1', value: 'Electronics' },
  { id: '2', value: 'Books' },
  { id: '3', value: 'Furniture' },
  { id: '4', value: 'Clothing' }
];

const selectedCategories = [
  { id: '5', value: 'Toys' },
  { id: '6', value: 'Sports' },
  { id: '1', value: 'Electronics' }
];
function ProductRoutes(){
    return(
        <Routes>
            <Route index element={<div>default page</div>} />
            <Route path="all" element={<ListProducts />} />
            <Route path="new" element={<NewProduct />} />
            <Route path="dashboard" element={<DualListBox availableCategories={availableCategories} selectedCategories={selectedCategories} />} />
            <Route path="slider" element={<SliderManager />} />
            <Route path="featured" element={<FeaturedManager />} />
            <Route path="type" element={<Schema />} />
            <Route path="variant" element={<ManageVariants />} />
            <Route path="category" element={<ManageCategories />} />
        </Routes>
    );
}
function MenuRoutes(){
  return(
      <Routes>
          <Route index element={<div>menu page</div>} />
          <Route path="edit" element={<Menu />} />
      </Routes>
  );
}
function ServiceRoutes(){
  return(
      <Routes>
          <Route index element={<div>service page</div>} />
          <Route path="new-service" element={<NewService />} />
          <Route path="dashboard" element={<div>dashboard</div>} />
          <Route path="search-service" element={<SearchService />} />
      </Routes>
  );
}
function HomeRoutes(){
  return(
      <Routes>
          <Route index element={<div>home page</div>} />
          <Route path="group" element={<ManageGroups />} />
      </Routes>
  );
}
function AppRoutes() {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<Root />}>
            <Route path="/" element={<Container />} />
            <Route path="/service/*" element={<ServiceRoutes />} />
            <Route path="/product/*" element={<ProductRoutes />} />
            <Route path="/menu/*" element={<MenuRoutes />} />
            <Route path="/home/*" element={<HomeRoutes />} />
            <Route path="/staff" element={<div>staff</div>} />
            <Route path="*" element={<p>error</p>} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
  export default AppRoutes;