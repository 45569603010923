import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useContext } from 'react';
import { Checkbox } from "./FormComponents";
import { AddToList, SubDirectoryRight, TickCircle, EditBox, DeleteSvg } from "../icons/Icon";
import { PlaceholderContext } from "../contexts/Contexts";
import { Dialog } from "../components/modal/Dialog";
 
let root = null;

const addRoot = (json) => {
    if(!('id' in json)) {
        //console.log('addroot', json);
        var node = {};
        node['id'] = -1;
        node['value'] = '\\root';
        node["parent"] = null;
        node['nodes'] = json.nodes || json;
        let array = [];
        array.push(node);
        root = node['nodes'];
        //console.log('addroot', array);
        return array;
    }
    return null;
};

/*const addRoot = (json) => {
    if (!('id' in json)) {
        console.log('addroot', json);
        json['id'] = -1;
        json['value'] = '\\root';
        json["parent"] = null;
        let array = [];
        array.push(json);
        root = json;
        console.log('addroot', array);
        return array;
    }
    return null;
};*/
const isRoot = (id) => {
    return id === 'root';
};

const insertInto = (node, parentId, json, order = null) => {

    var parent = findNodeByIdStack(parentId, json)?.node || root;
    //console.log('insertInto.parent', parentId, parent)
    var done = false;
    if (!parent) {
        console.error("Parent node or nodes array not found!");
        return;
    }
    if (!(parent.nodes && Array.isArray(parent.nodes))) {
        parent['nodes'] = [];
    }
    if (order === null) {
        done = parent.nodes.push(node);
        node.order = done - 1;
        node.parent = parent.id || -1;

    } else {
        node.parent = parent.id || -1;
        var inserted = false;
        for (let i = 0; i < parent.nodes.length; i++) {
            //console.log('inserting', i, order, i == order)
            if (parent.nodes[i].order == order) {
                parent.nodes.splice(i, 0, node);
                done = inserted = true;
                break;
            }
        }
        if (!inserted) {
            done = parent.nodes.push(node);
            node.order = done - 1;
        }
    }
    if (inserted) {
        for (let i = 0; i < parent.nodes.length; i++) {
            parent.nodes[i].order = i;
            parent.nodes[i]['edited'] = true;
        }
    }
    parent.nodes.sort((a, b) => a.order - b.order);

    return done ? true : false;
};
const findNodeByIdStack = (id, nodes) => {

    let stack = [{ nodes: nodes, parent: null }];

    while (stack.length > 0) {
        let { nodes, parent } = stack.pop();

        for (let index = 0; index < nodes.length; index++) {
            let node = nodes[index];

            if (node.id == id) {
                //console.log('findNodeById.node', id, node, nodes);
                return { node: node, index: index, parent: parent };
            }

            if (node.nodes && node.nodes.length > 0) {
                stack.push({ nodes: node.nodes, parent: node });
            }
        }
    }

    return null;
};
const findNodeInDeleted = () => {

};
const isParent = (source, destination, nodes) => {
    //console.log('isParent', source, destination, nodes);
    var s = findNodeByIdStack(source, nodes);
    var d = findNodeByIdStack(destination, nodes);

    if (s && d) {
        do {
            //console.log('isParent', d.parent, s.id);
            if (d.node.parent == s.node.id) {
                return true;
            }
            d = findNodeByIdStack(d.node.parent, nodes);
        } while (d && d.node && d.node.parent);
    }

    return false;
};
const deleteNode = (node) => {
    //console.log('deleteNode', node);
    if (node.parent && node.parent.nodes) {
        var deleted = node.parent.nodes.splice(node.index, 1)[0];
        if (deleted) {
            for (let i = 0; i < node.parent.nodes.length; i++) {
                node.parent.nodes[i].order = i;
                node.parent.nodes[i]['edited'] = true;
            }
        }
        if (node.parent.nodes.length === 0) {
            delete node.parent.nodes;
        }
        return deleted;
    } else {
        return deleteFromDeleted(node);
    }
};
const deleteAndMove = (node, parentId, json, order) => {
    var deleted = deleteNode(node);

    deleted.order = order;
    deleted['edited'] = true;
    delete deleted.deleted;
    //console.log('deleteAndMove', deleted);
    return insertInto(deleted, parentId, json, order);
};
function deleteFromDeleted(node) {
    var found = false;
    deleteFromDeleted.setDeleted(previous => {
        //var found = previous.find(p => p.id == node.id);
        var index = previous.indexOf(node);
        found = (index > -1) ? previous.splice(index, 1)[0] : node;
        //console.log('deleteNode',index);
        return [...previous];
    });
    return found;
}
const on_drop = function (e, json, deleted) {
    //console.log(e)
    //console.log('recieved', deleted);
    var done = false;
    if (!!e.in && !!e.source && (e.in !== e.source)) {
        var source = e.source.getAttribute('data-id');
        var destination = e.in.getAttribute('data-id');
        //console.log('getAttribute source', source, 'destination', destination, deleted);
        if (source && destination) {
            //var isp = isParent(source, destination, json);
            //console.log('isp', isp);
            if (isParent(source, destination, json) === false) {
                //console.log(`source: ${source}, destination: ${destination}`);
                source = findNodeByIdStack(source, json) || deleted.find(d => d.id == source);
                destination = findNodeByIdStack(destination, json);
                //console.log('ondrop.findNodeByIdStack', source, 'destination node', destination);
                if (source && destination) {
                    switch (true) {
                        case (e.edge && e.edge.indexOf('top') > -1):
                            done = deleteAndMove(source, destination.node.parent, json, destination.node.order);
                            break;
                        case (e.edge && e.edge.indexOf('bottom') > -1):
                            done = deleteAndMove(source, destination.node.parent, json, destination.node.order + 1);
                            break;
                        case (!e.edge):
                            done = deleteAndMove(source, destination.node.id, json);
                            break;
                    }
                }
            }
        }
    }
    if (done) {
        return json;
    }
};
const reRender = (setJson, json) => {
    if (json) {
        setJson([...json]);
    }
};
const on_add = (e, setJson, json, id) => {

};
const on_edit = (e, setJson, json, id) => {
    e.preventDefault();
    e.stopPropagation();
    var node = findNodeByIdStack(id, json);
    if (node) {
        node.node['edit'] = true;
        setJson([...json]);
    }
};
let counter = 0;
function onDrop(e) {
    //console.log('sending', onDrop.removed);
    var nodes = on_drop(e, onDrop.json, onDrop.removed);
    reRender(onDrop.setJson, nodes);
};
const Tree = forwardRef((p, r) => {
    
    
    //const root = addRoot([]);
    const [json, setJson] = useState();
    const [deleted, setDeleted] = useState([]);
    const [edited, setEdited] = useState([]);
    const [created, setCreated] = useState([]);
    const placeholder = useContext(PlaceholderContext);
    const inputs = useRef({});
    onDrop.removed = deleted;
    onDrop.json = json;
    onDrop.setJson = setJson;
    deleteFromDeleted.deleted = deleted;
    deleteFromDeleted.setDeleted = setDeleted;
    useEffect(() => {
        //console.log('p.json', p.json);
        //const root = ;
        setJson(addRoot(JSON.parse(JSON.stringify(p.json))));
        setDeleted([]);
        setEdited([]);
        setEdited([]);
        //root = addRoot(p.json)
    }, [p.json]);
    useImperativeHandle(r, () => ({
        getUpdates: () => {
            return { 'json': json[0].nodes, 'deleted': deleted, 'edited': edited, 'created': created }
        }
    }));
    useEffect(() => {
        //console.log('updates', edited, created);
        p.editUpdated(edited);
    }, [edited]);
    useEffect(() => {
        //console.log('updates', edited, created);
        p.deleteUpdated && p.deleteUpdated(deleted);
        onDrop['removed'] = deleted;
        onDrop['json'] = json;
    }, [deleted]);
    useEffect(() => {
        //console.log('updates', edited, created);
        p.createUpdated && p.createUpdated(created);
    }, [created]);
    //const NewNodeRegExp = new RegExp('input\-[0-9]+\-[0-9]+');
    //console.log(root);
    /*const populateChanges = (nodes) => {

        var stack = [];
        var node = null;
        var result = { 'new': [], 'edited':[], 'deleted':[]};

        function filter(node){
            switch(true){
                case (node['edited'] == true):

            }
        }

        if( !(nodes.nodes && nodes.nodes.length > 0) ){
            return null;
        }


        Array.prototype.push.apply(stack, nodes.nodes);
        console.log('stack', stack);
        while (stack.length > 0) {
            node = stack.pop();
    
            for (let index = 0; index < nodes.length; index++) {
                let node = nodes[index];
    
                if ('edited' in node) {
                    //console.log('findNodeById.node', id, node, nodes);
                    return { node: node, index: index, parent: parent };
                }
    
                if (node.nodes && node.nodes.length > 0) {
                    stack.push({ nodes: node.nodes, parent: node });
                }
            }
        }
    
        return null;
    };*/

    useEffect(() => {
        window.DragDrop.check('listItem', {
            source: {
                isDraggable: function (e) {
                    if (e.hasAttribute('data-pickable')) {
                        var pickable = e.getAttribute('data-pickable');
                        //console.log('got',pickable, pickable === 'no', e);
                        if (pickable === 'yes') {
                            return true;
                        } else if (pickable === 'no') {
                            return null;
                        }
                    }
                },
                onDragIn: function (e) {
                    //console.log('Enter target:', e.colission);
                    e.in && (e.in.style.backgroundColor = '#f5f5f5');
                    e.in && (e.in.style.border = '1px solid #1E88E5');
                },
                onDragOut: function (e) {
                    //console.log('Exit target:', e);
                    e.out && (e.out.style.backgroundColor = null);
                    e.out && (e.out.style.border = null);
                },
                onDragOver: function (e) {
                    //console.log('Over target:', e.hover, e.edge);
                },
                onPick: function (e) {
                    e.source.style.opacity = '0.3';
                    //console.log('Picked:', e);
                },
                onDrop: function (e) {
                    //console.log('Dropped:', e);
                    onDrop(e);
                    e.source.style.opacity = '';
                    e.out && (e.out.style.backgroundColor = null);
                    e.out && (e.out.style.border = null);
                    e.in && (e.in.style.backgroundColor = null);
                    e.in && (e.in.style.border = null);
                }
            },
            target: {
                isTarget: function (e) { return (e.getAttribute('data-target') === 'yes'); },
            }
        });
        return () => {
            window.DragDrop.uncheck('listItem');
        }
    }, []);


    //onDrop.deleted = deleted;
    //onDrop.json = json;
    function findNodeIndexByIdInArray(id, array) {
        if (array && array.length > 0) {
            for (var i = 0; i < array.length; i++) {
                if (array[i].id == id) {
                    return i;
                }
            }
        }
    }
    function updateStateArray(id, previous, node) {
        var index = findNodeIndexByIdInArray(id, previous);
        if (index > -1) {
            previous.splice(index, 1);
        }
        return [...previous, node];
    }
    const onEdit = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        let node = findNodeByIdStack(id, json);
        if (node) {
            node.node['edit'] = true;
            //setEdited(previous => updateStateArray(node.node.id, previous, node.node));
            setJson([...json]);
        }
        //console.log('onEdit', node);
    };
    const onAdd = (e, id, tempId) => {
        e.preventDefault();
        e.stopPropagation();
        //let newId = `${id}-${counter++}`;
        let node = {
            //"id": tempId,
            "id": `${id}/${counter++}`,
            "parent": id,
            "value": null,
            "order": null,
            "new": true,
            "edit": true
        };
        var done = insertInto(node, id, json);
        if (done) {

            setJson([...json]);
        }
        //console.log('onAdd', id, tempId, done);
    };
    const onSave = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        let value = inputs.current[id]?.value?.trim();
        let node = findNodeByIdStack(id, json);
        let parent = findNodeByIdStack(node.node.parent, json) || json;
        //console.log('onSave', value, node.node, parent);
        if (value && node && parent) {
            if (node.node.value && (node.node.value !== value)) {
                //console.log('onSave', node.node, value);
                node.node['edited'] = true;
                setEdited(previous => updateStateArray(node.node.id, previous, node.node));
            }
            if (node.node.new) {
                setCreated(previous => updateStateArray(node.node.id, previous, node.node));
            }
            node.node.value = value;
            delete inputs.current[id];
            delete node.node.edit;
            setJson([...json]);
        }
    };
    const onDeleteConfirm = (e, id) => {
        placeholder.openConfirm({
            onApprove: (a) => {
                a.actions.close();
                onDelete(e, id);
            }
        }, {
            heading: 'Confirm delete',
            message: 'The item will be send to deleted section you can still recover it before saving changes'
        })
    };
    const onDelete = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        let node = findNodeByIdStack(id, json);
        if (node.node && node.parent) {
            //console.log('delete ', node.node, node.parent, node.parent.nodes.indexOf(node.node));
            if (Array.isArray(node.parent.nodes)) {
                var removed = node.parent.nodes.splice(node.parent.nodes.indexOf(node.node), 1)[0];
                removed['deleted'] = true;
                //setDeleted(p => [...p, deleted]);
                //console.log('deleted ', removed, node.parent.nodes, updateStateArray(removed.id, deleted, removed));
                setDeleted(previous => updateStateArray(removed.id, previous, removed));

                if (node.parent.nodes.length == 0) {
                    delete node.parent.nodes;
                }
                if (removed) {
                    setJson([...json]);
                }
            }
        }

    };
    function render(json) {
        return (
            <>
                {(json && Array.isArray(json)) ? (<ul >{json.map((node, index) => {
                    let exists = node?.edit ? false : true;
                    let id = node.id || `${node.parent}/${index}`;
                    //let newId = `${node.id}-${counter++}`;
                    let key = `${id}-index`;
                    //console.log(exists, node.nodes);
                    return (
                        <li key={key} data-id={id} data-pickable="yes" data-target="yes" className={`tree-c-ul-li ${node?.nodes?.length > 0 && 'tree-c-connect'} ${node.edited && 'tree-edited'} ${node.new && 'tree-new'}`} >
                            {node.nodes && node.nodes.length > 0 && <Checkbox split={true} id={key} data-pickable="no" />}
                            {exists ?
                                (<div id={id} parent={node?.parent} className={'tree-c-ul-li-div'} >
                                    <span className={(node.selected && 'tree-select') || (node.surrogate && 'tree-surrogate') || null}>
                                        {node?.value}
                                    </span>
                                    <figure data-pickable="no">
                                        {!isRoot(id) && <>
                                            <EditBox name={'editListItem'} onClick={(e) => onEdit(e, id)} />
                                            <DeleteSvg onClick={(e) => onDeleteConfirm(e, id)} />
                                        </>}
                                        {node?.nodes ?
                                            <AddToList name={'addToList'} onClick={(e) => onAdd(e, id, 0)} /> :
                                            <SubDirectoryRight name={'newList'} onClick={(e) => onAdd(e, id, 0)} />
                                        }
                                    </figure>
                                </div>) :
                                (<div id={id} className={'tree-c-ul-li-div'} data-pickable="no" >
                                    <input type="text" className='tree-edit' defaultValue={node?.value} ref={(e) => (inputs.current[id] = e)} />
                                    <figure data-pickable="no">
                                        <TickCircle onClick={(e) => onSave(e, id)} />
                                    </figure>
                                </div>)}
                            {node.nodes && node.nodes.length > 0 && render(node.nodes)}
                        </li>
                    );
                })}</ul>) : null}
            </>
        );
    }
    return (
        <div className="tree-c">{render(json)}</div>
    );
});

export default Tree;