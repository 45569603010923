import { takeEvery, call, put, all } from 'redux-saga/effects';
import { UnitVariantActions } from '../redux/slices/UnitVariantSlice';
import { apiSubmit } from '../main';
import { toast } from 'react-toastify';

function* handleSetUnitVariant(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitVariantActions.setUnitVariantSuccess(response));
  } catch (e) {
    yield put(UnitVariantActions.setUnitVariantFailure(e.message));
  } finally {
  }
}

export function* watchSetUnitVariant() {
  yield takeEvery(UnitVariantActions.setUnitVariant, handleSetUnitVariant);
}

function* handleGetUnitVariant(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitVariantActions.getUnitVariantSuccess(response));
  } catch (e) {
    yield put(UnitVariantActions.getUnitVariantFailure(e.message));
  } finally {

  }
}
export function* watchGetUnitVariant() {
  yield takeEvery(UnitVariantActions.getUnitVariant, handleGetUnitVariant);
}


function* handleGetAllUnitVariants(action) {
  try {
    var loading = toast.loading('Loading Variants...',{
      closeOnClick: true,
      closeButton: true,
    });
    const response = yield call(apiSubmit, action.payload);
    if (response) {
      toast.update(loading, {
        render: "Variants loaded!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    }
    yield put(UnitVariantActions.getAllUnitVariantsSuccess(response));
  } catch (e) {
    //toast.dismiss(loading);
    toast.update(loading, {
      render: "Failed to load variants.",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
    yield put(UnitVariantActions.getAllUnitVariantsFailure(e.message));
  } finally {

  }
}
export function* watchGetAllUnitVariants() {
  yield takeEvery(UnitVariantActions.getAllUnitVariants, handleGetAllUnitVariants);
}

function* handleDeleteUnitVariant(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitVariantActions.deleteUnitVariantSuccess(response));
  } catch (e) {
    yield put(UnitVariantActions.deleteUnitVariantFailure(e.message));
  } finally {

  }
}
export function* watchDeleteUnitVariant() {
  yield takeEvery(UnitVariantActions.deleteUnitVariant, handleDeleteUnitVariant);
}

function* handleUpdateUnitVariant(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitVariantActions.putUnitVariantSuccess(response));
  } catch (e) {
    yield put(UnitVariantActions.putUnitVariantFailure(e.message));
  } finally {

  }
}
export function* watchUpdateUnitVariant() {
  yield takeEvery(UnitVariantActions.putUnitVariant, handleUpdateUnitVariant);
}
