import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null,
    create: null,
    read: null,
    delete: null,
    update: null
};

const UnitCategoryOptionSlice = createSlice({
    name: 'unitCategoryOption',
    initialState,
    reducers: {
        resetCreate: (state, action) => {
            state.create = null;
        },
        setUnitCategoryOption: (state, action) => {
            state.create = 'loading';
        },
        createUnitCategoryOption: (state, action) => {
            state.create = 'working';
        },
        setUnitCategoryOptionSuccess: (state, action) => {
            state.create = 'done';
            console.log(action);
        },
        setUnitCategoryOptionFailure: (state, action) => {
            state.create = 'error';
        },
        resetUpdate: (state, action) => {
            state.update = null;
        },
        putUnitCategoryOption: (state, action) => {
            state.update = 'loading';
        },
        updateUnitCategoryOption: (state, action) => {
            state.update = 'working';
        },
        putUnitCategoryOptionSuccess: (state, action) => {
            state.update = 'done';
        },
        putUnitCategoryOptionFailure: (state, action) => {
            state.update = 'error';
        },
        resetDelete: (state, action) => {
            state.delete = null;
        },
        deleteUnitCategoryOption: (state, action) => {
            state.delete = 'loading';
        },
        removeUnitCategoryOption: (state, action) => {
            state.delete = 'working';
        },
        deleteUnitCategoryOptionSuccess: (state, action) => {
            state.delete = 'done';
        },
        deleteUnitCategoryOptionFailure: (state, action) => {
            state.delete = 'error';
        },
        resetGet: (state, action) => {
            state.read = null;
        },
        getUnitCategoryOption: (state, action) => {
            state.read = 'loading';
        },
        fetchUnitCategoryOption: (state, action) => {
            state.read = 'working';
        },
        getUnitCategoryOptionSuccess: (state, action) => {
            state.read = 'done';
            state.data = action.payload.content.data;
        },
        getUnitCategoryOptionFailure: (state, action) => {
            state.read = 'error';
            state.data = null;
        }
    },
});

export const UnitCategoryOptionActions = UnitCategoryOptionSlice.actions;
export default UnitCategoryOptionSlice;