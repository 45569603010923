import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useContext } from 'react';
import { PlaceholderContext } from "../../contexts/Contexts";
import { $serializeForm, $TopologicalSort } from "../../main";
import { Dialog } from "../../components/modal/Dialog";
import { useDispatch, useSelector } from 'react-redux';
import BasicDataTable from "../../widgets/BasicDataTable";
import { UnitSchemaActions } from '../../redux/slices/UnitSchemaSlice';
import { UnitSchemaDefinitionActions } from '../../redux/slices/UnitSchemaDefinitionSlice';
import { UnitCategoryActions } from '../../redux/slices/UnitCategorySlice';
import { GET_UNIT_SCHEMA_DEFINITION, SET_UNIT_SCHEMA_DEFINITION, GET_ALL_UNIT_SCHEMA, GET_ALL_UNIT_CATEGORY } from "../../api/Api";
import Tree from "../../widgets/Tree";
import TreeSummary from "../../widgets/TreeSummary";
import { treeJson } from "../../db/TreeJson";
import { Loading } from "./../Loaders";
import { LoadingSvg, PlusSymbol, SaveSvg } from "../../icons/Icon";
import { NewUnitSchema, DeleteUnitSchema, EditUnitSchema } from './UnitSchema';
import DualListBox from '../../widgets/DualListBox';

function flattenTreeIterative(tree) {
    const result = [];
    const stack = [...tree];

    while (stack.length > 0) {
        const node = stack.pop();

        result.push({
            id: node.id,
            parent: node.parent,
            value: node.value,
            order: node.order,
            new: node.new,
            edited: node.edited
        });

        if (node.nodes && node.nodes.length > 0) {
            for (let i = node.nodes.length - 1; i >= 0; i--) {
                stack.push(node.nodes[i]);
            }
        }
    }

    return result;
}
const available = [
    { id: '1', value: 'Electronics' },
    { id: '2', value: 'Books' },
    { id: '3', value: 'Furniture' },
    { id: '4', value: 'Clothing' },
    { id: '5', value: 'Electronics' },
    { id: '6', value: 'Books' },
    { id: '7', value: 'Furniture' },
    { id: '8', value: 'Clothing' }
];

const selected = [
    { id: '10', value: 'Toys' },
    { id: '11', value: 'Sports' },
    { id: '12', value: 'Electronics' }
];
let blankJson = {
    nodes : []
};
const Schema = (p) => {

    const tree = useRef(null);
    const transfer = useRef(null);
    const [json, setJson] = useState(treeJson);
    const dataTable = useRef(null);
    const placeholder = useContext(PlaceholderContext);
    const [schema, setSchema] = useState(null);
    const [edited, setEdited] = useState();
    const [deleted, setDeleted] = useState();
    const [created, setCreated] = useState([]);
    const dispatch = useDispatch();
    //const services = useSelector(state => state.services.data);
    const allUnitSchemas = useSelector(state => state.unitSchema.data);
    const getAll = useSelector(state => state.unitSchema.getAll);
    const unitSchemaDefinition = useSelector(state => state.unitSchemaDefinition.data);
    const allUnitCategory = useSelector(state => state.unitCategory.data);
    const [unitSchemas, setUnitSchemas] = useState();

    function loadAllUnitSchemas(e) {
        e.preventDefault();
        dispatch(UnitSchemaActions.getAllUnitSchema(GET_ALL_UNIT_SCHEMA));
    }
    function getAllUnitSchemaState(create) {
        switch (create) {
            case 'loading':
            case 'working':
                setUnitSchemas(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                dispatch(UnitSchemaActions.resetGetAll());
                setUnitSchemas(<BasicDataTable
                    data={allUnitSchemas}
                    ref={dataTable}
                    searchClass={'search-table-search-flat'}
                    settings={{ show: ['name'], selectable: false, actions: { edit: doEdit, delete: doDelete, view: doView, deselect: getSelected } }} />);
                break;
            case 'error':
                dispatch(UnitSchemaActions.resetGetAll());
                setUnitSchemas(<button className='simple-form-button' onClick={loadAllUnitSchemas}>Load Schemas</button>);
                break;
        }
    }
    useEffect(() => {
        //console.log(allUnitCategory);
    }, [allUnitCategory]);
    useEffect(() => {
        getAllUnitSchemaState(getAll);
    }, [getAll]);
    useEffect(() => {
        //dispatch(ServicesActions.getAllService(GET_ALL_SERVICE));
        dispatch(UnitSchemaActions.getAllUnitSchema(GET_ALL_UNIT_SCHEMA));
        dispatch(UnitCategoryActions.getAllUnitCategory(GET_ALL_UNIT_CATEGORY));
    }, []);

    const getData = (e) => {
        var updates = tree.current.getUpdates();
        console.log(updates);
    };
    function editUpdated(e) {
        setEdited(e);
    }
    function deleteUpdated(e) {
        setDeleted(e);
    }
    function createUpdated(e) {
        setCreated(e);
    }
    const doEdit = (e) => {
        //console.log('doEdit',e);
        placeholder.openModal(<Dialog><EditUnitSchema id={e.id} value={e.name} success={() => dispatch(UnitSchemaActions.getAllUnitSchema(GET_ALL_UNIT_SCHEMA))} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Edit schema',
            message: ''
        })
    };
    const doDelete = (e) => {
        //console.log('delete', e);
        placeholder.openModal(<Dialog><DeleteUnitSchema id={e.id} value={e.name} success={() => dispatch(UnitSchemaActions.getAllUnitSchema(GET_ALL_UNIT_SCHEMA))} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Delete product type',
            message: ''
        })
    };
    const doView = (e) => {
        console.log('view', e);
        setSchema(e);
        dispatch(UnitSchemaDefinitionActions.getUnitSchemaDefinition(GET_UNIT_SCHEMA_DEFINITION(e.id)));
    };
    const getSelected = (e) => {
        //let selected = dataTable?.current.getSelectedRows();
        //console.log(selected)
    }
    const doSave = () => {
        var updated = tree.current.getUpdates().json;
        //console.log('dosave',updated);
        const flattened = flattenTreeIterative(updated);
        console.log('dosave', flattened);
        var sorted = $TopologicalSort(flattened);
        console.log('dosave', sorted);
        dispatch(UnitSchemaDefinitionActions.setUnitSchemaDefinition(SET_UNIT_SCHEMA_DEFINITION(schema?.id, sorted)));
    }
    const doSaveCategories = (d) => {

        let data = transfer.current.getData();
        console.log('doSaveCategories', data);
    };
    const onCreateNewSchema = (e, id) => {
        placeholder.openModal(<Dialog><NewUnitSchema success={() => dispatch(UnitSchemaActions.getAllUnitSchema(GET_ALL_UNIT_SCHEMA))} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Create new product type',
            message: ''
        })
    };
    return (
        <div className="page">
            <div className="flex-24">
                <div className="col-16 flex-column">
                    {/**
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span> {schema ? `Product type : ${schema.name || 'n/a'}` : 'Select product type'}</span><button className='page-box-header-yellow' onClick={doSaveCategories}><SaveSvg /> save</button></h4>
                        </div>
                        <div className='page-box-body'>
                            <DualListBox
                                ref={transfer}
                                available={allUnitCategory}
                                selected={selected}
                                doGet={doSaveCategories}
                            />
                        </div>
                    </div>
                 */}
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span> {schema ? `Modify ${schema.name} form` : 'Design product form'}</span></h4>
                        </div>
                        <div className='page-box-body'>
                            <div className="page-box-tree">
                                <Tree ref={tree} json={unitSchemaDefinition || blankJson} editUpdated={editUpdated} deleteUpdated={deleteUpdated} createUpdated={createUpdated} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-8 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span>Product types</span> <button className='page-box-header-green' onClick={onCreateNewSchema}><PlusSymbol /> new</button></h4>
                        </div>
                        <div className='page-box-body'>{unitSchemas}</div>
                    </div>
                    <div className="page-box page-box-sticky">
                        <div className='page-box-header'>
                            <h4><span>Changes Summary</span> <button className='page-box-header-yellow' onClick={doSave}><SaveSvg /> save</button></h4>
                        </div>
                        <div className='page-box-body'>
                            <TreeSummary edited={edited} deleted={deleted} created={created} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Schema;