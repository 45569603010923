import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    group: null,
    get: null,
    create: null,
    getAll: null,
    delete: null,
    update: null
};

const UnitGroupSlice = createSlice({
    name: 'unitGroup',
    initialState,
    reducers: {
        resetState:(state, action) => {
            state.data = [];
        },
        resetCreate: (state, action) => {
            state.create = null;
        },
        setUnitGroup: (state, action) => {
            state.create = 'loading';
        },
        createUnitGroup: (state, action) => {
            state.create = 'working';
        },
        setUnitGroupSuccess: (state, action) => {
            state.create = 'done';
        },
        setUnitGroupFailure: (state, action) => {
            state.create = 'error';
        },
        resetUpdate: (state, action) => {
            state.update = null;
        },
        putUnitGroup: (state, action) => {
            state.update = 'loading';
        },
        updateUnitGroup: (state, action) => {
            state.update = 'working';
        },
        putUnitGroupSuccess: (state, action) => {
            state.update = 'done';
        },
        putUnitGroupFailure: (state, action) => {
            state.update = 'error';
        },
        resetDelete: (state, action) => {
            state.delete = null;
        },
        deleteUnitGroup: (state, action) => {
            state.delete = 'loading';
        },
        removeUnitGroup: (state, action) => {
            state.delete = 'working';
        },
        deleteUnitGroupSuccess: (state, action) => {
            state.delete = 'done';
        },
        deleteUnitGroupFailure: (state, action) => {
            state.delete = 'error';
        },
        resetGet: (state, action) => {
            state.get = null;
        },
        getUnitGroup: (state, action) => {
            state.get = 'loading';
        },
        fetchUnitGroup: (state, action) => {
            state.get = 'working';
        },
        getUnitGroupSuccess: (state, action) => {
            state.get = 'done';
            state.variant = action.payload.content.data;
        },
        getUnitGroupFailure: (state, action) => {
            state.get = 'error';
            state.variant = null;
        },
        resetGetAll: (state, action) => {
            state.getAll = null;
        },
        getAllUnitGroup: (state, action) => {
            state.getAll = 'loading';
        },
        fetchAllUnitGroup: (state, action) => {
            state.getAll = 'working';
        },
        getAllUnitGroupSuccess: (state, action) => {
            state.getAll = 'done';
            state.data = action.payload.content.data;
        },
        getAllUnitGroupFailure: (state, action) => {
            state.getAll = 'error';
            state.data = null;
        }
    },
});

export const UnitGroupActions = UnitGroupSlice.actions;
export default UnitGroupSlice;
