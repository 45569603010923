import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null,
    create: null,
    read: null,
    delete: null,
    update: null
};

const UnitSchemaDefinitionSlice = createSlice({
    name: 'unitSchemaDefinition',
    initialState,
    reducers: {
        resetCreate: (state, action) => {
            state.create = null;
        },
        setUnitSchemaDefinition: (state, action) => {
            state.create = 'loading';
        },
        createUnitSchemaDefinition: (state, action) => {
            state.create = 'working';
        },
        setUnitSchemaDefinitionSuccess: (state, action) => {
            state.create = 'done';
            console.log(action);
        },
        setUnitSchemaDefinitionFailure: (state, action) => {
            state.create = 'error';
        },
        resetUpdate: (state, action) => {
            state.update = null;
        },
        putUnitSchemaDefinition: (state, action) => {
            state.update = 'loading';
        },
        updateUnitSchemaDefinition: (state, action) => {
            state.update = 'working';
        },
        putUnitSchemaDefinitionSuccess: (state, action) => {
            state.update = 'done';
        },
        putUnitSchemaDefinitionFailure: (state, action) => {
            state.update = 'error';
        },
        resetDelete: (state, action) => {
            state.delete = null;
        },
        deleteUnitSchemaDefinition: (state, action) => {
            state.delete = 'loading';
        },
        removeUnitSchemaDefinition: (state, action) => {
            state.delete = 'working';
        },
        deleteUnitSchemaDefinitionSuccess: (state, action) => {
            state.delete = 'done';
        },
        deleteUnitSchemaDefinitionFailure: (state, action) => {
            state.delete = 'error';
        },
        resetGet: (state, action) => {
            state.read = null;
        },
        getUnitSchemaDefinition: (state, action) => {
            state.read = 'loading';
        },
        fetchUnitSchemaDefinition: (state, action) => {
            state.read = 'working';
        },
        getUnitSchemaDefinitionSuccess: (state, action) => {
            state.read = 'done';
            state.data = action.payload.content.data;
        },
        getUnitSchemaDefinitionFailure: (state, action) => {
            state.read = 'error';
            state.data = null;
        }
    },
});

export const UnitSchemaDefinitionActions = UnitSchemaDefinitionSlice.actions;
export default UnitSchemaDefinitionSlice;