import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlaceholderContext } from "../../contexts/Contexts";
import { $serializeForm } from "../../main";
import { UnitVariantActions } from '../../redux/slices/UnitVariantSlice';
import { CREATE_UNIT_VARIANT, DELETE_UNIT_VARIANT, UPDATE_UNIT_VARIANT } from "../../api/Api";
import { Loading } from "../Loaders";
import { LoadingSvg } from "../../icons/Icon";

export const Unit = (p) => {

    const placeholder = useContext(PlaceholderContext);
    const form = useRef(null);
    const [button, setButtonState] = useState('Submit');

    function unitState(state) {
        switch (state) {
            case 'loading':
            case 'working':
                setButtonState(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                placeholder.openAlert({
                    onApprove: (a) => {
                        a.actions.close();
                    }
                }, {
                    heading: 'Success',
                    message: 'Task successful'
                })
                p.done();
                p.success();
                setButtonState('Submit');
                break;
            case 'error':
                placeholder.openAlert({
                    onApprove: (a) => {
                        a.actions.close();
                    }
                }, {
                    heading: 'Warning',
                    message: 'Task failed, try again.'
                })
                p.error();
                setButtonState('Try Again');
                break;
        }
    }
    useEffect(() => {
        unitState(p.state);
    }, [p.state]);

    const onClick = (e) => {
        e.preventDefault();
        if (p.state !== 'loading' || p.state !== 'working') {
            var [encoded, json] = $serializeForm(form.current);
            //console.log('UnitSchema', json);
            p.submit(json);
        }
    };

    return (
        <form ref={form} className="simple-form">
            <div className="flex-24">
                <div className="col-24 flex-column">
                    <div className="flex-column">
                        {p.id && <input type="text" name="id" defaultValue={p.id} style={{ display: 'none' }} />}
                        <label title=""><span>Name</span><input type="text" name="value" placeholder="" defaultValue={p.value || ''} { ...(p.disabled ? { disabled : true} : '') } /></label>
                    </div>
                    <div className="flex-column"><button onClick={onClick} className='simple-form-button' >{button}</button></div>
                </div>
            </div>
        </form>
    );
};

export const NewUnit = (p) => {

    const dispatch = useDispatch();
    const create = useSelector((state) => state.unitVariant.create);

    return (
        <Unit
            state={create}
            disabled={false}
            success={p.success}
            done={() => dispatch(UnitVariantActions.resetCreate())}
            error={() => dispatch(UnitVariantActions.resetCreate())}
            submit={(json) => dispatch(UnitVariantActions.setUnitVariant(CREATE_UNIT_VARIANT(json)))}
        />
    );
};

export const DeleteUnit = (p) => {

    const dispatch = useDispatch();
    const $delete = useSelector((state) => state.unitVariant.delete);

    return (
        <Unit
            state={$delete}
            value={p.value}
            id={p.id}
            disabled={true}
            success={p.success}
            done={() => dispatch(UnitVariantActions.resetDelete())}
            error={() => dispatch(UnitVariantActions.resetDelete())}
            submit={(json) => dispatch(UnitVariantActions.deleteUnitVarian(DELETE_UNIT_VARIANT(json['id'])))}
        />
    );
};

export const EditUnit = (p) => {

    const dispatch = useDispatch();
    const update = useSelector((state) => state.unitVariant.update);

    return (
        <Unit
            state={update}
            value={p.value}
            id={p.id}
            disabled={false}
            success={p.success}
            done={() => dispatch(UnitVariantActions.resetUpdate())}
            error={() => dispatch(UnitVariantActions.resetUpdate())}
            submit={(json) => dispatch(UnitVariantActions.putUnitVarian(UPDATE_UNIT_VARIANT(json)))}
        />
    );
};