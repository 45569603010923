export const SvgIcon = () => {

};
/*
className={p?.className} style={p?.style}
export const PlusSymbol = () => {
    return();
};
*/
export const RemoveCircleSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M280-440h400v-80H280v80ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>);
};
export const AddCircleSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M440-280h80v-160h160v-80H520v-160h-80v160H280v80h160v160Zm40 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>);
};
export const HomeSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path></svg>);
};
export const ProductSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><path d="m63 14.2-48 17c-1.2 0.4-2 1.6-2 2.8v60c0 1.3 0.8 2.4 2 2.8l48 17c0.3 0.1 0.7 0.2 1 0.2h0.5 0.1c0.1 0 0.2-0.1 0.3-0.1l48-17c1.2-0.4 2-1.6 2-2.8v-60.1-0.1-0.4-0.1c-0.2-1-0.9-1.9-1.9-2.2l-24-8.5h-0.1c-0.6-0.2-1.4-0.3-2.1 0l-46.8 16.5c-1.2 0.4-2 1.6-2 2.8v11c0 1.7 1.3 3 3 3s3-1.3 3-3v-8.9l43.8-15.5 15.2 5.4-40 14.2c-1.2 0.4-2 1.5-2 2.8v0.1 55.8l-42-15v-55.8l46-16.3c1.6-0.6 2.4-2.3 1.8-3.8-0.5-1.6-2.2-2.4-3.8-1.8zm4 38.9 42-14.9v53.6l-42 14.9v-53.6z" /></svg>);
};
export const ViewSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>);
};
export const ImageSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8z"></path><path d="m12 12-1-1-2 3h10l-4-6z"></path></svg>);
};
export const SaveSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 21h14a2 2 0 0 0 2-2V8l-5-5H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zM7 5h4v2h2V5h2v4H7V5zm0 8h10v6H7v-6z"></path></svg>);
};
export const UploadSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11 15h2V9h3l-4-5-4 5h3z"></path><path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path></svg>);
};
export const TickCircle = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>);
};
export const EditRow = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m7 17.013 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58zM9 13.417l6.03-5.973 1.586 1.586-6.029 5.971L9 15.006v-1.589z"></path><path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"></path></svg>);
};
export const EditBox = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m7 17.013 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58zM9 13.417l6.03-5.973 1.586 1.586-6.029 5.971L9 15.006v-1.589z"></path><path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"></path></svg>);
};
export const EditPencilDark = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m16 2.012 3 3L16.713 7.3l-3-3zM4 14v3h3l8.299-8.287-3-3zm0 6h16v2H4z"></path></svg>);
};
export const SubDirectoryRight = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M14 13H8V5H6v9a1 1 0 0 0 1 1h7v3l5-4-5-4v3z"></path></svg>);
};
export const DeleteSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" /></svg>);
};
export const AddLayerSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path></svg>);
};
export const CloseSvg = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><polygon points="18.707,6.707 17.293,5.293 12,10.586 6.707,5.293 5.293,6.707 10.586,12 5.293,17.293 6.707,18.707 12,13.414 17.293,18.707 18.707,17.293 13.414,12 "/></svg>);
};
export const AddToList = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M19 15v-3h-2v3h-3v2h3v3h2v-3h3v-2h-.937zM4 7h11v2H4zm0 4h11v2H4zm0 4h8v2H4z"></path></svg>);
};
export const PlusSymbol = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg>);
};
export const MinusSymbol = (p) => {
    return(<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M5 11h14v2H5z"></path></svg>);
};
export const LoadingSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <radialGradient id="a10" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                <stop offset="0" stopColor="#6F7474"></stop>
                <stop offset=".3" stopColor="#6F7474" stopOpacity=".9"></stop>
                <stop offset=".6" stopColor="#6F7474" stopOpacity=".6"></stop>
                <stop offset=".8" stopColor="#6F7474" stopOpacity=".3"></stop>
                <stop offset="1" stopColor="#6F7474" stopOpacity="0"></stop>
            </radialGradient>
            <circle
                transform-origin="center"
                fill="none"
                stroke="url(#a10)"
                strokeWidth="15"
                strokeLinecap="round"
                strokeDasharray="200 1000"
                strokeDashoffset="0"
                cx="100"
                cy="100"
                r="70"
            >
                <animateTransform
                    type="rotate"
                    attributeName="transform"
                    calcMode="spline"
                    dur="0.7s"
                    values="360;0"
                    keyTimes="0;1"
                    keySplines="0 0 1 1"
                    repeatCount="indefinite"
                ></animateTransform>
            </circle>
            <circle
                transform-origin="center"
                fill="none"
                opacity=".2"
                stroke="#6F7474"
                strokeWidth="15"
                strokeLinecap="round"
                cx="100"
                cy="100"
                r="70"
            ></circle>
        </svg>
    );
};


/*
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a10" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#6F7474"></stop><stop offset=".3" stop-color="#6F7474" stop-opacity=".9"></stop><stop offset=".6" stop-color="#6F7474" stop-opacity=".6"></stop><stop offset=".8" stop-color="#6F7474" stop-opacity=".3"></stop><stop offset="1" stop-color="#6F7474" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a10)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="0.7" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#6F7474" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
*/