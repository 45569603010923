import { useRef, useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import FileUpload from "../widgets/FileUpload";
import { GET_VARIANT_BY_VARIANT_ID } from "../api/Api";
import { apiRequestAsyncThunk } from "../thunk/CommonThunk";
import { Loading } from "./Loaders";
import { LoadingSvg } from "../icons/Icon";


export const Container = ({ children }) => {

    const uploader = useRef(null);
    const [images, setImages] = useState([]);
    const dispatch = useDispatch();

    const showVariant = (state, data) => {
        //console.log('Menu.showMainMenu', state, data?.data);
        switch (state) {
            case 'loading':
                setImages(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                let images = data.data.images;
                setImages(<FileUpload ref={uploader} images={images} />);
                break;
            case 'error':
                setImages(<button className='simple-form-button' onClick={() => { }}>Load variants</button>);
                break;
        }
    }
    const loadVariant = (e) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_VARIANT_BY_VARIANT_ID(15),
            call: showVariant,
            messages: {
                loading: 'Loading variants',
                done: 'Variants loaded',
                error: 'Error loading variants'
            }
        }));
    }
    useEffect(() => {
        loadVariant();
    }, []);
    return (
        <div className="container">
            <div className="page">
                <div className="flex-24">
                    <div className="file-upload-container" style={{ display: 'flex' }}>
                        {images}
                    </div>
                </div>
            </div>


        </div>
    );
}