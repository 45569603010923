import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useDispatch } from 'react-redux';
import { PUT_VARIANT_BY_VARIANT_ID, GET_VARIANT_BY_VARIANT_ID } from "../../api/Api";
import { apiRequestAsyncThunk } from "../../thunk/CommonThunk";
import { LoadingSvg } from "../../icons/Icon";
import { Loading } from "../Loaders";
import { JsonToTable, JsonToEditableTable } from "../../widgets/JsonToTable";
import { printFormData } from "../../main";

export const VariantView = (e) => {

    const dispatch = useDispatch();
    const [variant, setVariant] = useState(null);
    const dataTable = useRef(null);
    const loadVariant = (e) => {
        if (e.variantId > 0) {
            dispatch(apiRequestAsyncThunk({
                request_details: GET_VARIANT_BY_VARIANT_ID(e.variantId),
                call: showVariant,
                messages: {
                    loading: 'Loading variant',
                    done: 'Variant loaded',
                    error: 'Error loading variant'
                }
            }));
        }
    }
    const showVariant = (state, data) => {
        switch (state) {
            case 'loading':
                setVariant(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                setVariant(<JsonToTable json={data.data} />);
                break;
            case 'error':
                setVariant(<button className='simple-form-button' onClick={loadVariant}>Load variants</button>);
                break;
        }
    }

    useEffect(() => {
        loadVariant(e);
    }, [e]);

    return (<>{variant}</>);
}
export const VariantEditView = (e) => {

    const dispatch = useDispatch();
    const [variant, setVariant] = useState(null);
    const dataTable = useRef(null);
    const form = useRef(null);

    const saveVariant = (e) => {
        let { variant: variant, formData: formData } = form.current.getFormData();
        //console.log('VariantEditView.saveVariant', variant, formData);
        
        //printFormData(formData);
 
        if (variant.id > 0) {
            dispatch(apiRequestAsyncThunk({
                request_details: PUT_VARIANT_BY_VARIANT_ID(variant.id, formData),
                call: (e) => { console.log('saveVariant', e) },
                messages: {
                    loading: 'Updating variant',
                    done: 'Variant updated',
                    error: 'Error updating variant'
                }
            }));
        }

    }
    const loadVariant = (e) => {
        if (e.variantId > 0) {
            dispatch(apiRequestAsyncThunk({
                request_details: GET_VARIANT_BY_VARIANT_ID(e.variantId),
                call: showVariant,
                messages: {
                    loading: 'Loading variant',
                    done: 'Variant loaded',
                    error: 'Error loading variant'
                }
            }));
        }
    }
    const showVariant = (state, data) => {
        switch (state) {
            case 'loading':
                setVariant(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                setVariant(<JsonToEditableTable ref={form} json={data.data} onSave={saveVariant} />);
                break;
            case 'error':
                setVariant(<button className='simple-form-button' onClick={loadVariant}>Load variant</button>);
                break;
        }
    }

    useEffect(() => {
        loadVariant(e);
    }, [e]);

    return (<>{variant}</>);
}