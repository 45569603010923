import { takeEvery, call, put, all } from 'redux-saga/effects';
import { UnitCategoryActions } from '../redux/slices/UnitCategorySlice';
import { apiSubmit } from '../main';

function* handleSetUnitCategory(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitCategoryActions.setUnitCategorySuccess(response));
  } catch (e) {
    yield put(UnitCategoryActions.setUnitCategoryFailure(e.message));
  }finally{
  }
}

export function* watchSetUnitCategory() {
  yield takeEvery(UnitCategoryActions.setUnitCategory, handleSetUnitCategory);
}

function* handleGetUnitCategory(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitCategoryActions.getUnitCategorySuccess(response));
  } catch (e) {
    yield put(UnitCategoryActions.getUnitCategoryFailure(e.message));
  }finally{

  }
}
export function* watchGetUnitCategory() {
  yield takeEvery(UnitCategoryActions.getUnitCategory, handleGetUnitCategory);
}


function* handleGetAllUnitCategory(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitCategoryActions.getAllUnitCategorySuccess(response));
  } catch (e) {
    yield put(UnitCategoryActions.getAllUnitCategoryFailure(e.message));
  }finally{

  }
}
export function* watchGetAllUnitCategory() {
  yield takeEvery(UnitCategoryActions.getAllUnitCategory, handleGetAllUnitCategory);
}

function* handleDeleteUnitCategory(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitCategoryActions.deleteUnitCategorySuccess(response));
  } catch (e) {
    yield put(UnitCategoryActions.deleteUnitCategoryFailure(e.message));
  }finally{

  }
}
export function* watchDeleteUnitCategory() {
  yield takeEvery(UnitCategoryActions.deleteUnitCategory, handleDeleteUnitCategory);
}

function* handleUpdateUnitCategory(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitCategoryActions.putUnitCategorySuccess(response));
  } catch (e) {
    yield put(UnitCategoryActions.putUnitCategoryFailure(e.message));
  }finally{

  }
}
export function* watchUpdateUnitCategory() {
  yield takeEvery(UnitCategoryActions.putUnitCategory, handleUpdateUnitCategory);
}
