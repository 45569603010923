import React, { Fragment, useEffect, forwardRef, useContext, useImperativeHandle, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { PlaceholderContext } from "../../contexts/Contexts";
import { CloseSvg } from "../../icons/Icon";

const ComponentWrapper = (Component, a) => {

    return(<Component {...a} />);
};
const DialogWrapper = (Component, a) => {

    return(<Component {...a} />);
};
const Dialog = (p) => {
    //console.log('Dialog', p.controls);
    const approve = (e) => {
        (p?.controls?.onApprove) ? p?.controls?.onApprove({ ...e, ...p }) : p?.actions?.close();
    };
    const reject = (e) => {
        (p?.controls?.onCancel) ? p?.controls?.onCancel({ ...e, ...p }) : p?.actions?.close();
    };
    return (
        <section className='card-modal'>
            <header>
                <h2>
                    <span>{p?.attributes?.heading}</span>
                    <figure onClick={reject}><CloseSvg /></figure></h2>
            </header>
            <div>
                {p?.children || (<p>p?.attributes?.message</p>)}
            </div>
            <footer>
                <button onClick={approve}>Okay</button>
            </footer>
        </section>
    );
};
const DialogOnlyClose = (p) => {
    //console.log('Dialog', p.controls);
    const approve = (e) => {
        (p?.controls?.onApprove) ? p?.controls?.onApprove({ ...e, ...p }) : p?.actions?.close();
    };
    const reject = (e) => {
        (p?.controls?.onCancel) ? p?.controls?.onCancel({ ...e, ...p }) : p?.actions?.close();
    };
    return (
        <section className='card-modal'>
            <header>
                <h2>
                    <span>{p?.attributes?.heading}</span>
                    <figure onClick={reject}><CloseSvg /></figure></h2>
            </header>
            <div>
                {p?.children || (<p>p?.attributes?.message</p>)}
            </div>
        </section>
    );
};
const Alert = (p) => {
    const approve = (e) => {
        (p?.controls?.onApprove) ? p?.controls?.onApprove({ ...e, ...p }) : p?.actions?.close();
    };
    return (
        <section className='card-modal'>
            <header>
                <h2>{p?.attributes?.heading}</h2>
            </header>
            <div>
                <p>{p?.attributes?.message}</p>
            </div>
            <footer>
                <button onClick={approve}>Okay</button>
            </footer>
        </section>
    );
}
const Confirm = (p) => {
    const approve = (e) => {
        (p?.controls?.onApprove) ? p?.controls?.onApprove({ ...e, ...p }) : p?.actions?.close();
    };
    const reject = (e) => {
        (p?.controls?.onCancel) ? p?.controls?.onCancel({ ...e, ...p }) : p?.actions?.close();
    };
    return (
        <section className='card-modal'>
            <header>
                <h2>{p?.attributes?.heading}</h2>
            </header>
            <div>
                <p>{p?.attributes?.message}</p>
            </div>
            <footer>
                <button onClick={approve}>Okay</button>
                <button onClick={reject}>Cancel</button>
            </footer>
        </section>
    );
}
const Popup = forwardRef((p, r) => {

    const placeholder = useContext(PlaceholderContext);
    const self = useRef();
    const [id, setId] = useState(p.id);
    useImperativeHandle(r, () => ({
        getRef: () => self,
        getId: () => id
    }));
    const createSurface = () => {
        var id = null;
        var div = document.createElement('div');
        var ids = placeholder.modalComponent.map(c => c.ref);
        console.log(ids);
        id = Math.random().toString().replace('.', '');

        div.setAttribute('id', id);
        div.setAttribute('key', id);
        return document.body.insertBefore(div, document.body.firstChild);
    }
    const onEscape = (e) => {
        //console.log(e);
        if(e.key && (e.key.toLowerCase() === 'escape')){
            p?.actions?.close();
        }
        
    };
    useEffect(() => {
        document.addEventListener('keydown', onEscape);
        return () => document.removeEventListener('keydown', onEscape);
    }, []);
    return (
        <Fragment>
            {ReactDOM.createPortal(
                <div ref={self} id={p.id}>
                    <div className='backdrop'></div>
                    <div className='overlay'>{p.children}</div>
                </div>,
                document.getElementById('modal-root')
            )}
        </Fragment>
    );
});

export { Popup, Confirm, Alert, Dialog, DialogOnlyClose, DialogWrapper, ComponentWrapper };