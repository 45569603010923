import React, { useEffect, useRef, useState, useContext } from "react";
import { makeUrl, trimStringGaps } from "../../main";
import Meta from "../../widgets/Meta";
import MyQuillEditor from "../../widgets/MyQuillEditor";
import ConfirmButton from "../ConfirmButton";
import { SelectOptionsSection } from "../../widgets/MultiSelectOptions";
import { PlaceholderContext } from "../../contexts/Contexts";
import { Dialog } from "../modal/Dialog";

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'cucumba', label: 'Cucumba' },
    { value: 'banana', label: 'Banana' },
];

const Sample = () => {
    return (<div>modal content</div>);
};
const passthrough = <Dialog><SelectOptionsSection name='services' multiple={true} options={options} selected={options.slice(1, 3)} /></Dialog>;
//const passthrough = <SelectOptionsSection name='services' multiple={true} options={options} selected={options.slice(1,3)} />;
//console.log(typeof SelectOptionsSection);
const ServiceForm = (p) => {

    const service = useRef(null);
    const [form, formState] = useState(p?.data);
    const [name, setName] = useState(form?.name);
    const [url, setUrl] = useState(form?.url);
    const placeholder = useContext(PlaceholderContext);

    useEffect(() => {
        setUrl(p?.data?.url)
        setName(p?.data?.name)
        formState(p.data);
    }, [p.data]);

    function doUrl(e) {
        setUrl(makeUrl(e.target.value));
    }
    function trim(e) {
        setName(trimStringGaps(e.target.value));
    }
    function submit(e) {
        e.preventDefault();
        p.onSubmit(service.current);
    }
    const onChange = (e) => {
        const { name, value } = e.target;
        console.log('onchange', name, value)
        if (name === 'name') {
            setName(value);
        } else if (name === 'url') {
            setUrl(value);
        } else {
            formState(data => ({
                ...data, [name]: value
            }))
        }
    };
    const openModal = (e) => {
        e.preventDefault();
        if (false) {
            placeholder.openAlert({
                onApprove: (a) => {
                    a.actions.close();
                }
            }, {
                heading: 'No items',
                message: 'There are no items in the cart please add items to see the cart!'
            })
        } else {
            //setShow(true);

            placeholder.openModal(passthrough, {
                onApprove: (a) => {
                    //ok();
                    a.actions.close();
                }
            }, {
                heading: 'it works',
                message: 'the modal works'
            })

        }

    };
    return (
        <form action="" ref={service} onChange={p.onChange} className="simple-form" method="POST" encType="multipart/form-data" autoComplete="on" target="_blank" noValidate>
            <div className="flex-24">
                <div className="col-18 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4>Service</h4>
                        </div>
                        <div className='page-box-body'>
                            <div className="flex-column">
                                <SelectOptionsSection name='services' multiple={true} options={options} selected={options.slice(1, 3)} />
                                <input type="text" name="id" value={form?.id || ''} style={{ display: 'none' }} onChange={onChange} />
                                <label title="url friendly version of the service name"><span>URL</span><input type="text" name="url" placeholder="" value={url || ''} onKeyUp={doUrl} onChange={onChange} /></label>
                                <label title="service name"><span>Name</span><input type="text" name="name" placeholder="" value={name || ''} onKeyUp={trim} onChange={onChange} /></label>
                                <label title="brief description of the service"><span>Abstract</span><textarea name="abstract" rows="6" placeholder="" value={form?.abstract || ''} onChange={onChange} ></textarea></label>
                                <label title="detailed description of the service"><span>Details</span><MyQuillEditor name='details' value={form?.details || ''} onChange={onChange} /></label>
                            </div>
                        </div>
                    </div>
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4>Scripts</h4>
                        </div>
                        <div className='page-box-body'>
                            <div className="flex-column">
                                <label title="header scripts"><span>Header Script</span><textarea name="header" rows="6" placeholder="" value={form?.header || ''} onChange={onChange} ></textarea></label>
                                <label title="type unit name"><span>Footer Script</span><textarea name="footer" rows="6" placeholder="" value={form?.footer || ''} onChange={onChange} ></textarea></label>
                            </div>
                        </div>
                    </div>
                    <Meta value={form?.meta} />
                </div>
                <div className="col-6 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4>Submit Service</h4><button onClick={(e) => openModal(e)} >open modal</button>
                        </div>
                        <div className='page-box-body'>
                            <div className="flex-row">
                                <ConfirmButton className={'confirm-grey'} onConfirm={submit} label={p.label} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ServiceForm;