import React, { useState, useRef, useEffect, useContext } from 'react';
import { PlaceholderContext } from "../../contexts/Contexts";
import { $serializeForm } from "../../main";
import { Dialog } from "../modal/Dialog";
import { useDispatch, useSelector } from 'react-redux';
import BasicDataTable from "../../widgets/BasicDataTable";
import { GET_VARIANTS_BY_GROUP, GET_ALL_UNIT_GROUP, GET_ALL_VARIANTS, SET_VARIANTS_BY_GROUP } from "../../api/Api";
import { Loading } from "../Loaders";
import { LoadingSvg, PlusSymbol, SaveSvg } from "../../icons/Icon";
import { NewUnit, DeleteUnit, EditUnit } from './Unit';
import { apiRequestAsyncThunk } from '../../thunk/CommonThunk';
import { dateTimeFormatted } from '../../main';

let grouping = [];
let group = null;
const ManageGroups = (p) => {

    const dataTable = useRef(null);
    const groupedTable = useRef(null);
    const placeholder = useContext(PlaceholderContext);
    const dispatch = useDispatch();
    //const [group, setGroup] = useState(null);
    const [variants, setVariants] = useState([]);
    const [groups, setGroups] = useState([]);
    const [grouped, setGrouped] = useState([]);

    const loadVariants = (e) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_ALL_VARIANTS,
            call: showVariants,
            messages: {
                loading: 'Loading variants',
                done: 'Variants loaded',
                error: 'Error loading variants'
            }
        }));
    }
    const loadGroups = (e) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_ALL_UNIT_GROUP,
            call: showGroups,
            messages: {
                loading: 'Loading groups',
                done: 'Groups loaded',
                error: 'Error loading Groups'
            }
        }));
    }
    const loadGrouped = (e) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_VARIANTS_BY_GROUP(e.id),
            call: showGrouped,
            messages: {
                loading: 'Loading groups',
                done: 'Groups loaded',
                error: 'Error loading Groups'
            }
        }));
    }
    const saveGrouped = (groupId, data) => {
        dispatch(apiRequestAsyncThunk({
            request_details: SET_VARIANTS_BY_GROUP(groupId, data),
            call: showGrouped,
            messages: {
                loading: 'Saving groups',
                done: 'Groups saved',
                error: 'Error saving groups'
            }
        }));
    }
    const showGroups = (state, data) => {
        //console.log('Menu.showMainMenu', state, data?.data);
        switch (state) {
            case 'loading':
                setGroups(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                setGroups(<BasicDataTable
                    data={data.data}
                    ref={dataTable}
                    searchClass={'search-table-search-flat'}
                    settings={{
                        show: ['value'],
                        selectable: false,
                        actions: { edit: doEdit, delete: doDelete, view: doView, deselect: getSelected },
                        pageSize: 10
                    }}
                />);
                break;
            case 'error':
                setGroups(<button className='simple-form-button' onClick={loadGroups}>Load groups</button>);
                break;
        }
    }
    const showGrouped = (state, data) => {
        switch (state) {
            case 'loading':
                setGrouped(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                console.log('showGrouped', data);
                //grouping = [];
                if (Array.isArray(data?.data)) {
                    console.log('got', data.data);
                    grouping = data.data || [];
                }
                setGrouped(<BasicDataTable
                    data={[...grouping]}
                    ref={groupedTable}
                    searchClass={'search-table-search-flat'}
                    settings={{
                        show: ['name', 'skuName', 'skuCode'],
                        selectable: false,
                        actions: { delete: removeFromGroup },
                        pageSize: 4
                    }}
                />);
                break;
            case 'error':
                grouping = [];
                setGrouped(<button className='simple-form-button' onClick={loadGrouped}>Load grouped variants</button>);
                break;
        }
    }
    const showVariants = (state, data) => {
        //console.log('Menu.showMainMenu', state, data?.data);
        switch (state) {
            case 'loading':
                setVariants(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                let variants = data.data;
                variants = variants.map(product => ({
                    ...product,
                    create_date: dateTimeFormatted(product.create_date),
                    update_date: dateTimeFormatted(product.update_date)
                }));
                setVariants(<BasicDataTable
                    data={variants}
                    ref={dataTable}
                    searchClass={'search-table-search-flat'}
                    settings={{
                        show: ['name', 'skuName', 'skuCode', 'create_date'],
                        selectable: false,
                        actions: { deselect: getSelected, add: addToGroup },
                        pageSize: 4
                    }}
                />);
                break;
            case 'error':
                setVariants(<button className='simple-form-button' onClick={loadVariants}>Load variants</button>);
                break;
        }
    }
    useEffect(() => {
        loadVariants();
        loadGroups();
    }, []);


    const doEdit = (e) => {
        console.log('doEdit', e);
        placeholder.openModal(<Dialog><EditUnit id={e.id} value={e.value} success={loadGroups} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Edit Group',
            message: ''
        })
    };
    const doDelete = (e) => {
        placeholder.openModal(<Dialog><DeleteUnit id={e.id} value={e.value} success={loadGroups} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Delete Group',
            message: ''
        })
    };
    const doView = (e) => {
        //setGroup(e);
        group = e;
        loadGrouped(e);
    };
    const getSelected = (e) => {
        //let selected = dataTable?.current.getSelectedRows();
        //console.log(selected)
    }
    const removeFromGroup = (e) => {
        if (Array.isArray(grouping) && e?.id > -1) {
            let found = grouping.find(group => group.id == e.id && group.productId == e.productId);
            if (found) {
                grouping.splice(grouping.indexOf(found), 1);
                showGrouped('done', { data: e });
            }
        }
    };
    const addToGroup = (e) => {
        console.log('addToGroup', group);
        if (Array.isArray(grouping) && e?.id > -1 && group != null) {
            let found = grouping.find(g => g.id == e.id && g.productId == e.productId);
            if (!found) {
                grouping.push(e);
                showGrouped('done');
            }
        }
    };
    const saveVariantsByGroupId = (e) => {
        e.preventDefault();
        console.log('saveVariantsByGroupId', group, grouping);
        if (Array.isArray(grouping) && group.id > -1) {
            console.log('saveVariantsByGroupId', grouping, group.id);
            saveGrouped(group.id, grouping);
        }

    }
    const onCreateNewGroup = (e, id) => {
        placeholder.openModal(
            <Dialog><NewUnit success={() => loadGroups()} /> </Dialog>, {
            onApprove: (a) => { a.actions.close(); }
        }, {
            heading: 'Create new Group',
            message: ''
        })
    };
    return (
        <div className="page">
            <div className="flex-24">
                <div className="col-16 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span>Variants</span></h4>
                        </div>
                        <div className='page-box-body'>{variants}</div>
                    </div>
                </div>
                <div className="col-8 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span>Groups</span> <button className='page-box-header-green' onClick={onCreateNewGroup}><PlusSymbol /> new</button></h4>
                        </div>
                        <div className='page-box-body'>{groups}</div>
                    </div>
                </div>
            </div>
            <div className="flex-24">
                <div className="col-16 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4>
                                <span>{(group) ? `Add to ${group.value} group` : 'Select group'}</span>
                                <button className="page-box-header-yellow " onClick={saveVariantsByGroupId}><SaveSvg /> save</button>
                            </h4>
                        </div>
                        <div className='page-box-body'>{grouped}</div>
                    </div>
                </div>
                <div className="col-6 flex-column">
                </div>
            </div>
        </div>
    );
};

export default ManageGroups;