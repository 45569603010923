import { takeEvery, call, put, all } from 'redux-saga/effects';
import { UnitSchemaActions } from '../redux/slices/UnitSchemaSlice';
import { apiSubmit } from '../main';
import { toast } from 'react-toastify';

function* handleSetUnitSchema(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitSchemaActions.setUnitSchemaSuccess(response));
  } catch (e) {
    yield put(UnitSchemaActions.setUnitSchemaFailure(e.message));
  }finally{
  }
}

export function* watchSetUnitSchema() {
  yield takeEvery(UnitSchemaActions.setUnitSchema, handleSetUnitSchema);
}

function* handleGetAllUnitSchema(action) {
  try {
    var loading = toast.loading('Loading Product Types...',{
      closeOnClick: true,
      closeButton: true,
    });
    const response = yield call(apiSubmit, action.payload);
    if (response) {
      toast.update(loading, {
        render: "Product Types loaded!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    }
    yield put(UnitSchemaActions.getAllUnitSchemaSuccess(response));
  } catch (e) {
    toast.update(loading, {
      render: "Failed to load Product Types.",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
    yield put(UnitSchemaActions.getAllUnitSchemaFailure(e.message));
  }finally{

  }
}
export function* watchGetAllUnitSchema() {
  yield takeEvery(UnitSchemaActions.getAllUnitSchema, handleGetAllUnitSchema);
}

function* handleDeleteUnitSchema(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitSchemaActions.deleteUnitSchemaSuccess(response));
  } catch (e) {
    yield put(UnitSchemaActions.deleteUnitSchemaFailure(e.message));
  }finally{

  }
}
export function* watchDeleteUnitSchema() {
  yield takeEvery(UnitSchemaActions.deleteUnitSchema, handleDeleteUnitSchema);
}

function* handleUpdateUnitSchema(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitSchemaActions.putUnitSchemaSuccess(response));
  } catch (e) {
    yield put(UnitSchemaActions.putUnitSchemaFailure(e.message));
  }finally{

  }
}
export function* watchUpdateUnitSchema() {
  yield takeEvery(UnitSchemaActions.putUnitSchema, handleUpdateUnitSchema);
}
