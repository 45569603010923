import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    category: null,
    get: null,
    create: null,
    getAll: null,
    delete: null,
    update: null
};

const UnitCategorySlice = createSlice({
    name: 'unitCategory',
    initialState,
    reducers: {
        resetState:(state, action) => {
            state.data = [];
        },
        resetCreate: (state, action) => {
            state.create = null;
        },
        setUnitCategory: (state, action) => {
            state.create = 'loading';
        },
        createUnitCategory: (state, action) => {
            state.create = 'working';
        },
        setUnitCategorySuccess: (state, action) => {
            state.create = 'done';
        },
        setUnitCategoryFailure: (state, action) => {
            state.create = 'error';
        },
        resetUpdate: (state, action) => {
            state.update = null;
        },
        putUnitCategory: (state, action) => {
            state.update = 'loading';
        },
        updateUnitCategory: (state, action) => {
            state.update = 'working';
        },
        putUnitCategorySuccess: (state, action) => {
            state.update = 'done';
        },
        putUnitCategoryFailure: (state, action) => {
            state.update = 'error';
        },
        resetDelete: (state, action) => {
            state.delete = null;
        },
        deleteUnitCategory: (state, action) => {
            state.delete = 'loading';
        },
        removeUnitCategory: (state, action) => {
            state.delete = 'working';
        },
        deleteUnitCategorySuccess: (state, action) => {
            state.delete = 'done';
        },
        deleteUnitCategoryFailure: (state, action) => {
            state.delete = 'error';
        },
        resetGet: (state, action) => {
            state.get = null;
        },
        getUnitCategory: (state, action) => {
            state.get = 'loading';
        },
        fetchUnitCategory: (state, action) => {
            state.get = 'working';
        },
        getUnitCategorySuccess: (state, action) => {
            state.get = 'done';
            state.variant = action.payload.content.data;
        },
        getUnitCategoryFailure: (state, action) => {
            state.get = 'error';
            state.variant = null;
        },
        resetGetAll: (state, action) => {
            state.getAll = null;
        },
        getAllUnitCategory: (state, action) => {
            state.getAll = 'loading';
        },
        fetchAllUnitCategory: (state, action) => {
            state.getAll = 'working';
        },
        getAllUnitCategorySuccess: (state, action) => {
            state.getAll = 'done';
            state.data = action.payload.content.data;
        },
        getAllUnitCategoryFailure: (state, action) => {
            state.getAll = 'error';
            state.data = null;
        }
    },
});

export const UnitCategoryActions = UnitCategorySlice.actions;
export default UnitCategorySlice;