import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useContext } from 'react';
import { $serializeForm, $TopologicalSort } from "../../main";
import { useDispatch, useSelector } from 'react-redux';
import { SET_MAIN_MENU, GET_MAIN_MENU } from "../../api/Api";
import Tree from "../../widgets/Tree";
import TreeSummary from "../../widgets/TreeSummary";
import { Loading } from "./../Loaders";
import { LoadingSvg, SaveSvg } from "../../icons/Icon";
import { apiRequestAsyncThunk } from '../../thunk/CommonThunk';


function flattenTreeIterative(tree) {
    const result = [];
    const stack = [...tree];

    while (stack.length > 0) {
        const node = stack.pop();

        result.push({
            id: node.id,
            parent: node.parent,
            value: node.value,
            order: node.order,
            new: node.new,
            edited: node.edited
        });

        if (node.nodes && node.nodes.length > 0) {
            for (let i = node.nodes.length - 1; i >= 0; i--) {
                stack.push(node.nodes[i]);
            }
        }
    }

    return result;
}

const Menu = (p) => {

    const tree = useRef(null);
    const [edited, setEdited] = useState();
    const [deleted, setDeleted] = useState();
    const [created, setCreated] = useState([]);
    const dispatch = useDispatch();
    const [menu, setMenu] = useState([]);

    const showMainMenu = (state, data) => {
        //console.log('Menu.showMainMenu', state, data?.data);
        switch (state) {
            case 'loading':
                setMenu(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                setMenu(<Tree
                    ref={tree}
                    json={data.data}
                    editUpdated={editUpdated}
                    deleteUpdated={deleteUpdated}
                    createUpdated={createUpdated} />);
                break;
            case 'error':
                setMenu(<button className='simple-form-button' onClick={loadMainMenu}>Load menu data</button>);
                break;
        }
    }
    const loadMainMenu = (e) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_MAIN_MENU(),
            call: showMainMenu,
            messages: {
                loading: 'Loading main menu',
                done: 'Menu loaded',
                error: 'Error loading menu'
            }
        }));
    }
    useEffect(() => {
        loadMainMenu();
    }, []);

    const getData = (e) => {
        var updates = tree.current.getUpdates();
        console.log(updates);
    };
    function editUpdated(e) {
        setEdited(e);
    }
    function deleteUpdated(e) {
        setDeleted(e);
    }
    function createUpdated(e) {
        setCreated(e);
    }
    const doSave = () => {
        var updated = tree.current.getUpdates().json;
        //console.log('dosave',updated);
        const flattened = flattenTreeIterative(updated);
        //console.log('dosave', flattened);
        var sorted = $TopologicalSort(flattened);
        //console.log('dosave', sorted);
        //dispatch(UnitSchemaDefinitionActions.setUnitSchemaDefinition(SET_UNIT_SCHEMA_DEFINITION(schema?.id, sorted)));
        dispatch(apiRequestAsyncThunk({
            request_details: SET_MAIN_MENU(sorted),
            call: loadMainMenu,
            messages: {
                loading: 'Saving menu',
                done: 'Menu saved',
                error: 'Error saving menu'
            }
        }));
    }
    return (
        <div className="page">
            <div className="flex-24">
                <div className="col-16 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span>Modify site menu</span></h4>
                        </div>
                        <div className='page-box-body'>
                            <div className="page-box-tree">{menu}</div>
                        </div>
                    </div>
                </div>
                <div className="col-8 flex-column">
                    <div className="page-box page-box-sticky">
                        <div className='page-box-header'>
                            <h4><span>Changes Summary</span> <button className='page-box-header-yellow' onClick={doSave}><SaveSvg /> save</button></h4>
                        </div>
                        <div className='page-box-body'>
                            <TreeSummary edited={edited} deleted={deleted} created={created} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;