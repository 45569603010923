import React, { useEffect, useRef, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GET_ALL_PRODUCTS, GET_ALL_VARIANTS, GET_VARIANT_BY_VARIANT_ID } from "../../api/Api";
import { apiRequestAsyncThunk } from "../../thunk/CommonThunk";
import BasicDataTable from "../../widgets/BasicDataTable";
import { LoadingSvg } from "../../icons/Icon";
import { Loading } from "../Loaders";
import { dateTimeFormatted } from "../../main";
import { Dialog, DialogOnlyClose } from "../modal/Dialog";
import { JsonToTable } from "../../widgets/JsonToTable";
import { PlaceholderContext } from "../../contexts/Contexts";
import { VariantView, VariantEditView } from "./VariantView";
import { ProductView } from "./ProductView";

const ListProducts = (p) => {

    const dispatch = useDispatch();
    const [products, setProducts] = useState(null);
    const [variants, setVariants] = useState([]);
    const dataTable = useRef(null);
    const placeholder = useContext(PlaceholderContext);


    const loadVariants = (e) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_ALL_VARIANTS,
            call: showVariants,
            messages: {
                loading: 'Loading variants',
                done: 'Variants loaded',
                error: 'Error loading variants'
            }
        }));
    }
    const showVariants = (state, data) => {
        //console.log('Menu.showMainMenu', state, data?.data);
        switch (state) {
            case 'loading':
                setVariants(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                let variants = data.data;
                variants = variants.map(product => ({
                    ...product,
                    create_date: dateTimeFormatted(product.create_date),
                    update_date: dateTimeFormatted(product.update_date)
                }));
                setVariants(<BasicDataTable
                    data={variants}
                    ref={dataTable}
                    searchClass={'search-table-search-flat'}
                    settings={{
                        show: ['name', 'skuName', 'skuCode', 'create_date'],
                        selectable: false,
                        actions: { view: showVariantDetails, edit: showVariantEditableDetails },
                        pageSize: 4
                    }}
                />);
                break;
            case 'error':
                setVariants(<button className='simple-form-button' onClick={loadVariants}>Load variants</button>);
                break;
        }
    }
    const showVariantDetails = (e) => {
        console.log('showVariantDetails', e);
        placeholder.openModal(<Dialog><VariantView variantId={e.id} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Variant Information',
            message: ''
        })
    }
    const showVariantEditableDetails = (e) => {
        console.log('showVariantDetails', e);
        //placeholder.openModal(<Dialog><VariantEditView variantId={e.id} /></Dialog>, {
        placeholder.openModal(<DialogOnlyClose><VariantEditView variantId={e.id} /></DialogOnlyClose>, {
            onApprove: (a) => {
                a.actions.close();
            },
            onSave: () => {
                console.log('onSave');
            }
        }, {
            heading: 'Variant Information',
            message: ''
        })
    }
    const showProductDetails = (e) => {
        //console.log('showProductDetails', e);
        placeholder.openModal(<Dialog><ProductView productId={e.id} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Product Information',
            message: ''
        })
    };
    const showProducts = (state, data) => {
        //console.log('ListProducts.showProducts', state, data);
        switch (state) {
            case 'loading':
                setProducts(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                let products = data.data;
                products = products.map(product => ({
                    ...product,
                    create_date: dateTimeFormatted(product.create_date),
                    update_date: dateTimeFormatted(product.update_date)
                }));
                //products = products.map(product => ({ ...product, link: `<a href="/product/${product.id}">${product.name}</a>` }));
                products = products.map(product => ({ ...product, link: <Link to={`http://home.trovazo.com/product/${product.id}`}>{product.name}</Link> }));
                setProducts(<BasicDataTable
                    data={products}
                    ref={dataTable}
                    searchClass={'search-table-search-flat'}
                    settings={{
                        show: ['name', 'brand', 'sku', 'create_date', 'update_date', 'link'],
                        selectable: false,
                        actions: { view: showProductDetails },
                        pageSize: 4
                    }}
                />);
                break;
            case 'error':
                setProducts(<button className='simple-form-button' onClick={loadAllProducts}>Load Products</button>);
                break;
        }
    }
    const loadAllProducts = (e) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_ALL_PRODUCTS(),
            call: showProducts,
            messages: {
                loading: 'Loading products',
                done: 'Products loaded',
                error: 'Error loading products',
            }
        }));
    }
    useEffect(() => {
        loadVariants();
        loadAllProducts();
    }, []);

    return (
        <div className="page">
            <div className="flex-24">
                <div className="col-24 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span>Products</span></h4>
                        </div>
                        <div className='page-box-body'>{products}</div>
                    </div>
                </div>
                <div className="col-24 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span>Variants</span></h4>
                        </div>
                        <div className='page-box-body'>{variants}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListProducts;