import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useContext } from 'react';
import { PlaceholderContext } from "../../contexts/Contexts";
import { $serializeForm } from "../../main";
import { Dialog } from "../modal/Dialog";
import { useDispatch, useSelector } from 'react-redux';
import BasicDataTable from "../../widgets/BasicDataTable";
import { UnitCategoryActions } from '../../redux/slices/UnitCategorySlice';
import { UnitCategoryOptionActions } from '../../redux/slices/UnitCategoryOptionSlice';
import { SET_UNIT_CATEGORY_OPTION, GET_ALL_UNIT_CATEGORY, GET_UNIT_CATEGORY_OPTION } from "../../api/Api";
import { Loading } from "../Loaders";
import { LoadingSvg, PlusSymbol, SaveSvg } from "../../icons/Icon";
import { NewUnit, DeleteUnit, EditUnit } from './Unit';
import CategoryFieldsForm from './Category';

const ManageCategories = (p) => {

    const tree = useRef(null);
    const dataTable = useRef(null);
    const placeholder = useContext(PlaceholderContext);
    const [category, setCategory] = useState(null);
    const dispatch = useDispatch();
    const allUnitCategory = useSelector(state => state.unitCategory.data);
    const getAll = useSelector(state => state.unitCategory.getAll);
    const unitCategoryOption = useSelector(state => state.unitCategoryOption.data);
    const [unitCategory, setUnitCategory] = useState();

    function loadAllUnitCategory(e) {
        e.preventDefault();
        dispatch(UnitCategoryActions.getAllUnitCategory(GET_ALL_UNIT_CATEGORY));
    }
    function getAllUnitCategoryState(create) {
        switch (create) {
            case 'loading':
            case 'working':
                setUnitCategory(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                dispatch(UnitCategoryActions.resetGetAll());
                setUnitCategory(<BasicDataTable
                    data={allUnitCategory}
                    ref={dataTable}
                    searchClass={'search-table-search-flat'}
                    settings={{ show: ['value'], selectable: false, actions: { edit: doEdit, delete: doDelete, view: doView, deselect: getSelected } }} />);
                break;
            case 'error':
                dispatch(UnitCategoryActions.resetGetAll());
                setUnitCategory(<button className='simple-form-button' onClick={loadAllUnitCategory}>Load Category</button>);
                break;
        }
    }
    useEffect(() => {
        getAllUnitCategoryState(getAll);
    }, [getAll]);

    useEffect(() => {
        dispatch(UnitCategoryActions.getAllUnitCategory(GET_ALL_UNIT_CATEGORY));
        return() => dispatch(UnitCategoryActions.resetState());
    }, []);
    useEffect(() => {
        console.log(category);
    }, [category]);
    
    const getData = (e) => {
        var updates = tree.current.getUpdates();
        console.log(updates);
    };
    const doEdit = (e) => {
        //console.log('doEdit',e);
        placeholder.openModal(<Dialog><EditUnit id={e.id} value={e.name} success={() => dispatch(UnitCategoryActions.getAllUnitCategory(GET_ALL_UNIT_CATEGORY))} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Edit Category',
            message: ''
        })
    };
    const doDelete = (e) => {
        //console.log('delete', e);
        placeholder.openModal(<Dialog><DeleteUnit id={e.id} value={e.value} success={() => dispatch(UnitCategoryActions.getAllUnitCategory(GET_ALL_UNIT_CATEGORY))} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Delete Category',
            message: ''
        })
    };
    const doView = (e) => {
        console.log('doView', e);
        setCategory(e);
        dispatch(UnitCategoryOptionActions.getUnitCategoryOption(GET_UNIT_CATEGORY_OPTION(e.id)));
    };
    const getSelected = (e) => {
        //let selected = dataTable?.current.getSelectedRows();
        //console.log(selected)
    }
    const doSave = (e) => {
        console.log('dosave',e);
        if(Array.isArray(e) && e.length > 0){
            dispatch(UnitCategoryOptionActions.setUnitCategoryOption(SET_UNIT_CATEGORY_OPTION(category.id, e)));
        }
        
    }
    const onCreateNewSchema = (e, id) => {
        placeholder.openModal(<Dialog><NewUnit success={() => dispatch(UnitCategoryActions.getAllUnitCategory(GET_ALL_UNIT_CATEGORY))} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Create new category',
            message: ''
        })
    };
    return (
        <div className="page">
            <div className="flex-24">
                <div className="col-16 flex-column">
                    <CategoryFieldsForm fields={unitCategoryOption} doSave={doSave} category={category} />
                </div>
                <div className="col-8 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span>Categories</span> <button className='page-box-header-green' onClick={onCreateNewSchema}><PlusSymbol /> new</button></h4>
                        </div>
                        <div className='page-box-body'>{unitCategory}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageCategories;