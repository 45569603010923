import React, { useState, useRef, useEffect } from "react";
import { DeleteSvg, PlusSymbol, SaveSvg } from "../../icons/Icon";

const CategoryFieldsForm = (p) => {

    const [fields, setFields] = useState((p.fields) ? JSON.parse(JSON.stringify(p.fields)) : []);
    const category = useRef();

    useEffect(() => {
        return() => setFields([]);
    },[]);
    useEffect(() => {
        setFields((p.fields) ? JSON.parse(JSON.stringify(p.fields)) : []);
    }, [p.fields]);
    const doSave = (e) => {
        e.preventDefault();
        p.doSave && p.doSave(fields);
    }
    const handleInputChange = (event, index) => {
        //console.log('handleInputChange',event.target.value, index);
        const { name, value } = event.target;
        const newFields = [...fields];
        newFields[index]['value'] = value;
        setFields(newFields);
    };

    const addField = (e) => {
        e.preventDefault();
        if(p.category){
            setFields([...fields, { id: null, value: "" }]);
        }
    };

    const removeField = (e, index) => {
        e.preventDefault();
        const newFields = fields.filter((_, i) => i !== index);
        setFields(newFields);
    };

    return (
        <div className="page-box">
            <form action="" ref={category} className="simple-form" method="POST" encType="multipart/form-data" autoComplete="on" target="_blank" noValidate>
                <div className='page-box-header'>
                    <h4><span>{ (fields && p.category) ? `${p.category.value} Fields` : 'Select category type'}</span>
                        <button className="page-box-header-green" onClick={addField}><PlusSymbol /> new</button>
                        <button className="page-box-header-yellow " onClick={doSave}><SaveSvg /> save</button>
                    </h4>
                </div>
                <div className='page-box-body'>
                    {fields && Array.isArray(fields) ? fields.map((field, index) => (
                        <div className="flex-24" key={index}>
                            <div className="col-22 flex-column">
                                {field.id && <input type="hidden" name="id" value={field.id} />}
                                <label title=""><input type="text" name="value" placeholder="" value={field.value} onChange={(event) => handleInputChange(event, index)} /></label>
                            </div>
                            <div className="col-2 flex-column flex-center"><button type="button" onClick={(e) => removeField(e, index)} className="meta-add " ><DeleteSvg /></button></div>
                        </div>
                    )) : 'please select a category'}
                </div>
            </form>
        </div>
    );
};
/*<pre>{JSON.stringify(fields, null, 2)}</pre> */
export default CategoryFieldsForm;