
export function dateTimeFormatted(string) {
    let date = new Date(string.replace(" ", "T"));
    let day = date.getDate();
    let month = dateTimeFormatted.months[date.getMonth()].substring(0, 3);
    let year = date.getFullYear().toString().substring(2, 4);
    let hours = date.getHours();

    let minutes = date.getMinutes().toString().padStart(2, '0');
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;

    return `${day}, ${month} ${year} ${hours}:${minutes}${ampm}`;
}
dateTimeFormatted.months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
export function columnNameToPretty(string) {
    return string.replaceAll(/\_/g, ' ').replaceAll(/([a-z])([A-Z])/g, '$1 $2').replaceAll(/\b\w/g, c => c.toUpperCase());
}
function $encodeURIComponent(s) {
    return encodeURIComponent(s).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
}
function $decodeURIComponent(s) {
    return decodeURIComponent(s).replace(/%21/g, '!').replace(/%27/g, '\'').replace(/%28/g, '(').replace(/%29/g, ')').replace(/%2A/g, '*');
}
function $getSelectedOptions(e, f, o, i, l, r) {
    if (e.type === 'select-one') {
        //console.log(e.options[0]);
        o = e.options[e.selectedIndex];
        //console.log(e.options[0]);
        //console.log(e.selectedIndex);
        //console.log(o);
        return (f === 'option') ? o : (o[f] || o.value || o.text);
    }
    if (e.type === 'select-multiple') {
        console.log(e);
        console.log(e.selectedOptions || e.options);
        if ((o = e.selectedOptions || e.options).length > 0) {
            for (i = 0, r = [], l = o.length; i < l; i++) {
                (o[i].selected) && r.push((f === 'option') ? o[i] : (o[i][f] || o[i].value || o[i].text));
            }
            return r;
        }
    }
}
export function printFormData(formData) {
    console.log('------------------------------');
    for (let [key, value] of formData.entries()) {
        if (value instanceof File) {
            console.log(`key: ${key}, filename: ${value.name}, size: ${value.size} bytes, type: ${value.type}`);
        } else {
            console.log(`key: ${key}, value: ${value}`);
        }
    }
    console.log('------------------------------');
}

export const apiSubmit = async (data) => {

    let response = null;
    console.log('apiSubmit.recieved', data);
    try {
        if(data.data instanceof FormData){
            console.log('apiSubmit.send', (data.data instanceof FormData) ? data.data : JSON.stringify(data.data));
            //printFormData(data.data);
        }

        response = await fetch(data.api, {
            method: data.method,
            headers: data.headers,
            body: (data.data instanceof FormData) ? data.data : JSON.stringify(data.data),
            //mode: 'no-cors'
        });
    } catch (error) {
        throw new Error(`Error ${error.name}: ${error.message}`);
    }

    if (!response.ok) {
        const error = await response.text();
        throw new Error(`Error ${response.status}: ${error}`);
    }

    const text = await response.text();
    const status = response.status;
    const headers = response.headers;

    try {
        var content = JSON.parse(text);
    } catch (error) {
        content = text;
    }
    //console.log('apiSubmit.recieve', status, content);
    return { content, status };
};

export function makeUrl(s) {
    return s.toLowerCase().replaceAll(makeUrl.gaps, '-');
}
makeUrl.gaps = /\s+/g;
export function trimStringGaps(s) {
    return s.replaceAll(makeUrl.gaps, ' ');
}
trimStringGaps.gaps = /\s+/g;

function $serializeForm(f) {
    let arrayName = new RegExp('\\s*(.+?)\\s*\\[\\s*\\]\\s*');
    var query = [];
    var json = {};

    function select(select) {
        var options = null;
        if (select.type === 'select-one') {
            options = select.options[select.selectedIndex];
            //console.log('select.select-one', select.name, options);
            addNameValue(select.name, options?.value || options?.text || '');
        }
        if (select.type === 'select-multiple') {
            if ((options = select.selectedOptions || select.options).length > 0) {
                for (var i = 0; i < options.length; i++) {
                    (options[i].selected) && addNameValue(select.name, options[i].value || options[i].text);
                }
            }
        }
    }
    function check(e) {
        if (e.checked) {
            addNameValue(e.name, e.value);
        }
    }
    function addNameValue(name, value) {
        var found = arrayName.exec(name);
        //console.log('addNameValue',name, name in json)
        if (found) {
            name = found[1];
            if (!(name in json)) {
                json[name] = [];
            }
        }
        //console.log('found', $encodeURIComponent(name) + '=' + $encodeURIComponent(value || ' '))
        query.push($encodeURIComponent(name) + '=' + $encodeURIComponent(value || ' '));
        if (Array.isArray(json[name])) {
            json[name].push(value);
        } else {
            json[name] = value || '';
        }
    }
    if (!f || f.nodeName !== "FORM") {
        return;
    }
    for (var i = 0; i < f.elements.length; i++) {
        if (f.elements[i].name === "" || f.elements[i].disabled) {
            continue;
        }
        switch (f.elements[i].nodeName) {
            case 'INPUT':
                switch (f.elements[i].type) {
                    case 'text':
                    case 'email':
                    case 'number':
                    case 'tel':
                    case 'date':
                    case 'datetime':
                    case 'datetime-local':
                    case 'month':
                    case 'week':
                    case 'time':
                    case 'url':
                    case 'hidden':
                    case 'password':
                    case 'button':
                    case 'reset':
                    case 'submit':
                    case 'range':
                        addNameValue(f.elements[i].name, f.elements[i].value);
                        break;
                    case 'checkbox':
                    case 'radio':
                        check(f.elements[i]);
                        break;
                    case 'file':
                        break;
                }
                break;
            case 'TEXTAREA':
                addNameValue(f.elements[i].name, f.elements[i].value);
                break;
            case 'SELECT':
                select(f.elements[i]);
                break;
            case 'BUTTON':
                switch (f.elements[i].type) {
                    case 'reset':
                    case 'submit':
                    case 'button':
                        addNameValue(f.elements[i].name, f.elements[i].value);
                        break;
                }
                break;
        }
    }
    return [query.join("&"), json];
}
function $TopologicalSort(arr) {
    const graph = new Map(); // To hold the graph
    const inDegree = new Map(); // To hold in-degrees of nodes
    const result = []; // This will hold the sorted order

    // Build the graph and in-degree map
    for (const item of arr) {
        graph.set(item.id, []); // Initialize adjacency list for each item
        inDegree.set(item.id, 0); // Initialize in-degree to 0
    }

    // Populate the graph and in-degrees
    for (const item of arr) {
        if (item.parent !== -1 && graph.has(item.parent)) { // Only consider valid parents
            graph.get(item.parent).push(item.id); // Add the edge
            inDegree.set(item.id, inDegree.get(item.id) + 1); // Increment in-degree
        }
    }

    // Collect nodes with no incoming edges (in-degree of 0)
    const queue = [];
    for (const [node, degree] of inDegree.entries()) {
        if (degree === 0) {
            queue.push(node); // Add to the queue if in-degree is 0
        }
    }

    // Process the nodes
    while (queue.length > 0) {
        const current = queue.shift(); // Dequeue an item
        result.push(arr.find(item => item.id === current)); // Find the item and add to result

        for (const neighbor of graph.get(current)) {
            // Decrease the in-degree for each child
            inDegree.set(neighbor, inDegree.get(neighbor) - 1);
            if (inDegree.get(neighbor) === 0) {
                queue.push(neighbor); // Enqueue if in-degree becomes 0
            }
        }
    }

    return result;
}
function $GroupLeafNodesUnderCommonPath(data) {
    if (!Array.isArray(data)) {
        return;
    }

    const resultMap = {};
    const stack = [];

    data.forEach(item => stack.push({ node: item, path: '' }));

    while (stack.length) {
        const { node, path } = stack.pop();
        if (node.nodes && node.nodes.length > 0) {
            node.nodes.forEach(child => {
                stack.push({ node: child, path: `${path ? path + ' > ' : ''}${node.value}` });
            });
        } else {
            if (path) {
                if (!resultMap[path]) {
                    resultMap[path] = [];
                }
                resultMap[path].push(node);
            } else {
                resultMap[node.id] = [];
                resultMap[node.id].push(node);
            }

        }
    }

    //return resultMap;
    return Object.keys(resultMap).map(path => ({ path, nodes: resultMap[path] }));
}
function $GroupArrayItemsToPairs(arr) {
    const result = [];

    for (let i = 0; i < arr.length; i += 2) {
        // If there's a next element, group the current and next element
        if (i + 1 < arr.length) {
            result.push([arr[i], arr[i + 1]]);
        } else {
            // If it's the last single element, add it as its own group
            result.push([arr[i]]);
        }
    }

    return result;
}
export { $serializeForm, $TopologicalSort, $GroupLeafNodesUnderCommonPath, $GroupArrayItemsToPairs };