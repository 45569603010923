import { takeEvery, call, put, all } from 'redux-saga/effects';
import { UnitGroupActions } from '../redux/slices/UnitGroupSlice';
import { apiSubmit } from '../main';

function* handleSetUnitGroup(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitGroupActions.setUnitGroupSuccess(response));
  } catch (e) {
    yield put(UnitGroupActions.setUnitGroupFailure(e.message));
  }finally{
  }
}

export function* watchSetUnitGroup() {
  yield takeEvery(UnitGroupActions.setUnitGroup, handleSetUnitGroup);
}

function* handleGetUnitGroup(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitGroupActions.getUnitGroupSuccess(response));
  } catch (e) {
    yield put(UnitGroupActions.getUnitGroupFailure(e.message));
  }finally{

  }
}
export function* watchGetUnitGroup() {
  yield takeEvery(UnitGroupActions.getUnitGroup, handleGetUnitGroup);
}


function* handleGetAllUnitGroup(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitGroupActions.getAllUnitGroupSuccess(response));
  } catch (e) {
    yield put(UnitGroupActions.getAllUnitGroupFailure(e.message));
  }finally{

  }
}
export function* watchGetAllUnitGroup() {
  yield takeEvery(UnitGroupActions.getAllUnitGroup, handleGetAllUnitGroup);
}

function* handleDeleteUnitGroup(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitGroupActions.deleteUnitGroupSuccess(response));
  } catch (e) {
    yield put(UnitGroupActions.deleteUnitGroupFailure(e.message));
  }finally{

  }
}
export function* watchDeleteUnitGroup() {
  yield takeEvery(UnitGroupActions.deleteUnitGroup, handleDeleteUnitGroup);
}

function* handleUpdateUnitGroup(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitGroupActions.putUnitGroupSuccess(response));
  } catch (e) {
    yield put(UnitGroupActions.putUnitGroupFailure(e.message));
  }finally{

  }
}
export function* watchUpdateUnitGroup() {
  yield takeEvery(UnitGroupActions.putUnitGroup, handleUpdateUnitGroup);
}
