import React, { useState, useMemo, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react';
import { columnNameToPretty } from '../main';
import { EditRow, DeleteSvg, ViewSvg, AddCircleSvg } from '../icons/Icon';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';

const BasicDataTable = forwardRef((p, ref) => {

  const [searchQuery, setSearchQuery] = useState('');
  const [settings, setSettings] = useState(p.settings || { show: [], selectable: false })
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowData, setRowData] = useState(null);

  const handleSaveClick = () => {
    if (settings.actions && settings.actions.save) {
      settings.actions.save(rowData);
    }
    setSelectedRow(null);
  };
  const handleAction = (action, row) => {
    setSelectedRow(row);
    switch (action) {
      case 'EDIT':
        settings.actions.edit(row.original);
        break;
      case 'DELETE':
        settings.actions.delete(row.original);
        break;
      case 'VIEW':
        settings.actions.view(row.original);
        break;
      case 'ADD':
        settings.actions.add(row.original);
        break;
    }
  };
  const handleChange = (e, key) => {
    setRowData({
      ...rowData,
      [key]: e.target.value,
    });
  };
  const handleEditClick = (row) => {
    setSelectedRow(row.id);
    setRowData({ ...row.original });
  };
  useImperativeHandle(ref, () => ({
    getSelectedRows: () => table.getSelectedRowModel().rows.map(row => row.original),
    deselect: () => setSelectedRow(null)
  }));
  const filtered = useMemo(() => {

    if (!searchQuery) return p.data;

    const lowercasedQuery = searchQuery.toLowerCase();

    return p.data.filter(row =>
      Object.values(row).some(value =>
        String(value).toLowerCase().includes(lowercasedQuery)
      )
    );

  }, [searchQuery, p.data]);

  const columns = useMemo(() => {
    //console.log('Object.keys',p.data, p.data.length < 1);
    if (!Array.isArray(p.data) || p.data.length === 0) return [];

    const keys = Object.keys(p.data[0]);

    const selectable = (settings.selectable === true) ? ({
      id: 'selection',
      header: ({ table }) => (
        <div className="search-table-check-cell">
          <input
            type="checkbox"
            {...{
              checked: table.getIsAllPageRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
            }}
            onClick={() => { }}

          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="search-table-check-cell">
          <input
            type="checkbox"
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={() => { }}
          />
        </div>
      ),
    }) : null;

    const actions = (settings.actions) ? ({
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <div className='table-actions'>
          {(selectedRow === row.id) ? (
            <>
              <button onClick={handleSaveClick}><img src="/icons/save.svg" alt="" /><span>Save</span></button>
              <button onClick={() => setSelectedRow(null)}><img src="/icons/cancel.svg" alt="" /><span>Cancel</span></button>
            </>
          ) : (
            <>
              {(settings.actions.add) ? (<button onClick={() => handleAction('ADD', row)}><AddCircleSvg/><span>Add</span></button>) : null}
              {(settings.actions.edit) ? (<button onClick={() => handleAction('EDIT', row)}><EditRow/><span>Edit</span></button>) : null}
              {(settings.actions.delete) ? (<button onClick={() => handleAction('DELETE', row)}><DeleteSvg /><span>Delete</span></button>) : null}
              {(settings.actions.view) ? (<button onClick={() => handleAction('VIEW', row)}><ViewSvg /><span>View</span></button>) : null}
            </>
          )}
        </div>
      ),
    }) : null;

    const columns = keys
      .filter(key => (settings.show ? settings.show.includes(key) : true))
      .map(key => ({
        //header: key.charAt(0).toUpperCase() + key.slice(1),
        header: columnNameToPretty(key),
        accessorKey: key,
        cell: info => typeof info.getValue() === 'object' && React.isValidElement(info.getValue())
          ? info.getValue()
          : String(info.getValue() || ''),
      }));


    let _columns = [
      ...keys
        .filter(key => (settings.show) ? settings.show.includes(key) : true)
        .map(key => ({
          header: key.charAt(0).toUpperCase() + key.slice(1),
          accessorKey: key,
        }))
    ];

    selectable && columns.unshift(selectable);
    actions && columns.push(actions);

    return columns;
  }, [p.data, settings, selectedRow]);

  const table = useReactTable({
    data: filtered,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    enableRowSelection: true,
    initialState: {
      pagination: {
        pageSize: p?.settings?.pageSize || 5,
        pageIndex: 0,
      },
    },
  });

  return (
    <>
      <div className='search-table-pagination'>
        <input type="text" className={p.searchClass} placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ marginBottom: '10px', padding: '5px' }} />
      </div>
      <table className="search-table">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <span>{{ asc: '↑', desc: '↓' }[header.column.getIsSorted()] ?? null}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className={`show-table-actions ${selectedRow && (selectedRow.id === row.id) ? 'search-table-selected-tr' : ''}`}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {selectedRow === row.id && rowData.hasOwnProperty(cell.column.id) ? (
                    <input
                      type="text"
                      value={rowData[cell.column.id]}
                      onChange={(e) => handleChange(e, cell.column.id)}
                    />
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className='search-table-pagination search-table-pagination-green'>
        <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>{String.fromCharCode('8676')}</button>
        <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>{String.fromCharCode('9666')}</button>
        <span>Page{' '}<strong>{table.getState().pagination.pageIndex + 1} of {table.getPageCount()}</strong></span>
        <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>{String.fromCharCode('9656')}</button>
        <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>{String.fromCharCode('8677')}</button>
        {/**<button onClick={() => { }}>Selected Rows</button>  ⇥ 171 187*/}
      </div>
    </>
  );
});

export default BasicDataTable;