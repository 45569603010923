import { useEffect, useRef, useState, useContext } from "react";
import Select from 'react-select';

function inSelected(option, selected) {
    for (var index in selected) {
        if (selected[index].label == option.label) {
            return true;
        }
    }
}
function setSelectedElement(select, options, selected) {
    if(!options || !selected) {
        return;
    }
    if (Array.isArray(selected)) {
        for (var index in options) {
            //console.log('selected.Array', options[index].label, inSelected(options[index], selected) ? true : false)
            if(select.options[index]){
                select.options[index].selected = inSelected(options[index], selected) ? true : false;
            }
        }
    } else {
        for (var index in options) {
            if (options[index].label == selected.label) {
                select.selectedIndex = index;
                console.log('selected.NotArray',select,  options[index].label, selected.label, index, select.selectedIndex);
            }
        }
    }
}
const SelectHtml = (p) => {

    const select = useRef(null);
    const onChange = (e) => {

    };
    useEffect(() => {
        //console.log('SelectHtml.selected', p.selected, p.options)
        //var selected = getSelected(p.selected);
        setSelectedElement(select.current, p.options, p.selected);
    }, [p.selected]);

    return (
        <select
            name={p.name || ''}
            ref={select}
            style={p.style || {}}
            id={`id-${p.name}`}
            multiple={p.multiple}
            onChange={onChange}
        >
            {/*<option value="">Select</option>*/}
            {Array.isArray(p?.options) && p.options.map((option) => (
                <option key={option.value} value={option.value} >
                    {option.label}
                </option>
            ))}
        </select>
    );
};
function SelectOptionsSection(p) {

    const [selected, setSelected] = useState(p.selected);

    useEffect(() => {
        //console.log('SelectOptionsSection.selected',p.selected)
        setSelected(p.selected);
    }, [p.selected]);
    useEffect(() => {
        //console.log('SelectOptionsSection.selected',selected)
        p.onChange && p.onChange(selected);
    }, [selected]);
    return (
        <div>
            <Select
                isMulti={p.multiple}
                options={p.options}
                defaultValue={selected}
                onChange={setSelected}
                className="simple-form-input"
            />
            <SelectHtml
                name={p.name || ''}
                options={p.options}
                selected={selected}
                multiple={p.multiple}
                style={{ display: 'none' }} />
        </div>

    );
}

export { SelectOptionsSection, SelectHtml };