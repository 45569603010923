import './reset.css';
import './App.css';
import './css.css';
import AppRoutes from './routes/AppRoutes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'INITIALIZE' });
  }, [dispatch]);

  return (<AppRoutes />);
}

export default App;
