import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { UploadSvg, DeleteSvg } from '../../icons/Icon';
import { IMAGE_HOST } from '../../api/Api';

function bytesToMegabytes(bytes) {
    return (bytes === 0) ? 0 : (bytes / (1024 * 1024)).toFixed(4);
}
function truncateString(s, c){
    return s.length > c ? s.substring(0, c) + '...' : s;
}
const ImageHolder = forwardRef((p, r) => {

    const fileInput = useRef(null);
    const [images, setImages] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);

    useImperativeHandle(r, () => ({
        getImages: () => images
    }));
    useEffect(() => {
        //console.log(p.images);
        //let images = Array.isArray(p.images) && p.images.sort((a, b) => a.order - b.order);
        console.log('ImageHolder',p.data );
        let images = p.data;
        if(p.data){
            addImage(p.data);
        }
        //Array.isArray(images) && images.forEach(file => {});
    }, [p.data]);
    const onFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('onFile', e);
        fileInput.current.click();
    };
    const startEditing = (index) => {
        setEditingIndex(index);
    }
    const saveName = (e, index) => {
        if (e.key === 'Enter' || e.type === 'blur') {
            const newName = e.target.value;
            setImages(current => current.map((file, i) =>
                i === index ? { ...file, newName: newName } : file
            ));
            setEditingIndex(null);
        }
    }
    const addImage = (file) => {
        const image = new Image();
        const src = IMAGE_HOST + (file.url || '');
        image.src = src;
        const name = file.name || (file.url && new URL(file.url, IMAGE_HOST).pathname.split('/').pop())
        console.log('addimage', file.path, file.url, name);
        console.log(`addimage', ${file}, ${src}, ${name}, ${file.name}`);
        const data = {
            id: file.id,
            name: name,
            newName: file.newName || file.name || '',
            image: image,
            file: null,
            size: (file.size != null) ? bytesToMegabytes(file.size) + ' MB' : '',
            order: file.order || 0,
            selected: false
        };

        image.onload = async function () {
            setImages(current => ([...current, data]));
        };
    };
    const load = (files) => {
        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    const data = {
                        name: file.name,
                        newName: file.newName || file.name,
                        image: image,
                        file: file,
                        size: bytesToMegabytes(file.size) + ' MB',
                        order: images?.length || 0,
                        selected: false
                    };
                    setImages(current => ([...current, data]));
                };
            };
            reader.readAsDataURL(file);
        });
    };
    const file = (e) => {
        //console.log(e.target.files);
        //console.log(e.target.files[0]);
        load(Array.from(e.target.files));
    };
    const remove = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let updated = [...images];
        updated.splice(index, 1);
        updated.forEach((file, index) => {
            file.order = index;
        })
        //console.log('remove', updated);
        setImages(updated);
    }
    return (
        <div className='image-holder'>
            <input ref={fileInput} onChange={file} id="image-uploader" style={{ display: "none" }} type="file" name="file" />
            <ul>
                {Array.isArray(images) && images.map((image, index) => {
                    return (
                        <li key={`${image.name}-${index}`}>
                            <figure><img src={image.image.currentSrc} /></figure>
                            <div>
                                {editingIndex === index ? (
                                    <input
                                        type="text"
                                        defaultValue={image.newName || image.name}
                                        onBlur={(e) => saveName(e, index)}
                                        onKeyDown={(e) => saveName(e, index)}
                                        autoFocus
                                    />
                                ) : (
                                    <em onDoubleClick={() => startEditing(index)}>{truncateString(image.newName || image.name, 10)}</em>
                                )}
                                <span>{image.size}</span>
                            </div>
                            <section>
                                <button onClick={(e) => remove(e, index)}><span>x</span></button>
                            </section>
                        </li>
                    );
                })}
            </ul>
            <button onClick={onFile}><UploadSvg /><span>Upload</span></button>
        </div>
    );
});

export default ImageHolder;