import React, { useRef, useState, useImperativeHandle, forwardRef } from "react";
import { $serializeForm } from "../../main";
import FileUpload from "../../widgets/FileUpload";
import { randomVariantFor, generateRandomColorName } from "../../widgets/random";
import { faker } from '@faker-js/faker';

const randomNum = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
function randomFor(key, seed) {

    switch (key) {
        case 'name':
            //console.log(key, seed)
            return randomVariantFor(seed);
        case 'skuCode':
            return `${seed.replace(/\s+/g, '').toUpperCase()}-${faker.number.int({ min: 1000, max: 9999 })}`;
        case 'URL':
            return seed.replaceAll(/\s+/g, '-').toLowerCase();
        case 'ram':
            return `${randomNum(1, 16)}-GB`;
        case 'color':
            return generateRandomColorName();
        default:
            return randomNum(0, 1000);
    }
}
const Variant = forwardRef((p, r) => {
    const [showPopup, setShowPopup] = useState(false);
    const uploader = useRef(null);
    const form = useRef(null);
    let itemsPerRow = p.itemsPerRow || 4;
    let noOfRows = Math.trunc(p.variants.length / itemsPerRow) +
        (Math.trunc(p.variants.length % itemsPerRow) > 0 ? 1 : 0);

    useImperativeHandle(r, () => ({
        togglePopup: () => {
            setShowPopup(!showPopup);
        },
        getVariant: () => {
            var [encoded, json] = $serializeForm(form.current);
            let files = uploader.current.getUploadedFiles();
            const formData = new FormData();
            files.forEach((file, index) => {
                //console.log(file.file);
                formData.append(`images[${index}]`, file.file);
                formData.append(`data[${index}][name]`, file.name);
                formData.append(`data[${index}][newName]`, file.newName);
                formData.append(`data[${index}][size]`, file.size);
                formData.append(`data[${index}][order]`, file.order);
            });
            //for (let pair of formData.entries()) {console.log(pair[0] + ": " + pair[1]);}

            return {
                form: json,
                formData: formData,
                //images: uploader.current.getUploadedFiles()
            };
        }
    }));

    if (p.variants && p.variants.length == 0) return null;

    function formChanged(e) {
        e.preventDefault();
    }

    const render = (variants) => {
        let result = [];
        for (let row = 0, col = 0; row < noOfRows; row++) {
            let colItems = [];
            //console.log('from', row * itemsPerRow, 'to', ((row * itemsPerRow) + itemsPerRow));
            for (col = row * itemsPerRow; col < ((row * itemsPerRow) + itemsPerRow); col++) {
                //console.log('col', col, 'to', variants[col]?.value);
                if (variants[col]) {
                    colItems.push(<div key={`${p.keyid}-${col}`} className={`col-${Math.trunc(24 / itemsPerRow)} gap-1rem-right`}>
                        <label title=""><span>{window.camelToTitleCase(variants[col].value)}</span>
                            <input type="text" name={variants[col].value} placeholder={window.camelToTitleCase(variants[col].value)} defaultValue={randomFor(variants[col].value, p.name || '')} />
                        </label>
                    </div>);
                }
            }
            result.push(<div className="flex-row" key={`${p.keyid}-${row}`}>{colItems}</div>);
        }

        return (
            <form key={`form-${p.keyid}-${p.index}`} action="" ref={form} name={`variant${p.index}`} onChange={formChanged} style={{ position: 'relative' }} className="simple-form" autoComplete="on" target="_blank" noValidate>
                <div>{result}</div>
                <div style={showPopup ? { display: 'flex' } : { display: 'none' }} className="file-upload-container file-upload-popup">
                    <FileUpload ref={uploader} />
                </div>
            </form>);
    };

    return render(p.variants);
});

export default Variant;