import { combineReducers, bindActionCreators } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import ServicesSlice from './slices/ServicesSlice';
import UnitSchemaSlice from './slices/UnitSchemaSlice';
import UnitSchemaDefinitionSlice from './slices/UnitSchemaDefinitionSlice';
import UnitVariantDefinitionSlice from './slices/UnitVariantDefinitionSlice';
import UnitCategoryOptionSlice from './slices/UnitCategoryOptionSlice';
import UnitVariantSlice from './slices/UnitVariantSlice';
import UnitCategorySlice from './slices/UnitCategorySlice';
import UnitGroupSlice from './slices/UnitGroupSlice';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga/RootSaga';

const initialState = {
    moduleMenu: null,
};
export const rootReducer = combineReducers({
    services: ServicesSlice.reducer,
    unitSchema: UnitSchemaSlice.reducer,
    unitSchemaDefinition: UnitSchemaDefinitionSlice.reducer,
    unitVariantDefinition: UnitVariantDefinitionSlice.reducer,
    unitVariant: UnitVariantSlice.reducer,
    unitCategory: UnitCategorySlice.reducer,
    unitGroup: UnitGroupSlice.reducer,
    unitCategoryOption: UnitCategoryOptionSlice.reducer,
});
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware).concat(thunk),
});

sagaMiddleware.run(rootSaga);

export default store;