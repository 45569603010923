import { takeEvery, call, put, all } from 'redux-saga/effects';
import { UnitCategoryOptionActions } from '../redux/slices/UnitCategoryOptionSlice';
import { apiSubmit } from '../main';

function* handleSetUnitCategoryOption(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitCategoryOptionActions.setUnitCategoryOptionSuccess(response));
  } catch (e) {
    yield put(UnitCategoryOptionActions.setUnitCategoryOptionFailure(e.message));
  }finally{
  }
}

export function* watchSetUnitCategoryOption() {
  yield takeEvery(UnitCategoryOptionActions.setUnitCategoryOption, handleSetUnitCategoryOption);
}

function* handleGetUnitCategoryOption(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitCategoryOptionActions.getUnitCategoryOptionSuccess(response));
  } catch (e) {
    yield put(UnitCategoryOptionActions.getUnitCategoryOptionFailure(e.message));
  }finally{

  }
}
export function* watchGetUnitCategoryOption() {
  yield takeEvery(UnitCategoryOptionActions.getUnitCategoryOption, handleGetUnitCategoryOption);
}

/*
function* handleGetUnitCategoryOption(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitCategoryOptionActions.getUnitCategoryOptionSuccess(response));
  } catch (e) {
    yield put(UnitCategoryOptionActions.getUnitCategoryOptionFailure(e.message));
  }finally{

  }
}
export function* watchGetUnitCategoryOption() {
  yield takeEvery(UnitCategoryOptionActions.getUnitCategoryOption, handleGetUnitCategoryOption);
}
*/
function* handleDeleteUnitCategoryOption(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitCategoryOptionActions.deleteUnitCategoryOptionSuccess(response));
  } catch (e) {
    yield put(UnitCategoryOptionActions.deleteUnitCategoryOptionFailure(e.message));
  }finally{

  }
}
export function* watchDeleteUnitCategoryOption() {
  yield takeEvery(UnitCategoryOptionActions.deleteUnitCategoryOption, handleDeleteUnitCategoryOption);
}

function* handleUpdateUnitCategoryOption(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(UnitCategoryOptionActions.putUnitCategoryOptionSuccess(response));
  } catch (e) {
    yield put(UnitCategoryOptionActions.putUnitCategoryOptionFailure(e.message));
  }finally{

  }
}
export function* watchUpdateUnitCategoryOption() {
  yield takeEvery(UnitCategoryOptionActions.putUnitCategoryOption, handleUpdateUnitCategoryOption);
}
