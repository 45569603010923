import { faker } from '@faker-js/faker';

const phoneModels = [
    // Apple
    "iPhone 4", "iPhone 4S", "iPhone 5", "iPhone 5C", "iPhone 5S",
    "iPhone 6", "iPhone 6 Plus", "iPhone 6S", "iPhone 6S Plus",
    "iPhone 7", "iPhone 7 Plus", "iPhone 8", "iPhone 8 Plus",
    "iPhone X", "iPhone XR", "iPhone XS", "iPhone XS Max",
    "iPhone 11", "iPhone 11 Pro", "iPhone 11 Pro Max",
    "iPhone 12", "iPhone 12 Mini", "iPhone 12 Pro", "iPhone 12 Pro Max",
    "iPhone 13", "iPhone 13 Mini", "iPhone 13 Pro", "iPhone 13 Pro Max",
    "iPhone 14", "iPhone 14 Plus", "iPhone 14 Pro", "iPhone 14 Pro Max",
    "iPhone SE", "iPhone SE (2nd generation)", "iPhone SE (3rd generation)",

    // Samsung
    "Samsung Galaxy S3", "Samsung Galaxy S4", "Samsung Galaxy S5",
    "Samsung Galaxy S6", "Samsung Galaxy S6 Edge", "Samsung Galaxy S7",
    "Samsung Galaxy S7 Edge", "Samsung Galaxy S8", "Samsung Galaxy S8+",
    "Samsung Galaxy S9", "Samsung Galaxy S9+", "Samsung Galaxy S10",
    "Samsung Galaxy S10+", "Samsung Galaxy S10e", "Samsung Galaxy S20",
    "Samsung Galaxy S20+", "Samsung Galaxy S20 Ultra", "Samsung Galaxy S21",
    "Samsung Galaxy S21+", "Samsung Galaxy S21 Ultra", "Samsung Galaxy Note 8",
    "Samsung Galaxy Note 9", "Samsung Galaxy Note 10", "Samsung Galaxy Note 10+",
    "Samsung Galaxy Note 20", "Samsung Galaxy Note 20 Ultra",
    "Samsung Galaxy Z Fold", "Samsung Galaxy Z Fold2", "Samsung Galaxy Z Fold3",
    "Samsung Galaxy Z Flip", "Samsung Galaxy Z Flip3", "Samsung Galaxy Z Flip4",
    "Samsung Galaxy A3", "Samsung Galaxy A5", "Samsung Galaxy A7", "Samsung Galaxy A8",
    "Samsung Galaxy A9", "Samsung Galaxy A10", "Samsung Galaxy A11", "Samsung Galaxy A12",
    "Samsung Galaxy A32", "Samsung Galaxy A52", "Samsung Galaxy A72",

    // Google
    "Google Pixel", "Google Pixel XL", "Google Pixel 2", "Google Pixel 2 XL",
    "Google Pixel 3", "Google Pixel 3 XL", "Google Pixel 3a", "Google Pixel 3a XL",
    "Google Pixel 4", "Google Pixel 4 XL", "Google Pixel 4a", "Google Pixel 4a 5G",
    "Google Pixel 5", "Google Pixel 5a", "Google Pixel 6", "Google Pixel 6 Pro",
    "Google Pixel 6a", "Google Pixel 7", "Google Pixel 7 Pro", "Google Pixel 7a",

    // OnePlus
    "OnePlus One", "OnePlus 2", "OnePlus X", "OnePlus 3", "OnePlus 3T",
    "OnePlus 5", "OnePlus 5T", "OnePlus 6", "OnePlus 6T", "OnePlus 7",
    "OnePlus 7 Pro", "OnePlus 7T", "OnePlus 7T Pro", "OnePlus 8",
    "OnePlus 8 Pro", "OnePlus 8T", "OnePlus 9", "OnePlus 9 Pro",
    "OnePlus 9R", "OnePlus 10 Pro", "OnePlus Nord", "OnePlus Nord N10 5G",
    "OnePlus Nord N100", "OnePlus Nord 2", "OnePlus Nord CE", "OnePlus 11",

    // Xiaomi
    "Xiaomi Mi 1", "Xiaomi Mi 2", "Xiaomi Mi 3", "Xiaomi Mi 4",
    "Xiaomi Mi 5", "Xiaomi Mi 6", "Xiaomi Mi 8", "Xiaomi Mi 9",
    "Xiaomi Mi 10", "Xiaomi Mi 11", "Xiaomi Mi 11 Ultra",
    "Xiaomi Redmi Note 7", "Xiaomi Redmi Note 8", "Xiaomi Redmi Note 9",
    "Xiaomi Redmi Note 10", "Xiaomi Redmi Note 11", "Xiaomi Redmi K20",
    "Xiaomi Redmi K30", "Xiaomi Redmi K40", "Xiaomi Redmi K50",
    "Xiaomi Black Shark 2", "Xiaomi Black Shark 3", "Xiaomi Black Shark 4",
    "Xiaomi Poco F1", "Xiaomi Poco X2", "Xiaomi Poco X3", "Xiaomi Poco F2 Pro",

    // Sony
    "Sony Xperia Z", "Sony Xperia Z1", "Sony Xperia Z2", "Sony Xperia Z3",
    "Sony Xperia Z5", "Sony Xperia X", "Sony Xperia XZ", "Sony Xperia XZ1",
    "Sony Xperia XZ2", "Sony Xperia XZ3", "Sony Xperia 1", "Sony Xperia 1 II",
    "Sony Xperia 1 III", "Sony Xperia 5", "Sony Xperia 5 II", "Sony Xperia 5 III",
    "Sony Xperia L1", "Sony Xperia L2", "Sony Xperia XA", "Sony Xperia XA1",
    "Sony Xperia XA2", "Sony Xperia XA3", "Sony Xperia 10", "Sony Xperia 10 II",
    "Sony Xperia 10 III",

    // Huawei
    "Huawei P10", "Huawei P20", "Huawei P20 Pro", "Huawei P30",
    "Huawei P30 Pro", "Huawei P40", "Huawei P40 Pro", "Huawei P50",
    "Huawei P50 Pro", "Huawei Mate 10", "Huawei Mate 20", "Huawei Mate 30",
    "Huawei Mate 30 Pro", "Huawei Mate 40", "Huawei Mate 40 Pro",
    "Huawei Nova 3", "Huawei Nova 5", "Huawei Nova 6", "Huawei Nova 7",
    "Huawei Honor 6", "Huawei Honor 7", "Huawei Honor 8", "Huawei Honor 9",
    "Huawei Honor 10", "Huawei Honor 20", "Huawei Honor 30", "Huawei Honor 50",

    // Nokia
    "Nokia 6", "Nokia 7 Plus", "Nokia 8", "Nokia 8 Sirocco", "Nokia 9 PureView",
    "Nokia 1", "Nokia 2", "Nokia 3", "Nokia 5", "Nokia 7.1", "Nokia 9",
    "Nokia X6", "Nokia X7", "Nokia X10", "Nokia X20", "Nokia G10", "Nokia G20",
    "Nokia C1", "Nokia C2", "Nokia C3", "Nokia C5",

    // Motorola
    "Motorola Moto G", "Motorola Moto G4", "Motorola Moto G5",
    "Motorola Moto G6", "Motorola Moto G7", "Motorola Moto G8",
    "Motorola Moto G Power", "Motorola Moto G Stylus", "Motorola Moto X",
    "Motorola Moto X4", "Motorola Moto Z", "Motorola Moto Z2 Force",
    "Motorola Edge", "Motorola Edge 20", "Motorola Edge 30", "Motorola Razr 2019",
    "Motorola Razr 5G", "Motorola One", "Motorola One Vision", "Motorola One Action",

    // Asus
    "Asus Zenfone 5", "Asus Zenfone 6", "Asus Zenfone 7", "Asus Zenfone 8",
    "Asus ROG Phone", "Asus ROG Phone 2", "Asus ROG Phone 3", "Asus ROG Phone 5",
    "Asus Zenfone Max", "Asus Zenfone 3", "Asus Zenfone 4",

    // Oppo
    "Oppo F9", "Oppo F11", "Oppo F15", "Oppo Reno", "Oppo Reno2",
    "Oppo Reno3 Pro", "Oppo Reno4 Pro", "Oppo Reno5 Pro", "Oppo Reno6 Pro",
    "Oppo A37", "Oppo A53", "Oppo A54", "Oppo A72", "Oppo A92", "Oppo A93",
    "Oppo Find X", "Oppo Find X2", "Oppo Find X3 Pro",

    // Vivo
    "Vivo V9", "Vivo V11", "Vivo V15 Pro", "Vivo V17 Pro", "Vivo V19",
    "Vivo V20", "Vivo V21", "Vivo V23", "Vivo X21", "Vivo X50", "Vivo X60 Pro",
    "Vivo X70 Pro", "Vivo X90 Pro", "Vivo V5", "Vivo V5 Plus",

    // LG
    "LG G5", "LG G6", "LG G7 ThinQ", "LG G8 ThinQ", "LG V30",
    "LG V40 ThinQ", "LG V50 ThinQ", "LG Velvet", "LG Wing", "LG Stylo 5",

    // Realme
    "Realme 1", "Realme 2", "Realme 2 Pro", "Realme 3", "Realme 3 Pro",
    "Realme 5", "Realme 5 Pro", "Realme 6", "Realme 6 Pro", "Realme 7", "Realme 7 Pro",
    "Realme 8", "Realme 8 Pro", "Realme 9", "Realme X", "Realme X2", "Realme X3",
    "Realme Narzo", "Realme Narzo 30", "Realme GT", "Realme GT 5G"
];
const phoneVariants = [
    "Ultra", "Pro", "Max", "Plus", "Mini", "Lite", "Neo", "Active", "Prime",
    "Elite", "X", "S", "Z", "V", "R", "F", "G", "Xtreme", "Edition",
    "Super", "Turbo", "2", "3", "4", "5", "6", "7", "8", "9",
    "Edge", "Infinity", "Speed", "Titan", "Apex", "Power", "Fusion",
    "Snap", "View", "Razor", "Focus", "Impact", "Zoom", "Sprint",
    "Max Pro", "Max Plus", "Z Pro", "Infinity Pro", "X2", "Z Max",
    "Infinity Max", "5G", "6G", "4G", "Super Pro", "Dual", "Pro Max",
    "Alloy", "Fusion Plus", "Stream", "Vibe", "Sky", "Ace", "Star",
    "Vision", "Active X", "Prime Plus", "Quantum", "Pulse", "Xtreme Pro",
    "Force", "X Prime", "Super Max", "2X", "Alpha", "Galaxy", "Galaxy Pro",
    "Infinity Ultra", "Ultra Max", "Snap Pro", "Plus Pro", "Zoom Pro",
    "Express", "Speed Pro", "Edge Max", "Max Edge", "Next", "Rapid",
    "Elite Pro", "Pro X", "Speed Max", "Stream Pro", "Super Edge",
    "Vibe Plus", "Super View", "Boost", "Stealth", "Stealth Pro",
    "Titan Pro", "Zoom X", "Pinnacle", "Velocity", "Super Zoom",
    "Cyber", "Cyber Pro", "Flash", "Zoom Plus", "Vision Pro", "Vision X",
    "Stream Max", "Eclipse", "Quantum Pro", "Quantum X", "Vibe Max",
    "Star Pro", "Prime Max", "Vibe X", "X Pro Max", "Evo", "Evo Max",
    "Vision Max", "Power Max", "Infinity X", "Focus Pro", "Titan X",
    "Epic", "Elite X", "Nova", "Nova Plus", "Sprint Pro", "Snap Max",
    "Fusion X", "Razor Pro", "Zoom Elite", "Max Edge Pro", "Prime Elite",
    "Power Elite", "Galaxy Max", "Speed X", "Pro Elite", "Stealth X",
    "Edge Elite", "Turbo Plus", "Fusion Turbo", "Pro Xtreme", "Zoom Edge",
    "Velocity Pro", "Eclipse Pro", "Fusion Max", "Vibe Turbo", "Ace Pro",
    "Vibe Elite", "Pro Turbo", "Snap Plus", "Snap Elite", "Galaxy Elite",
    "Max Turbo", "Ultra Turbo", "Pro Velocity", "Zoom Turbo", "Zoom Xtreme",
    "Prime Turbo", "Xtreme Edge", "Turbo Pro Max", "Ultra Pro Max",
    "Super Elite", "Pro Vision", "Vibe Xtreme", "Snap Ultra", "Turbo Edge",
    "Power Turbo", "Super Snap", "X2 Pro", "Prime Vision", "Zoom Vision",
    "Elite Vision", "Zoom Infinity", "Max Pro Turbo", "Fusion Vision",
    "Elite Fusion", "Focus Max", "Pro Turbo Vision", "Prime Zoom",
    "Stealth Elite", "Pro Fusion", "Max Fusion", "Zoom Pro Elite",
    "Turbo Vision", "Power Vision", "Elite Zoom", "Fusion Edge",
    "Super Focus", "Xtreme Fusion", "Turbo Focus", "Ultra Focus",
    "Fusion Vision", "Xtreme Velocity", "Turbo X", "Pro Focus",
    "Galaxy Zoom", "Pro Vision Max", "Zoom Focus", "Pro Power Max",
    "Elite Power", "Super Focus Pro", "Xtreme Max", "Vision Turbo",
    "Power Focus", "Zoom Xtreme Pro", "Focus Elite", "Pro Galaxy",
    "Max Vision", "Fusion Turbo Pro", "Xtreme Turbo", "Focus Turbo Pro",
    "Elite Focus", "Vision Xtreme", "Pro Nova", "Turbo Nova", "Xtreme Nova",
    "Fusion Nova", "Power Nova", "Max Nova", "Elite Nova", "Xtreme Elite",
    "Galaxy Turbo", "Vision Nova", "Power Nova Max", "X2 Elite", "Super Zoom Pro",
    "Elite Zoom Pro", "Nova Pro", "Elite Turbo", "Power Turbo Pro",
    "Turbo Nova", "Pro Power", "Xtreme Nova Pro", "Focus Nova", "Fusion Nova Pro",
    "Super Fusion", "Turbo Nova Pro", "Elite Power Pro", "Max Power",
    "Zoom Nova", "Turbo Xtreme", "Vision Power", "Max Zoom", "Pro Nova Plus"
];
const colorNames = [
    "Red", "Green", "Blue", "Yellow", "Orange", "Purple", "Pink", "Brown",
    "Gray", "Black", "White", "Beige", "Turquoise", "Cyan", "Magenta",
    "Violet", "Indigo", "Crimson", "Lavender", "Lime", "Teal", "Olive",
    "Maroon", "Coral", "Gold", "Silver", "Plum", "Aqua", "Mint", "Peach",
    "Chartreuse", "Periwinkle", "Tan", "Ivory", "Emerald", "Sky Blue",
    "Navy", "Sienna", "Slate", "Amber", "Azure", "Burgundy", "Copper",
    "Khaki", "Sea Green", "Fuchsia", "Magenta", "Cerulean", "Lavender Blush",
    "Mauve", "Scarlet", "Cobalt", "Eggplant", "Pistachio"
];
export function generateRandomSentenceByWords(wordCount) {
    let sentence = faker.lorem.sentence();

    let words = sentence.split(" ");
    while (words.length < wordCount) {
      sentence += " " + faker.lorem.sentence();
      words = sentence.split(" ");
    }

    return words.slice(0, wordCount).join(" ");
  }
export function generateRandomColorName() {
    const randomIndex = faker.number.int({ min: 0, max: colorNames.length - 1 });
    return colorNames[randomIndex];
}
export function generateRandomPhoneName() {
    const model = faker.helpers.arrayElement(phoneModels);
    const modelNumber = faker.number.int({ min: 1, max: phoneModels.length });
    return `${model}${modelNumber}`;
}
export function generateRandomParagraphAndList(numItems = 5) {
    const paragraph = faker.lorem.paragraph();
    const randomTextList = Array.from({ length: numItems }, () => `<li>${faker.lorem.sentence()}</li>`);

    return `<p>${paragraph}</p><ul>${randomTextList.join('')}</ul>`;
}
export function generateRandomBrand() {
    const model = faker.helpers.arrayElement(phoneModels);
    const modelNumber = faker.number.int({ min: 1, max: phoneModels.length });
    return `${model} ${modelNumber}`;
}
export function randomVariantFor(name) {
    const variant = faker.helpers.arrayElement(phoneVariants);
    return `${name || ''} ${variant}`;
}