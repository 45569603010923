import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    create: null,
    getAll: null,
    delete: null,
    update: null
};

const UnitVariantDefinitionSlice = createSlice({
    name: 'unitVariantDefinition',
    initialState,
    reducers: {
        resetState:(state, action) => {
            state.data = [];
        },
        resetCreate: (state, action) => {
            state.create = null;
        },
        setUnitVariantDefinition: (state, action) => {
            state.create = 'loading';
        },
        createUnitVariantDefinition: (state, action) => {
            state.create = 'working';
        },
        setUnitVariantDefinitionSuccess: (state, action) => {
            state.create = 'done';
        },
        setUnitVariantDefinitionFailure: (state, action) => {
            state.create = 'error';
        },
        resetUpdate: (state, action) => {
            state.update = null;
        },
        putUnitVariantDefinition: (state, action) => {
            state.update = 'loading';
        },
        updateUnitVariantDefinition: (state, action) => {
            state.update = 'working';
        },
        putUnitVariantDefinitionSuccess: (state, action) => {
            state.update = 'done';
        },
        putUnitVariantDefinitionFailure: (state, action) => {
            state.update = 'error';
        },
        resetDelete: (state, action) => {
            state.delete = null;
        },
        deleteUnitVariantDefinition: (state, action) => {
            state.delete = 'loading';
        },
        removeUnitVariantDefinition: (state, action) => {
            state.delete = 'working';
        },
        deleteUnitVariantDefinitionSuccess: (state, action) => {
            state.delete = 'done';
        },
        deleteUnitVariantDefinitionFailure: (state, action) => {
            state.delete = 'error';
        },
        resetGetAll: (state, action) => {
            state.getAll = null;
        },
        getUnitVariantDefinition: (state, action) => {
            state.getAll = 'loading';
        },
        fetchUnitVariantDefinition: (state, action) => {
            state.getAll = 'working';
        },
        getUnitVariantDefinitionSuccess: (state, action) => {
            state.getAll = 'done';
            state.data = action.payload.content.data;
        },
        getUnitVariantDefinitionFailure: (state, action) => {
            state.getAll = 'error';
            state.data = null;
        }
    },
});

export const UnitVariantDefinitionActions = UnitVariantDefinitionSlice.actions;
export default UnitVariantDefinitionSlice;