import React, { useState, useRef, Fragment, useEffect, createElement, forwardRef, useContext } from 'react';
import { PlaceholderContext } from '.././contexts/Contexts';
import { Popup, Confirm, Alert, ComponentWrapper } from '../components/modal/Dialog';

const ModalPlaceholder = forwardRef((p, r) => {
  const context = useContext(PlaceholderContext);
  return (
    <Fragment>
      {context.modalComponent.map(item => {
        return item;
      })}
    </Fragment>
  );
});
const PlaceholderContextProvider = (p) => {

  const [modalComponent, setModalComponent] = useState([]);

  const createModalComponent = (controls, attributes, parent, child) => {

    let id = Math.random().toString().replace('.', '');

    function close() {
      //console.log(element.id, element.key);
      setModalComponent(e => {
        //console.log(e);
        var filter = e.filter(e => e.key !== element.key);
        return filter;
      });
    }

    let args = {
      id,
      key: id,
      controls: controls,
      attributes: { ...attributes, id },
      actions: { close }
    };
    //console.log('typeof', typeof ComponentWrapper(child, args), typeof child);
    let element = createElement(parent, args, (typeof child === 'object') ? createElement(child.type, { ...child.props, ...args }) : createElement(child, args));

    return element;

  };
 
  const openConfirm = (controls, attributes) => {
    setModalComponent((previous) => [...previous, createModalComponent(controls, attributes, Popup, Confirm)]);
  };
  const openAlert = (controls, attributes) => {
    setModalComponent((previous) => [...previous, createModalComponent(controls, attributes, Popup, Alert)]);
  };
  const openModal = (child, controls, attributes) => {
    setModalComponent((previous) => [...previous, createModalComponent(controls, attributes, Popup, child)]);
  };

  return (
    <PlaceholderContext.Provider value={{ openConfirm, openAlert, openModal, modalComponent }}>
      {p.children}
    </PlaceholderContext.Provider>
  );
};

export { PlaceholderContextProvider, ModalPlaceholder };
