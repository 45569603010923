export const DOMAIN = 'http://localhost/global_work_travel/public/api';
export const AUTHORIZED = `${DOMAIN}/authorized`;
//export const HOST = "http://localhost/global_work_travel/public/api";
//export const IMAGE_HOST = "http://localhost/global_work_travel/public/";
export const HOST = "https://trovazo.com/global_work_travel/public/api";
export const IMAGE_HOST = "http://trovazo.com/global_work_travel/public/";

export const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
};
export const FORM_DATA = {
    'Content-Type': 'multipart/form-data'
};
export const FORM_URL_ENCODED = {
    'Content-Type': 'application/x-www-form-urlencoded'
};

export const CREATE_SERVICE = {
    api: HOST + '/services',
    method: 'POST',
    headers: DEFAULT_HEADERS
};
export const GET_ALL_SERVICE = {
    api: HOST + '/services/all',
    method: 'GET',
    headers: DEFAULT_HEADERS
};
export const GET_ALL_SCHEMA = {
    api: HOST + '/services/all',
    method: 'GET',
    headers: DEFAULT_HEADERS
};
export const UPDATE_SERVICE = (id) => ({
    api: `${HOST}/services/${id}`,
    method: 'PUT',
    headers: DEFAULT_HEADERS
});
export const CREATE_UNIT_SCHEMA = (data) => ({
    api: `${HOST}/unit-schema`,
    method: 'POST',
    headers: DEFAULT_HEADERS,
    data: data
});
export const GET_ALL_UNIT_SCHEMA = {
    api: `${HOST}/unit-schema/all`,
    method: 'GET',
    headers: DEFAULT_HEADERS
};
export const DELETE_UNIT_SCHEMA = (id) => ({
    api: `${HOST}/unit-schema/${id}`,
    method: 'DELETE',
    headers: DEFAULT_HEADERS,
    //data: data
});
export const UPDATE_UNIT_SCHEMA = (data) => ({
    api: `${HOST}/unit-schema`,
    method: 'PUT',
    headers: DEFAULT_HEADERS,
    data: data
});
export const GET_UNIT_SCHEMA_DEFINITION = (schemaId) => ({
    api: `${HOST}/unit-schema-definition/${schemaId}`,
    method: 'GET',
    headers: DEFAULT_HEADERS
});
export const SET_UNIT_SCHEMA_DEFINITION = (schemaId, data) => ({
    api: `${HOST}/unit-schema-definition/${schemaId}`,
    method: 'post',
    headers: DEFAULT_HEADERS,
    data: data
});
export const GET_UNIT_VARIANT_DEFINITION = (variantId) => ({
    api: `${HOST}/unit-variant-definition/${variantId}`,
    method: 'GET',
    headers: DEFAULT_HEADERS
});
export const SET_UNIT_VARIANT_DEFINITION = (schemaId, data) => ({
    api: `${HOST}/unit-variant-definition/${schemaId}`,
    method: 'POST',
    headers: DEFAULT_HEADERS,
    data: data
});
export const CREATE_UNIT_VARIANT = (data) => ({
    api: `${HOST}/unit-variant`,
    method: 'POST',
    headers: DEFAULT_HEADERS,
    data: data
});
export const GET_ALL_UNIT_VARIANTS = {
    api: `${HOST}/unit-variant/all`,
    method: 'GET',
    headers: DEFAULT_HEADERS
};
export const GET_UNIT_VARIANT = (id) => ({
    api: `${HOST}/unit-variant/${id}`,
    method: 'GET',
    headers: DEFAULT_HEADERS
});
export const DELETE_UNIT_VARIANT = (id) => ({
    api: `${HOST}/unit-variant/${id}`,
    method: 'DELETE',
    headers: DEFAULT_HEADERS
});
export const UPDATE_UNIT_VARIANT = (data) => ({
    api: `${HOST}/unit-variant`,
    method: 'PUT',
    headers: DEFAULT_HEADERS,
    data: data
});

export const CREATE_UNIT_CATEGORY = (data) => ({
    api: `${HOST}/unit-category`,
    method: 'POST',
    headers: DEFAULT_HEADERS,
    data: data
});
export const GET_UNIT_CATEGORY = (id) => ({
    api: `${HOST}/unit-category/${id}`,
    method: 'GET',
    headers: DEFAULT_HEADERS
});
export const DELETE_UNIT_CATEGORY = (id) => ({
    api: `${HOST}/unit-category/${id}`,
    method: 'DELETE',
    headers: DEFAULT_HEADERS
});
export const UPDATE_UNIT_CATEGORY = (data) => ({
    api: `${HOST}/unit-category`,
    method: 'PUT',
    headers: DEFAULT_HEADERS,
    data: data
});
export const GET_ALL_UNIT_CATEGORY = {
    api: `${HOST}/unit-category/all`,
    method: 'GET',
    headers: DEFAULT_HEADERS
};

export const GET_UNIT_CATEGORY_OPTION = (categoryId) => ({
    api: `${HOST}/unit-category-option/${categoryId}`,
    method: 'GET',
    headers: DEFAULT_HEADERS
});
export const SET_UNIT_CATEGORY_OPTION = (categoryId, data) => ({
    api: `${HOST}/unit-category-option/${categoryId}`,
    method: 'POST',
    headers: DEFAULT_HEADERS,
    data: data
});

export const CREATE_PRODUCT = (data) => ({
    api: `${HOST}/product/create`,
    method: 'POST',
    data: data
});

export const GET_ALL_PRODUCTS = () => ({
    api: `${HOST}/product/all`,
    method: 'GET',
    headers: DEFAULT_HEADERS
});
export const GET_PRODUCT_BY_ID = (productId) => ({
    api: `${HOST}/product/${productId}`,
    method: 'GET',
    headers: DEFAULT_HEADERS
});
export const GET_VARIANTS_BY_PRODUCT_ID = (id) => ({
    api: `${HOST}/product/${id}/variants`,
    method: 'GET',
    headers: DEFAULT_HEADERS
});
export const SET_MAIN_MENU = (data) => ({
    api: `${HOST}/menu/edit`,
    method: 'post',
    headers: DEFAULT_HEADERS,
    data: data
});
export const GET_MAIN_MENU = (data) => ({
    api: `${HOST}/menu/get`,
    method: 'get',
    headers: DEFAULT_HEADERS,
    data: data
});

export const GET_ALL_UNIT_GROUP = {
    api: `${HOST}/unit-group/all`,
    method: 'GET',
    headers: DEFAULT_HEADERS
};
export const CREATE_UNIT_GROUP = (data) => ({
    api: `${HOST}/unit-group`,
    method: 'POST',
    headers: DEFAULT_HEADERS,
    data: data
});
export const SET_VARIANTS_BY_GROUP = (groupId, data) => ({
    api: `${HOST}/unit-group/${groupId}`,
    method: 'POST',
    headers: DEFAULT_HEADERS,
    data: data
});
export const GET_VARIANTS_BY_GROUP = (groupId) => ({
    api: `${HOST}/unit-group/${groupId}`,
    method: 'GET',
    headers: DEFAULT_HEADERS
});
export const GET_VARIANT_BY_VARIANT_ID = (variantId) => ({
    api: `${HOST}/variant/${variantId}`,
    method: 'GET',
    headers: DEFAULT_HEADERS
});
export const PUT_VARIANT_BY_VARIANT_ID = (variantId, data) => ({
    api: `${HOST}/variant/${variantId}`,
    method: 'POST',
    data: data
});
export const DELETE_UNIT_GROUP = (id) => ({
    api: `${HOST}/unit-group/${id}`,
    method: 'DELETE',
    headers: DEFAULT_HEADERS
});
export const UPDATE_UNIT_GROUP = (data) => ({
    api: `${HOST}/unit-group`,
    method: 'PUT',
    headers: DEFAULT_HEADERS,
    data: data
});

export const GET_ALL_VARIANTS = {
    api: `${HOST}/variant/all`,
    method: 'GET',
    headers: DEFAULT_HEADERS
};

export const CRUD_SLIDER = (data) => ({
    api: `${HOST}/slider/crud`,
    method: 'POST',
    data: data
});
export const GET_SLIDERS = {
    api: `${HOST}/slider/all`,
    method: 'GET'
};
export const CRUD_FEATURED = (data) => ({
    api: `${HOST}/featured/crud`,
    method: 'POST',
    data: data
});
export const GET_FEATURED = {
    api: `${HOST}/featured/all`,
    method: 'GET'
};