import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null,
    create: null,
    getAll: null,
    delete: null,
    update: null
};

const UnitSchemaSlice = createSlice({
    name: 'unitSchema',
    initialState,
    reducers: {
        resetCreate: (state, action) => {
            state.create = null;
        },
        setUnitSchema: (state, action) => {
            state.create = 'loading';
        },
        createUnitSchema: (state, action) => {
            state.create = 'working';
        },
        setUnitSchemaSuccess: (state, action) => {
            state.create = 'done';
        },
        setUnitSchemaFailure: (state, action) => {
            state.create = 'error';
        },
        resetUpdate: (state, action) => {
            state.update = null;
        },
        putUnitSchema: (state, action) => {
            state.update = 'loading';
        },
        updateUnitSchema: (state, action) => {
            state.update = 'working';
        },
        putUnitSchemaSuccess: (state, action) => {
            state.update = 'done';
        },
        putUnitSchemaFailure: (state, action) => {
            state.update = 'error';
        },
        resetDelete: (state, action) => {
            state.delete = null;
        },
        deleteUnitSchema: (state, action) => {
            state.delete = 'loading';
        },
        removeUnitSchema: (state, action) => {
            state.delete = 'working';
        },
        deleteUnitSchemaSuccess: (state, action) => {
            state.delete = 'done';
        },
        deleteUnitSchemaFailure: (state, action) => {
            state.delete = 'error';
        },
        resetGetAll: (state, action) => {
            state.getAll = null;
        },
        getAllUnitSchema: (state, action) => {
            state.getAll = 'loading';
        },
        fetchAllUnitSchema: (state, action) => {
            state.getAll = 'working';
        },
        getAllUnitSchemaSuccess: (state, action) => {
            state.getAll = 'done';
            state.data = action.payload.content.data;
        },
        getAllUnitSchemaFailure: (state, action) => {
            state.getAll = 'error';
            state.data = null;
        }
    },
});

export const UnitSchemaActions = UnitSchemaSlice.actions;
export default UnitSchemaSlice;