import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

const DualListBox = forwardRef((p, r) => {

    const [available, setAvailable] = useState(p.available);
    const [selected, setSelected] = useState(p.selected);

    const [selectedAvailable, setSelectedAvailable] = useState([]);
    const [selectedSelected, setSelectedSelected] = useState([]);

    useEffect(()=>{
        setAvailable(p.available);
        setSelected(p.selected);
    },[p.available, p.selected]);
    
    useImperativeHandle(r, () => ({
        getData: () => {
            return [available, selected];
        }
    }));

    const moveRight = () => {
        let toMove = null;
        for(let i = 0; i < available.length; i++) {
            if (selectedAvailable.indexOf(''+available[i].id) > -1 && !selected.find(s => s.value === available[i].value)) {
                toMove = available[i];
                setSelected([...selected, toMove]);
                setAvailable(a => { 
                    let available = [...a];
                    available.splice(i, 1); 
                    return available; 
                });
                setSelectedAvailable([]);
                break;
            }
        }
    };

    const moveLeft = () => {
        let toMove = null;
        for(let i = 0; i < selected.length; i++) {
            if (selectedSelected.indexOf(''+selected[i].id) > -1 && !available.find(s => s.value === selected[i].value)) {
                toMove = selected[i];
                setAvailable([...available, toMove]);
                setSelected(s => { 
                    let selected = [...s];
                    selected.splice(i, 1); 
                    return selected; 
                });
                setSelectedSelected([]);
                break;
            }
        }
    };

    return (
        <div className='transfer-box'>
            <div className='transfer-list'>
                <h3>Available</h3>
                <select
                    multiple
                    value={selectedAvailable}
                    onChange={(e) => {
                        const options = e.target.options;
                        const selectedValues = [];
                        for (let i = 0; i < options.length; i++) {
                            if (options[i].selected) {
                                selectedValues.push(options[i].value);
                            }
                        }
                        setSelectedAvailable(selectedValues);
                    }}
                    style={{ width: '200px', height: '150px' }}
                >
                    {available.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.value}
                        </option>
                    ))}
                </select>
            </div>

            <div className='transfer-controls'>
                <button onClick={moveRight} disabled={selectedAvailable.length === 0}>{String.fromCharCode('9656')}</button>
                <button onClick={moveLeft} disabled={selectedSelected.length === 0}>{String.fromCharCode('9666')}</button>
            </div>

            <div className='transfer-list'>
                <h3>Selected</h3>
                <select
                    multiple
                    value={selectedSelected}
                    onChange={(e) => {
                        const options = e.target.options;
                        const selectedValues = [];
                        for (let i = 0; i < options.length; i++) {
                            if (options[i].selected) {
                                selectedValues.push(options[i].value);
                            }
                        }
                        setSelectedSelected(selectedValues);
                    }}
                    style={{ width: '200px', height: '150px' }}
                >
                    {selected.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.value}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
});

export default DualListBox;
