import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { apiSubmit } from "../main";
import { IMAGE_HOST } from "../api/Api";

const createMockImage = (name, size) => {
    const svgContent = `
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
            <rect width="100" height="100" fill="blue"/>
            <text x="10" y="50" fill="white" font-size="20">${name}</text>
        </svg>`;
    const blob = new Blob([svgContent], { type: 'image/svg+xml' });
    const file = new File([blob], `${name}.svg`, { type: 'image/svg+xml', lastModified: new Date() });
    return file;
};
let test = [
    {
        name: '1',
        newName: '',
        image: '',
        file: '',
        size: createMockImage('1', 128 * 1024 * 1024),
        selected: false
    },
    {
        name: '2',
        newName: '',
        image: '',
        file: '',
        size: createMockImage('2', 128 * 1024 * 1024),
        selected: false
    },
    {
        name: '3',
        newName: '',
        image: '',
        file: '',
        size: createMockImage('3', 128 * 1024 * 1024),
        selected: false
    },
    {
        name: '4',
        newName: '',
        image: '',
        file: '',
        size: createMockImage('4', 128 * 1024 * 1024),
        selected: false
    }
];
const arrowKeys = (e, files) => {

    let array = null;
    let found = null, previous = null, next = null;
    files.forEach((file, index) => {
        if (file.selected) {
            found = index;
            previous = found - 1;
            next = found + 1;
        }
    });
    //console.log("arrowKeys.found", previous, found, next, next < array.length);

    if (found > -1) {
        //console.log("array", array.map(a => a.name).join(', '));
        switch (e.key) {
            case "ArrowUp":
                e.preventDefault();
                if (previous > -1) {
                    array = [...files];
                    let temp = array[previous];
                    array[previous] = array[found];
                    array[found] = temp;
                }
                break;
            case "ArrowDown":
                e.preventDefault();
                if (next < files.length) {
                    array = [...files];
                    let temp = array[next];
                    array[next] = array[found];
                    array[found] = temp;
                }
                break;
            case "ArrowLeft":

                break;
            case "ArrowRight":

                break;
            default:
                break;
        }
        if (array) {
            array.forEach((file, index) => {
                file.order = index;
                return file;
            })
        }
        return array;
    }

};
function onArrow(event) {
    let array = arrowKeys(event, onArrow.uploadFiles);
    Array.isArray(array) && onArrow.setUploadFiles(array);
};
const FileUpload = forwardRef((p, r) => {

    const fileInput = useRef(null);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);

    const addImage = (file) => {

        const image = new Image();
        const src = IMAGE_HOST + (file.url || '');
        image.src = src;
        console.log('addimage', file, `${src}`);
        const name = file.name || (file.url && new URL(file.url, IMAGE_HOST).pathname.split('/').pop())
        const data = {
            id: file.id,
            name: name,
            newName: file.newName || file.name || '',
            image: image,
            file: null,
            size: (file.size != null) ? bytesToMegabytes(file.size) + ' MB' : '',
            order: file.order || 0,
            selected: false
        };

        image.onload = async function () {
            /*let response, blob, object;
            try{
                response = await fetch(src);
                blob = await response.blob();
                object = new File([blob], name, { type: blob.type });
            }catch(e){

            }*/
            setUploadFiles(current => ([...current, data]));
        };
    };
    useEffect(() => {
        window.addEventListener("keydown", onArrow);
        //console.log(p.images);
        let images = Array.isArray(p.images) && p.images.sort((a, b) => a.order - b.order);
        Array.isArray(images) && images.forEach(file => {
            addImage(file)
        });
        return () => {
            window.removeEventListener("keydown", onArrow);
        };
    }, [p.images]);
    useEffect(() => {
        onArrow.uploadFiles = uploadFiles;
        onArrow.setUploadFiles = setUploadFiles;
    }, [uploadFiles]);
    function bytesToMegabytes(bytes) {
        return (bytes === 0) ? 0 : (bytes / (1024 * 1024)).toFixed(4);
    }
    //useEffect(() => { console.log('files', uploadFiles); }, [uploadFiles]);
    useImperativeHandle(r, () => ({
        getUploadedFiles: () => uploadFiles
    }));

    const load = (files) => {
        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    const data = {
                        name: file.name,
                        newName: file.newName || file.name,
                        image: image,
                        file: file,
                        size: bytesToMegabytes(file.size) + ' MB',
                        order: uploadFiles.length,
                        selected: false
                    };
                    setUploadFiles(current => ([...current, data]));
                };
            };
            reader.readAsDataURL(file);
        });
    };
    const upload = (e) => {
        e.preventDefault();
        fileInput.current.click();
    };
    const file = (e) => {
        load(Array.from(e.target.files));
        e.target.value = null;
    };
    const remove = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        //console.log('remove', uploadFiles.length);
        let updated = [...uploadFiles];
        updated.splice(index, 1);
        updated.forEach((file, index) => {
            file.order = index;
            return file;
        })
        //console.log('remove', updated);
        setUploadFiles(updated);
    }
    const startEditing = (index) => {
        setEditingIndex(index);
    }

    const saveName = (e, index) => {
        if (e.key === 'Enter' || e.type === 'blur') {
            const newName = e.target.value;
            setUploadFiles(current => current.map((file, i) =>
                i === index ? { ...file, newName: newName } : file
            ));
            setEditingIndex(null);
        }
    }
    const onSelect = (e, index) => {
        e.preventDefault();
        let updated = [...uploadFiles];
        updated.forEach((u, i) => {
            u['selected'] = (i == index) ? true : false;
        });
        setUploadFiles(updated);
    }
    return (
        <div className="file-upload">
            <ul>
                {(uploadFiles && uploadFiles.length > 0) ?
                    (uploadFiles.sort((a, b) => a.order - b.order).map((file, index) => {
                        //file.order = index;
                        return (
                            <li key={index + file.name} className={file.selected ? 'selected' : ''} onClick={(e) => onSelect(e, index)}>
                                <figure><img src={file.image.currentSrc} /></figure>
                                <div>
                                    {editingIndex === index ? (
                                        <input
                                            type="text"
                                            defaultValue={file.newName || file.name}
                                            onBlur={(e) => saveName(e, index)}
                                            onKeyDown={(e) => saveName(e, index)}
                                            autoFocus
                                        />
                                    ) : (
                                        <em onDoubleClick={() => startEditing(index)}>{file.newName || file.name}</em>
                                    )}
                                    <span>{file.size}</span>
                                </div>
                                <section>
                                    <button onClick={(e) => remove(e, index)}><span>x</span></button>
                                </section>
                            </li>
                        );
                    })) : <li>no files</li>}
            </ul>
            <div>
                <label htmlFor="image-uploader" >
                    <button onClick={upload} >{`${String.fromCharCode(9650)} Upload`}</button>
                    <input ref={fileInput} onChange={file} id="image-uploader" style={{ display: "none" }} type="file" name="file" />
                </label>
            </div>
        </div>
    );
});

export default FileUpload;