import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useContext } from 'react';
import { PlaceholderContext } from "../../contexts/Contexts";
import { $serializeForm, $TopologicalSort } from "../../main";
import { Dialog } from "../modal/Dialog";
import { useDispatch, useSelector } from 'react-redux';
import BasicDataTable from "../../widgets/BasicDataTable";
import { UnitVariantActions } from '../../redux/slices/UnitVariantSlice';
import { UnitVariantDefinitionActions } from '../../redux/slices/UnitVariantDefinitionSlice';
import { GET_ALL_UNIT_VARIANTS, GET_UNIT_VARIANT, GET_UNIT_VARIANT_DEFINITION, SET_UNIT_VARIANT_DEFINITION } from "../../api/Api";
import { Loading } from "../Loaders";
import { LoadingSvg, PlusSymbol, SaveSvg } from "../../icons/Icon";
import { NewUnit, DeleteUnit, EditUnit } from './Unit';
import VariantFieldsForm from './Variant';

const ManageVariants = (p) => {

    const tree = useRef(null);
    const dataTable = useRef(null);
    const placeholder = useContext(PlaceholderContext);
    const [variant, setVariant] = useState(null);
    const dispatch = useDispatch();
    const allUnitVariants = useSelector(state => state.unitVariant.data);
    const getAll = useSelector(state => state.unitVariant.getAll);
    const unitVariant = useSelector(state => state.unitVariantDefinition.data);
    const [unitVariants, setUnitVariants] = useState();

    function loadAllUnitVariants(e) {
        e.preventDefault();
        dispatch(UnitVariantActions.getAllUnitVariants(GET_ALL_UNIT_VARIANTS));
    }
    function getAllUnitVariantState(create) {
        switch (create) {
            case 'loading':
            case 'working':
                setUnitVariants(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                dispatch(UnitVariantActions.resetGetAll());
                setUnitVariants(<BasicDataTable
                    data={allUnitVariants}
                    ref={dataTable}
                    searchClass={'search-table-search-flat'}
                    settings={{ show: ['value'], selectable: false, actions: { edit: doEdit, delete: doDelete, view: doView, deselect: getSelected } }} />);
                p.done && p.done();
                p.success && p.success();
                break;
            case 'error':
                dispatch(UnitVariantActions.resetGetAll());
                setUnitVariants(<button className='simple-form-button' onClick={loadAllUnitVariants}>Load Variants</button>);
                break;
        }
    }
    useEffect(() => {
        getAllUnitVariantState(getAll);
    }, [getAll]);

    useEffect(() => {
        dispatch(UnitVariantActions.getAllUnitVariants(GET_ALL_UNIT_VARIANTS));
        return () => dispatch(UnitVariantActions.resetState());
    }, []);
    useEffect(() => {
        console.log(unitVariant);
    }, [unitVariant]);

    const getData = (e) => {
        var updates = tree.current.getUpdates();
        console.log(updates);
    };
    const doEdit = (e) => {
        //console.log('doEdit',e);
        placeholder.openModal(<Dialog><EditUnit id={e.id} value={e.name} success={() => dispatch(UnitVariantActions.getAllUnitVariants(GET_ALL_UNIT_VARIANTS))} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Edit Variant',
            message: ''
        })
    };
    const doDelete = (e) => {
        //console.log('delete', e);
        placeholder.openModal(<Dialog><DeleteUnit id={e.id} value={e.name} success={() => dispatch(UnitVariantActions.getAllUnitVariants(GET_ALL_UNIT_VARIANTS))} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Delete variant',
            message: ''
        })
    };
    const doView = (e) => {
        //console.log('doView', e);
        setVariant(e);
        dispatch(UnitVariantDefinitionActions.getUnitVariantDefinition(GET_UNIT_VARIANT_DEFINITION(e.id)));
    };
    const getSelected = (e) => {
        //let selected = dataTable?.current.getSelectedRows();
        //console.log(selected)
    }
    const doSave = (e) => {
        //console.log('dosave', e);
        if (Array.isArray(e) && e.length > 0) {
            dispatch(UnitVariantActions.setUnitVariant(SET_UNIT_VARIANT_DEFINITION(variant.id, e)));
        }

    }
    const onCreateNewSchema = (e, id) => {
        placeholder.openModal(<Dialog><NewUnit success={() => dispatch(UnitVariantActions.getAllUnitVariants(GET_ALL_UNIT_VARIANTS))} /></Dialog>, {
            onApprove: (a) => {
                a.actions.close();
            }
        }, {
            heading: 'Create new variant type',
            message: ''
        })
    };
    return (
        <div className="page">
            <div className="flex-24">
                <div className="col-16 flex-column">
                    <VariantFieldsForm fields={unitVariant} doSave={doSave} variant={variant} />
                </div>
                <div className="col-8 flex-column">
                    <div className="page-box">
                        <div className='page-box-header'>
                            <h4><span>Variants</span> <button className='page-box-header-green' onClick={onCreateNewSchema}><PlusSymbol /> new</button></h4>
                        </div>
                        <div className='page-box-body'>{unitVariants}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageVariants;