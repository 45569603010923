import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { GET_PRODUCT_BY_ID } from "../../api/Api";
import { apiRequestAsyncThunk } from "../../thunk/CommonThunk";
import { LoadingSvg } from "../../icons/Icon";
import { Loading } from "../Loaders";
import { JsonToTable } from "../../widgets/JsonToTable";
import { columnNameToPretty } from "../../main";

function render(json) {
    if (!json) {
        return <></>;
    }
    //switch()
    return (
        <table>
            <tbody>
                {Object.entries(json).map(([key, value]) => (
                    <tr key={key}>
                        {Array.isArray(json) ? <></> : <td>{columnNameToPretty(key)}</td>}
                        <td>{typeof value === "object" ? render(value) : value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
function renderProduct(json) {
    if (!json) {
        return <></>;
    }
    return (
        <div className="simpleTableView">
            <table>
                <tbody>
                    {Object.entries(json).map(([key, value]) => (
                        <tr key={key}>
                            <td>{columnNameToPretty(key)}</td>
                            <td>
                                {typeof value === "object" ? render(value) : value}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
export const ProductView = (e) => {

    const dispatch = useDispatch();
    const [product, setProduct] = useState(null);
    const dataTable = useRef(null);
    const loadProduct = () => {
        if (e.productId > 0) {
            dispatch(apiRequestAsyncThunk({
                request_details: GET_PRODUCT_BY_ID(e.productId),
                call: showProduct,
                messages: {
                    loading: 'Loading product',
                    done: 'Product loaded',
                    error: 'Error loading product'
                }
            }));
        }
    }
    const showProduct = (state, data) => {
        switch (state) {
            case 'loading':
                setProduct(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                setProduct(renderProduct(data.data));
                break;
            case 'error':
                setProduct(<button className='simple-form-button' onClick={loadProduct}>Load product</button>);
                break;
        }
    }

    useEffect(() => {
        loadProduct(e);
    }, [e]);

    return (<>{product}</>);
}