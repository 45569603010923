import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { AddToList, DeleteSvg } from '../../icons/Icon';
import ImageHolder from './ImageHolder';

const SliderForm = forwardRef((p, r) => {

    const form = useRef(null);

    useImperativeHandle(r, () => ({
        getForm: () => form.current
    }));

    return (
        <form ref={form} className='bland-form'>
            <input type="text" name={`link`} placeholder='paste link here' defaultValue={p.data || ''} />
        </form>
    );
});
let counter = 0;
const arrowKeys = (e, files) => {

    let array = null;
    let found = null, previous = null, next = null;
    files.forEach((file, index) => {
        if (file.selected) {
            found = index;
            previous = found - 1;
            next = found + 1;
        }
    });

    if (found > -1) {
        switch (e.key) {
            case "ArrowUp":
                e.preventDefault();
                if (previous > -1) {
                    array = [...files];
                    let temp = array[previous];
                    array[previous] = array[found];
                    array[found] = temp;
                }
                break;
            case "ArrowDown":
                e.preventDefault();
                if (next < files.length) {
                    array = [...files];
                    let temp = array[next];
                    array[next] = array[found];
                    array[found] = temp;
                }
                break;
            case "ArrowLeft":

                break;
            case "ArrowRight":

                break;
            default:
                break;
        }
        if (array) {
            array.forEach((file, index) => {
                file.order = index;
                return file;
            })
        }
        return array;
    }

};
function onArrow(event) {
    let array = arrowKeys(event, onArrow.list);
    Array.isArray(array) && onArrow.setList(array);
};
const SliderList = forwardRef((p, r) => {

    const [list, setList] = useState(p.data || []);
    const refs = useRef({});
    useImperativeHandle(r, () => ({
        getData: () => refs.current,
        getList: () => list,
    }));
    useEffect(() => {
        window.addEventListener("keydown", onArrow);
        return () => {
            window.removeEventListener("keydown", onArrow);
        };
    }, []);
    useEffect(() => {
        onArrow.list = list;
        onArrow.setList = setList;
    }, [list]);
    const onAdd = (e) => {
        e.preventDefault();
        setList(current => {
            return [...current, { order: current.length, id: `index-${counter++}`, selected: false }];
        });
    };
    const remove = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let updated = [...list];
        let deleted = updated.splice(index, 1);
        updated.forEach((file, index) => {
            file.order = index;
        })
        setList(updated);
    }
    const onSelect = (e, index) => {
        let updated = [...list];
        updated.forEach((u, i) => {
            u['selected'] = (i == index) ? true : false;
        });
        setList(updated);
    }
    return (
        <div className='dynamic-list'>
            <div className='dynamic-list-header'>
                <h5>
                    <span>Image Slider</span>
                    <button onClick={onAdd}><AddToList /><span>Add</span></button>
                </h5>
            </div>
            <div className='dynamic-list-body'>
                <div className='dynamic-list'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ minWidth: '25%' }}>Details</th>
                                <th>Image</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(list) && list.sort((a, b) => a.order - b.order).map((li, index) => {
                                let id = li.id || li.key;
                                refs.current[id] = refs.current[id] ? refs.current[id] : {};
                                console.log('listing', li.image);
                                list['order'] = (list['order'] > -1) ? list['order'] : index;
                                return (
                                    <tr key={(id > 0 ) ? id : 'key-' + id} className={li.selected ? 'tr-selected' : ''} onClick={(e) => onSelect(e, index)}>
                                        <td><SliderForm ref={(n) => (refs.current[id]['form'] = n)} isKey={id} data={li.link} /></td>
                                        <td><ImageHolder ref={(n) => (refs.current[id]['image'] = n)} data={li.image} /></td>
                                        <td><button onClick={(e) => remove(e, index)}><DeleteSvg /></button></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
});

export default SliderList;